<template>
    <div class="modal-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="others-title">
                    <h3>Modal Live demo</h3>
                </div>

                <!-- Button trigger modal -->
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Launch demo modal
                </button>
                
                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus ad facere, sapiente distinctio vitae quos saepe et aliquid dolor eligendi provident, suscipit similique harum repellendus voluptates nisi necessitatibus assumenda nihil. Lorem ipsum dolor sit amet consectetur adipisicing elit. At, cumque dolorum incidunt deleniti, aperiam commodi eligendi blanditiis repudiandae nihil esse sit odit modi labore unde enim nemo inventore beatae aspernatur?
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Static Modal Live demo</h3>
                </div>

                <!-- Button trigger modal -->
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    Launch static backdrop modal
                </button>
                
                <!-- Modal -->
                <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, facere labore ullam reiciendis facilis cumque consequatur amet dolorem dolore. Iusto saepe necessitatibus consectetur ipsum magni sapiente est facilis illo molestiae. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos optio molestiae vero quisquam deserunt est sequi iure nesciunt, dolore vel dignissimos eius, ipsa quasi maiores aliquid cum accusamus, repellat quae.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Understood</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Launch Demo Modal</h3>
                </div>

                <!-- Button trigger modal -->
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">
                    Launch Demo Modal
                </button>
                
                <!-- Modal -->
                <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse, facere labore ullam reiciendis facilis cumque consequatur amet dolorem dolore. Iusto saepe necessitatibus consectetur ipsum magni sapiente est facilis illo molestiae. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eos optio molestiae vero quisquam deserunt est sequi iure nesciunt, dolore vel dignissimos eius, ipsa quasi maiores aliquid cum accusamus, repellat quae. Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur optio consectetur similique voluptates ut sapiente sequi natus animi earum, et maxime perferendis minima magnam sed dolorum vitae reprehenderit quos officiis. Lorem ipsum, dolor sit amet consectetur adipisicing elit. Earum aperiam quos quidem corrupti perspiciatis commodi vel fugit eveniet magnam? Distinctio placeat voluptatum ipsam dicta et obcaecati vitae totam ut minus! Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore consequuntur, maiores temporibus numquam, iste impedit ad sapiente et quis explicabo sit recusandae, natus saepe fugit dolore! Cum vel quae modi.
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary">Understood</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalContant'
}
</script>

<style lang="scss">
.modal {
    display: none !important;
    &.show {
        display: block !important;
    }
}
</style>