<template>
    <div class="profile-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row">
                    <div class="row"><span style="padding: 2em 4em;font-size: 14px;">Al hacer clic en el plan que deseas contratar, 
                        serás redirigido a la plataforma de pago, una vez procesados los datos de tu tarjeta,
                        volverás a este sitio, por favor no refresques ni cierres la ventana.</span></div>
                    <div class="row"><hr/></div>
                    <div class="col-md-4" v-for="(plan, index) in planes" :key="index">
                        <div class="profile-info" style="border: 1px solid #bbb; padding: 1em; height: 350px;">
                            <div class="profile-name">
                                <h4>{{plan.nombre}}</h4>
                                <h6>{{plan.precio}} {{plan.moneda}}</h6>
                                <span style="font-size: 14px; padding: 1em;">{{plan.descripcion}}</span>
                                <a class="btn btn-warning" :href="plan.stripe_payment_link">
                                    <i class="bx bx-money"></i>&nbsp;Contratar
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'ProfilePaymentsContent',
    data() {
        return {
            planes: []
        }
    },
    mounted() {
        let userData = JSON.parse(localStorage.user)
        this.email = userData.email
        let loaderInfo = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/configs/planes?tipo=all', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.planes = response.data
                loaderInfo.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderInfo.hide()
            })
    }
}
</script>

<style lang="scss">
.profile-info {
	.w-25 {
		width: 120px !important;
	}
	h4 {
		font-size: 18px;
		margin-bottom: 10px;
	}
	span {
		font-size: 14px;
		display: block;
		margin-bottom: 20px;
	}
	.follow-email {
		.btn {
			&:last-child {
				margin-right: 0;
			}
			&.btn-primary {
				background-color: var(--main-color);
				margin-right: 20px;
			}
			&.btn-secondary {
				background-color: #5C31D6;
			}
		}
		
	}
}
.profile-activity {
	background-color: var(--white-color);
	box-shadow: var(--box-shadow);
	padding: 20px;
	text-align: center;
	border-radius: var(--border-radius);
	margin-bottom: 24px;
	i {
		font-size: 20px;
		width: 50px;
		height: 50px;
		line-height: 50px;
		display: inline-block;
		background-color: #5C31D6;
		text-align: center;
		color: var(--white-color);
		border-radius: 50px;
		margin-bottom: 20px;
	}
	h3 {
		font-weight: 600;
		font-size: 15px;
		margin-bottom: 10px;
	}
	h2 {
		font-size: 20px;
		font-weight: 700;
	}
}
.edit-massage {
	.btn {
		&:last-child {
			margin-right: 0;
		}
		&.btn-primary {
			background-color: var(--main-color);
			margin-right: 20px;
		}
		&.btn-secondary {
			background-color: #5C31D6;
		}
	}
	
}
.profile-details {
	padding: 100px !important;
	.profile-menu {
		margin-bottom: 0;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 20px;
		margin-bottom: 30px;
		li {
			display: inline-block;
			margin-right: 30px;
			&:last-child {
				margin-right: 0;
			}
			a {
				&:hover {
					color: var(--main-color);
				}
				&.active {
					color: var(--main-color);
				}
			}
		}
	}
	h3 {
		font-size: 18px;
		margin-bottom: 20px;
	}
	.personal-info {
		margin-bottom: 30px;
		ul {
			li {
				margin-bottom: 15px;
				span {
					font-weight: 600;
					font-size: 14px;
					color: var(--heading-color);
				}
			}
		}
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .profile-details {
        .profile-menu {
            margin-bottom: 20px;
        }
    }
    .edit-massage {
        .btn.btn-primary {
            margin-right: 0;
        }
    }
    .profile-info {
        display: block !important;
        text-align: center;
        img {
            margin-bottom: 10px;
        }
        .ms-4 {
            margin-left: 0 !important;
        }
        .follow-email {
            margin-bottom: 20px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .profile-details {
        padding: 30px;
        .profile-menu {
            margin-bottom: 20px;
        }
    }
    .edit-massage {
        text-align: center !important;
        .btn.btn-primary {
            margin-right: 0;
        }
    }
    .profile-info {
        display: block !important;
        text-align: center;
        img {
            margin-bottom: 10px;
        }
        .ms-4 {
            margin-left: 0 !important;
        }
        .follow-email {
            margin-bottom: 20px;
        }
    }
}
</style>