<template>
    <div class="content-scroll-bar-area">
        <div class="container-fluid">
            <div class="scroll-bar-content card-box-style mb-24">
                <div class="others-title">
                    <h3>Scroll Bar</h3>
                </div>

                <div class="content-height scroll-bar">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>
                </div>
            </div>

            <div class="scroll-bar-content card-box-style">
                <div class="others-title">
                    <h3>Scroll Bar Background Color</h3>
                </div>

                <div class="content-height style-two scroll-bar">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>
                </div>
            </div>

            <div class="scroll-bar-content card-box-style">
                <div class="others-title">
                    <h3>Scroll Bar Withought Simpla Bar</h3>
                </div>

                <div class="content-height">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>

                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A reprehenderit esse dolor laudantium voluptas pariatur magnam optio, animi corporis quis aspernatur commodi cumque cupiditate quas dolorem, voluptatem sequi, laborum nam. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam inventore, in amet cupiditate commodi enim eos! A quod eaque optio cupiditate laborum fuga nisi odio sed, placeat itaque repellendus eveniet. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus officiis quos, est ducimus laborum neque quam temporibus! Maxime exercitationem ut cupiditate id odit, quia fugit. Exercitationem ab asperiores hic perspiciatis. Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur, doloribus nesciunt! Consectetur nihil blanditiis, veniam, libero explicabo nisi illo harum, optio non ipsa nam dignissimos aspernatur temporibus ab eum doloremque! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit similique, voluptates a deserunt ex reiciendis sapiente saepe quod incidunt necessitatibus itaque porro assumenda dolorum? Officiis odit delectus laudantium modi qui. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Amet perspiciatis, molestiae sint animi hic cumque voluptatem suscipit repellendus voluptas neque dolore expedita nihil fugit, deserunt dolores libero veniam. Optio, dolores. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nam sunt quos eum magnam asperiores eos vitae, quibusdam voluptatum mollitia ex, labore, libero ipsum natus delectus atque.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContentScrollBar'
}
</script>

<style lang="scss">
.content-height {
	height: 500px;
	overflow: auto;
	&.style-two {
        &.scroll-bar {
            &::-webkit-scrollbar-thumb {
                background: var(--main-color); 
                border-radius: 30px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: var(--main-color); 
            }
        }
	}
}
</style>