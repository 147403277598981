<template>
    <div class="about-us-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6">
                    <div class="about-content card-box-style">
                        <h2>About Us Joxi</h2>
                        <p>Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Nulla quis lorem ut libero malesuada feugiat. ante ipsum primis in faucibus orci luctus Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quis qui dolorum reiciendis vitae officiis corrupti sunt, repellendus fugiat ratione cum! Deserunt, aliquid? Quis earum ipsum facilis delectus rerum recusandae error</p>

                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugit asperiores deserunt aperiam fuga, ipsam quibusdam officia totam provident doloremque iusto incidunt modi commodi consectetur magni nemo. Iste tempora facere eius Lorem ipsum dolor sit amet consectetur adipisicing elit. Eius tempore magni dignissimos corporis neque iusto reprehenderit fugit! Necessitatibus, consequuntur, pariatur dicta impedit voluptas minima fugit culpa, vero ipsum suscipit porro</p>

                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>Our Vision</h3>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum culpa modi ducimus saepe. Ex perspiciatis, ipsum aut, recusandae pariatur dolor nam dignissimos deserunt delectus iusto libero eius vitae tempore consequuntur.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>Our Mission</h3>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum culpa modi ducimus saepe. Ex perspiciatis, ipsum aut, recusandae pariatur dolor nam dignissimos deserunt delectus iusto libero eius vitae tempore consequuntur.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <h3>Joxi Services</h3>
                                        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Illum culpa modi ducimus saepe. Ex perspiciatis, ipsum aut, recusandae Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam id impedit soluta distinctio quos eos omnis. Accusantium quis, consectetur, reiciendis ipsum, modi rerum.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="about-img">
                        <img src="../../assets/images/about-img.jpg" alt="about-img">
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6">
                    <div class="card mb-4">
                        <img src="../../assets/images/friends/friend-1.jpg" class="card-img-top" alt="gallery-1">
                        <div class="card-body">
                            <h5 class="card-title">Juhon Dew</h5>
                            <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis temporibus nihil perspiciatis nobis explicabo eum iure dolor labore quod veniam enim repellat id consectetur, doloribus recusandae qui similique laborum expedita.</p>
                            <a href="#" class="btn btn-primary">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="card mb-4">
                        <img src="../../assets/images/friends/friend-2.jpg" class="card-img-top" alt="gallery-1">
                        <div class="card-body">
                            <h5 class="card-title">Jeck Dew</h5>
                            <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis temporibus nihil perspiciatis nobis explicabo eum iure dolor labore quod veniam enim repellat id consectetur, doloribus recusandae qui similique laborum expedita.</p>
                            <a href="#" class="btn btn-primary">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="card mb-4">
                        <img src="../../assets/images/friends/friend-3.jpg" class="card-img-top" alt="gallery-1">
                        <div class="card-body">
                            <h5 class="card-title">Anny Mari</h5>
                            <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis temporibus nihil perspiciatis nobis explicabo eum iure dolor labore quod veniam enim repellat id consectetur, doloribus recusandae qui similique laborum expedita.</p>
                            <a href="#" class="btn btn-primary">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6">
                    <div class="card mb-4">
                        <img src="../../assets/images/friends/friend-4.jpg" class="card-img-top" alt="gallery-1">
                        <div class="card-body">
                            <h5 class="card-title">Cmox Smith</h5>
                            <p class="card-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis temporibus nihil perspiciatis nobis explicabo eum iure dolor labore quod veniam enim repellat id consectetur, doloribus recusandae qui similique laborum expedita.</p>
                            <a href="#" class="btn btn-primary">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs'
}
</script>

<style lang="scss">
.about-content {
	padding-bottom: 0;
	h2 {
		font-size: 25px;
		margin-bottom: 15px;
	}
	.card {
		margin-bottom: 30px;
		.card-body {
			h3 {
				font-size: 20px;
				margin-bottom: 15px;
			}
		}
	}
}
.about-img {
	margin-bottom: 24px;
	img {
		border-radius: 30px;
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .about-content {
        h2 {
            font-size: 20px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-content {
        h2 {
            font-size: 20px;
        }
    }
}
</style>