<template>
    <div class="modal fade contact-modal" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add New Contact</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="account-content">
                        <form @submit.prevent class="account-wrap">
                            <div class="form-group mb-24 icon">
                                <input type="text" class="form-control" placeholder="Email">
                                <img src="../../assets/images/icon/user-square.svg" alt="user-square">
                            </div>

                            <div class="form-group mb-24 icon">
                                <input type="email" class="form-control" placeholder="Email">
                                <img src="../../assets/images/icon/sms.svg" alt="sms">
                            </div>

                            <div class="form-group mb-24 icon">
                                <input type="text" class="form-control" placeholder="Phone No">
                                <img src="../../assets/images/icon/call-calling.svg" alt="key">
                            </div>

                            <div class="form-group mb-24 icon">
                                <input type="text" class="form-control" placeholder="Address">
                                <img src="../../assets/images/icon/location.svg" alt="location">
                            </div>

                            <div class="form-group">
                                <button type="submit" class="default-btn">Save Contact</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactModal'
}
</script>

<style lang="scss">
.contact-modal {
	.modal-content {
		padding: 50px;
		border-radius: 30px;
		border: none;
	}
	h3 {
		color: #292D32;
		font-size: 20px;
		font-weight: 600;
	}
	.modal-header {
		margin-bottom: 30px;
		padding-left: 0;
		padding-right: 0;
		padding-top: 0;
		border-color: #EBF1FF;
	}
	.modal-body {
		padding: 0;
	}
	.account-content {
		padding: 0;
	}
	.btn-close {
		box-shadow: none;
		opacity: 1;
	}
}
</style>