<template>
    <div id="chart">
        <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    name: 'GroupedBarChart',
    data: function() {
    return {
            series: [{
                name: 'Series-1',
                data: [44, 55, 41, 64, 22, 43, 21]
            }, {
                name: 'Series-2',
                data: [53, 32, 33, 52, 13, 44, 32]
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 430
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        dataLabels: {
                            position: 'top',
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    offsetX: -6,
                    style: {
                        fontSize: '12px',
                        colors: ['#fff']
                    }
                },
                stroke: {
                    show: true,
                    width: 1,
                    colors: ['#fff']
                },
                tooltip: {
                    shared: true,
                    intersect: false
                },
                xaxis: {
                    categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
                },
            },
        };
    },
}
</script>