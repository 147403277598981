<template>
    <div class="account-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="account-content">
                        <div class="account-header">
                            <router-link to="/">
                                <img src="../../assets/images/apsicat_fondo_pequeno.png" alt="main-logo">
                            </router-link>
                            <h3>Términos y condiciones</h3>
                        </div>
                        <TermsContent />
                        <router-link to="/registro">Regresar</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TermsContent from '../Terms/TermsContent'

export default {
    name: 'TermsPage',
    components: {
        TermsContent,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>