<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Editar Curso" pageTitle="Editar Curso" />
            <AdminEditorCurso />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import AdminEditorCurso from '../Admin/EditorCurso'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'AdminEditorCursoPage',
    components: {
        MainHeader,
        PageTitle,
        AdminEditorCurso,
        FooterArea,
    },
}
</script>