<template>
    <div class="account-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="account-content">
                        <div class="account-header">
                            <router-link to="/" class="d-block">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="profile-img">
                            </router-link>
                            <h3>{{userName}} bloqueó su pantalla</h3>
                        </div>

                        <form @submit.prevent="submit" class="account-wrap">
                            <div class="form-group mb-24 icon">
                                <input type="password" class="form-control" placeholder="password" v-model="form.password">
                                <img src="../../assets/images/icon/key.svg" alt="key">
                            </div>
                            <div class="form-group mb-24">
                                <button type="submit" class="default-btn">Desbloquear</button>
                            </div>
                            <div class="form-group mb-24">
                                <p class="account text-center">No recuerdas tu password? <router-link to="/logout">Haz clic aquí</router-link></p>
                            </div>
                        </form>

                        <ul class="account-social-link">
                            <li>
                                <a href="https://www.google.com/" target="_blank">
                                    <i class='bx bxl-google' ></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.twitter.com/" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
	name: 'ScreenLockPage',
	data() {
		return {
			form: {
				email: '',
				password: '',
				rememberMe: true
			},
			userName: ''
		}
	},
	mounted() {
		localStorage.screenLocked = true
		let user = JSON.parse(localStorage.user)
		this.form.email = user.email
		this.userName = user.general.nombre
	},
	methods: {
		submit() {
			console.log(this.form)
			let loader = this.$loading.show({ canCancel: false })
			axios
				.post(this.$backendUrl + '/accesos/login', this.form, { headers: { token: localStorage.webToken } })
				.then(response => {
					if (response.status == 200) {
						localStorage.userToken = response.data.accesos.token
						localStorage.user = JSON.stringify(response.data.accesos)
						localStorage.removeItem('screenLocked')
						loader.hide()
						this.$router.push('/profile')
					}
				},
				error => {
					loader.hide()
					this.$swal({ icon: 'error', text: 'El password no es correcto' })
					console.log(error)
				})
		},
	},
}
</script>

<style lang="scss">
.account-area {
	background-image: url(../../assets/images/account-bg.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100vh;
}
.account-content {
	max-width: 510px;
	margin: auto;
	background-color: var(--white-color);
	border-radius: 30px;
	padding: 50px;
}
.account-header {
	text-align: center;
	img {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 30px;
		padding-top: 30px;
		border-top: 1px solid #EBF1FF;
		font-size: 20px;
	}
}
.account-wrap {
	.forgot {
		color: #1765FD;
		font-size: 14px;
	}
	.default-btn {
		color: var(--white-color);
		width: 100%;
		padding: 23px 20px;
	}
	.account {
		font-size: 14px;
		margin-bottom: 0;
		color: #292D32;
		a {
			color: #1765FD;
		}
	}
	.form-check {
		a {
			color: #1765FD;
		}
	}
}
.account-social-link {
	padding-left: 0;
	margin-bottom: 0;
	list-style-image: none;
	text-align: center;
	li {
		display: inline-block;
		margin-right: 15px;
		&:last-child {
			margin-right: 0;
		}
		a {
			width: 46px;
			height: 46px;
			line-height: 50px;
			text-align: center;
			display: inline-block;
			background-color: #EBF1FF;
			color: #1765FD;
			border-radius: 50%;
			font-size: 20px;
			&:hover {
				background-color: var(--main-color);
				color: var(--white-color);
			}
		}
	}
}


/* Max width 767px */
@media only screen and (max-width: 767px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .account-content {
        padding: 30px;
    }
}

/* Min width 1320px to Max width 1550px */
@media only screen and (min-width: 1320px) and (max-width: 1550px) {
    .account-area {
        height: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }

}
</style>