<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Apex Charts" pageTitle="Apex Charts" />
            <ApexCharts />
            <div class="flex-grow-1"></div>
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import ApexCharts from '../ApexCharts/ApexCharts'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'ApexChartsPage',
    components: {
        MainHeader,
        PageTitle,
        ApexCharts,
        FooterArea,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>