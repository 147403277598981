<template>
    <div class="faq-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="main-content-text">
                    <h3>1. Términos y condiciones</h3>
                    <p>Proin eget tortor risus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Quisque velit nisi, pretium ut lacinia in elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. Cras ultricies ligula sed magna dictum porta. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vestibulum ac diam sit amet quam vehicula elementum sed sit amet dui. Donec rutrum congue leo eget malesuada. Donec rutrum congue leo eget malesuada. Pellentesque in ipsum id orci porta dapibus. Curabitur aliquet quam id ut lacinia in elementum id enim. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi leo eget malesuada.</p>

                    <div class="gap-20"></div>

                    <h3>2. Disponibilidad</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, odio sequi! Nam consectetur, officiis ipsam explicabo tempore placeat! Voluptas molestiae pariatur necessitatibus consectetur, ullam aut esse amet debitis sint culpa. orem ipsum dolor sit amet consectetur adipisicing elit. Assumenda, odio Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae mollitia laborum assumenda beatae minima enim quis dolores, pariatur, at perferendis sit dolore modi nostrum eum ea voluptatem</p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ut, placeat? Culpa ab est nemo perspiciatis quam, nesciunt reprehenderit voluptate id error corrupti doloremque exercitationem quis, iusto debitis velit eveniet ea. ipsum dolor sit amet consectetur, adipisicing elit. Ut, placeat? Culpa ab est nemo perspiciatis quam, nesciunt reprehenderit</p>

                    <div class="gap-20"></div>

                    <h3>3. Confidencialidad de datos</h3>
                    <ul>
                        <li>
                            <i class="ri-check-line"></i>
                            Quisque velit nisi, pretium ut lacinia in, elementum id enim. 
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Proin eget tortor risus consectetur adipiscing elit.
                        </li>
                        <li>
                            <i class="ri-check-line"></i>
                            Curabitur aliquet quam id dui posuere blandit. 
                        </li>
                    </ul>

                    <div class="gap-20"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TermsContent'
}
</script>

<style lang="scss">
.main-content-text {
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: 15px;
	}
	h1 {
		font-size: 30px;
	}
	h2 {
		font-size: 26px;
	}
	h3 {
		font-size: 18px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 20px;
	}
	h6 {
		font-size: 18px;
	}
	.gap-20 {
		margin-bottom: 30px;
	}
	ul {
		padding: 0;
		list-style-type: none;
		margin-bottom: 0;
		li {
			position: relative;
			margin-bottom: 10px;
			padding-left: 35px;
			&:last-child {
				margin-bottom: 0;
			}
			i {
				position: absolute;
				top: -6px;
				left: 0;
				color: var(--main-color);
				font-size: 25px;
			}
		}
	}
	ol {
		padding-left: 18px;
		li {
			margin-bottom: 10px;
			&::marker  {
				color: var(--main-color);
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	a {
		color: var(--main-color);
		&:hover {
			text-decoration: underline;
		}
	}
	.content-gap-mb-20 {
		margin-bottom: 20px;
	}
	.content-gap-mb-30 {
		margin-bottom: 30px;
	}
	&.business-insurance-content {
		max-width: 860px;
		margin: auto;
	}
	&.health-insurance-content {
		max-width: 860px;
		margin: auto;
	}
	&.life-insurance-content {
		max-width: 860px;
		margin: auto;
	}
	&.car-insurance-content {
		max-width: 860px;
		margin: auto;
	}
	
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .main-content-text {
        h3 {
            font-size: 18px;
        }
    }
}
</style>