<template>
    <div id="chart">
        <apexchart type="pie" width="550" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    name: 'MonochromePieChart',
    data: function() {
        return {
            series: [25, 15, 44, 55, 41, 17],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                theme: {
                    monochrome: {
                        enabled: true
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            offset: -5
                        }
                    }
                },
                title: {
                    text: "Number of leads"
                },
                dataLabels: {
                    formatter(val, opts) {
                        const name = opts.w.globals.labels[opts.seriesIndex]
                        return [name, val.toFixed(1) + '%']
                    }
                },
                legend: {
                    position: 'bottom'
                }
            },
        };
    },
}
</script>