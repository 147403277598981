<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Administrar Productos" pageTitle="Administrar Productos" />
            <AdminProductos />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import AdminProductos from '../Admin/Productos'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'AdminProductosPage',
    components: {
        MainHeader,
        PageTitle,
        AdminProductos,
        FooterArea,
    },
}
</script>