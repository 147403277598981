<template>
    <div id="chart">
        <apexchart type="pie" width="550" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    name: 'SimplePieChart',
    data: function() {
        return {
            series: [44, 55, 13, 43, 22],
            chartOptions: {
                chart: {
                    width: '100%',
                    height: 430,
                    type: 'pie',
                },
                labels: ['UK', 'USA', 'Canada', 'Australia', 'Italy'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                legend: {
                    horizontalAlign: 'right',
                }
            },
        };
    },
}
</script>

<style lang="scss">
.apexcharts-canvas {
    margin: auto;
}
</style>