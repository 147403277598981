<template>
    <div class="all-section-area">
        <main class="main-content-wrap">
            <div class="flex-grow-1"></div>
        </main>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'OauthCalendy',
    components: {
    },
    mounted() {
        document.body.classList.add('body-bg-f8faff')
        let loader = this.$loading.show({ canCancel: false })
        axios
            .post(this.$backendUrl + '/me/config', { ruta:'/oauth/calendy', valor: this.$route.query.code }, { headers: { token: localStorage.userToken } })
            .then(response => {
                console.log(response)
                loader.hide()
                this.$router.push('/perfil')
            },
            error => {
                loader.hide()
                this.$swal({ icon: 'error', text: error.response.data.description })
            })
    },
}
</script>