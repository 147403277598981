<template>
    <div class="contact-us-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-4">
                    <div class="contact-info-wrap">
                        <div class="single-contact-info">
                            <h3>Get In Touch</h3>

                            <ul class="address">
                                <li class="location">
                                    <span>Address</span>
                                    : 6890 Blvd, The Bronx, NY 1058, USA
                                </li>
                                <li>
                                    <span>Phone</span>
                                    <a href="tel:+44-077-585-00-77">: +44 077 585 00 77</a>
                                </li>
                                <li>
                                    <span>Email</span>
                                    <a href="mailto:hello@odix.com.com">hello@odix.com</a>
                                </li>
                            </ul>
                        </div>

                        <div class="single-contact-info follow-us">
                            <h3>Follow Us</h3>

                            <ul class="social-link">
                                <li>
                                    <a href="https://www.facebook.com/" target="_blank">
                                        <i class="ri-facebook-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" target="_blank">
                                        <i class="ri-instagram-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" target="_blank">
                                        <i class="ri-linkedin-box-fill"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.twitter.com/" target="_blank">
                                        <i class="ri-twitter-fill"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8">
                    <div class="contact-form">
                        <form @submit.prevent id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone No">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                        <div class="help-block with-errors"></div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message"></textarea>
                                        <div class="help-block with-errors" aria-placeholder="Your Message"></div>
                                    </div>
                                </div>
    
                                <div class="col-12">
                                    <div class="form-check">
                                        <div class="form-group">
                                            <div class="form-check">
                                                <input
                                                    name="gridCheck"
                                                    value="I agree to the terms and privacy policy."
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    id="gridCheck"
                                                    required
                                                >
                                        
                                                <label class="form-check-label" for="gridCheck">
                                                    I agree to the <router-link to="/terms">Terms &amp; conditions</router-link>
                                                </label>
                                                <div class="help-block with-errors gridCheck-error"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
    
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn">
                                        Send Message
                                    </button>
                                    <div id="msgSubmit" class="h3 text-center hidden"></div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs'
}
</script>

<style lang="scss">
.contact-form {
	background-color: var(--white-color);
	box-shadow: var(--box-shadow);
	padding: 70px;
	border-radius: 20px;
	margin-bottom: 50px;
	h3 {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.form-group {
		margin-bottom: 30px;
	}
	.form-check {
		padding-left: 12px;
		label {
			margin-bottom: 0;
		}
	}
	a {
		color: var(--main-color);
	}
	.form-check-input {
		border-color: var(--main-color);
		&:checked {
			background-color: var(--main-color);
			border-color: var(--main-color);
		}
		&:focus {
			box-shadow: none;
		}
	}
	.default-btn {
		padding: 15px 20px;
		font-size: 16px;
		font-weight: 500;
	}
}
.list-unstyled {
	color: #dc3545;
	margin-top: 10px;
	font-size: 14px;
}
.hidden {
	display: none;
}
#msgSubmit {
	margin-top: 30px;
	font-size: 20px;
}
.contact-info-wrap {
	background-color: var(--white-color);
	box-shadow: var(--box-shadow);
	padding: 50px;
	border-radius: 20px;
}
.single-contact-info {
	h3 {
		font-size: 16px;
		margin-bottom: 25px;
	}
	.address {
		list-style-type: none;
		padding: 0;
		margin: 0;
		.location {
			span {
				display: block;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 10px;
				color: var(--heading-color);
			}
		}
		li {
			position: relative;
			margin-bottom: 20px;
			padding-left: 90px;
			a {
				color: var(--body-color);
				display: block;
				&:hover {
					color: var(--main-color);
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
			span {
				position: absolute;
				left: 0;
				top: -3px;
				font-size: 14px;
				font-weight: 600;
				margin-bottom: 10px;
				color: var(--heading-color);
			}
		}
	}
	.social-link {
		padding: 0;
		margin: 0;
		list-style-type: none;
		li {
			display: inline-block;
			margin-right: 10px;
			&:last-child {
				margin-right: 0;
			}
			a {
				i {
					width: 40px;
					height: 40px;
					line-height: 40px;
					background-color: #f1f1f1;
					color: var(--heading-color);
					transition: var(--transition);
					display: inline-block;
					text-align: center;
				}
				&:hover {
					i {
						color: var(--white-color);
						background-color: var(--heading-color);
					}
				}
			}
		}
	}
	&.follow-us {
		margin-top: 40px;
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-info-wrap {
        padding: 20px;
        margin-bottom: 30px;
    }
    .contact-form {
        padding: 20px;
        .form-group {
            margin-bottom: 20px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-info-wrap {
        padding: 20px;
        margin-bottom: 30px;
    }
    .contact-form {
        padding: 20px;
        .form-group {
            margin-bottom: 20px;
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .contact-info-wrap {
        padding: 30px;
    }
    .contact-form {
        padding: 30px;
        .form-group {
            margin-bottom: 20px;
        }
    }
}
</style>