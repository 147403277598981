<template>
    <div class="chat-content-area mt-20">
        <div class="container-fluid">
            <div class="sidebar-left">
                <div class="sidebar">
                    <ul class="top-chat-menu">
                        <li>
                            <router-link to="/chat">Chat</router-link>
                        </li>
                        <li>
                            <router-link to="/group">Group</router-link>
                        </li>
                        <li>
                            <router-link to="/contact-us">Contact</router-link>
                        </li>
                    </ul>

                    <div class="sidebar-content chat-sidebar scroll-bar">
                        <div class="chat-menu">
                            <ul class="list-group list-group-user list-unstyled mb-0">
                                <li class="active">
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Anderson Coopers</h6>
                                            <span class="d-block">Lorem ipsum dolor sit amet, consete tur sadi...</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                            <span class="sms-count">1</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-2.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Zennilia Anderson</h6>
                                            <span class="d-block">Lorem ipsum dolor sit ame..</span>
                                            <span class="date">12:00 AM - 02:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-3.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Zara Canela</h6>
                                            <span class="d-block">Okay</span>
                                            <span class="date">10:00 AM - 11:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-4.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Victor James</h6>
                                            <span class="d-block">Lorem ipsum dolor sit ame..</span>
                                            <span class="date">09:00 AM - 10:30 AM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-5.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Jane Ronan</h6>
                                            <span class="d-block">See you later.</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-6.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Angela Carter</h6>
                                            <span class="d-block">Thanks. Good Bye.</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">John Karahan</h6>
                                            <span class="d-block">Okay</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-2.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Zennilia Dew</h6>
                                            <span class="d-block">Thanks!</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-3.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Jekt</h6>
                                            <span class="d-block">Hello!</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <div class="d-flex align-items-center">
                                        <div class="avatar me-3">
                                            <img src="../../assets/images/chat/chat-4.png" width="50" height="50" class="rounded-circle" alt="image">
                                        </div>

                                        <div class="user-name">
                                            <h6 class="font-weight-bold">Juhon Dew</h6>
                                            <span class="d-block">How are you</span>
                                            <span class="date">11:00 AM - 01:30 PM</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="content-right">
                <div class="chat-area">
                    <div class="chat-list-wrapper">
                        <div class="chat-list">
                            <div class="chat-list-header d-flex align-items-center">
                                <div class="header-left d-flex align-items-center me-2">
                                    <div class="avatar me-2">
                                        <img src="../../assets/images/chat/chat-1.png" class="rounded-circle" alt="image">
                                        <!-- <span class="status-online"></span> -->
                                    </div>
                                    <div>
                                        <h6 class="mb-0 font-weight-bold">Anderson Coopers</h6>
                                        <span>Active 2 hours ago</span>
                                    </div>
                                </div>

                                <div class="header-right text-end w-100">
                                    <ul class="list-unstyled mb-0">
                                        <li>
                                            <a href="#">
                                                <img src="../../assets/images/icon/call.svg" alt="call">
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <img src="../../assets/images/icon/video.svg" alt="video">
                                            </a>
                                        </li>
                                        <li>
                                            <div class="dropdown">
                                                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <img src="../../assets/images/icon/dots.svg" alt="dots">
                                                </button>
    
                                                <div class="dropdown-menu">
                                                    <a class="dropdown-item d-flex align-items-center" href="#">
                                                        <i class="bx bx-log-in-circle"></i> Leave Group
                                                    </a>
                                                    <a class="dropdown-item d-flex align-items-center" href="#">
                                                        <i class='bx bx-trash'></i> Delete Chat
                                                    </a>
                                                    <a class="dropdown-item d-flex align-items-center" href="#">
                                                        <i class='bx bx-block'></i> Block
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="chat-container scroll-bar">
                                <div class="chat-content">
                                    <div class="chat chat-left">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Hey John, I am looking for the best admin template. Could you please tell me to find it out?</p>
                                            </div>
                                            <div class="chat-message">
                                                <p>it should be bootstrap 4 comfortable</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-6.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Sure. I'll let you know within 1 hour.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat chat-left">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Thanks a lot!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-6.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>I'll knock you when I'm available. Please consider me some time please.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat chat-left">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>No problem. Knock me when you'll be available.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-6.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Thanks!</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="badge badge-pill my-3">
                                        <span>Today</span>
                                    </div>

                                    <div class="chat chat-left">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-1.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Are you available now?</p>
                                            </div>
                                            <div class="chat-message">
                                                <p>Actually I've an important discussion with you.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="chat">
                                        <div class="chat-avatar">
                                            <a href="#" class="d-inline-block">
                                                <img src="../../assets/images/chat/chat-6.png" width="50" height="50" class="rounded-circle" alt="image">
                                            </a>
                                        </div>

                                        <div class="chat-body">
                                            <div class="chat-message">
                                                <p>Sure. I'm calling you.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="chat-list-footer">
                                <form @submit.prevent class="d-flex align-items-center">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Type your message...">

                                        <ul class="others-option-list">
                                            <li>
                                                <a href="#" class="first">
                                                    <img src="../../assets/images/icon/emoji-happy.svg" alt="emoji-happy">
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="second">
                                                    <img src="../../assets/images/icon/gallery.svg" alt="gallery">
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#" class="third">
                                                    <img src="../../assets/images/icon/folder-add.svg" alt="folder-add">
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <button type="submit" class="send-btn d-inline-block">
                                        <span>Send</span> 
                                        <img src="../../assets/images/icon/send-2.svg" alt="send-2">
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatContent'
}
</script>

<style lang="scss">
.chat-content-area {
    .sidebar-left {
        float: left;
        .sidebar {
            background-color: var(--white-color);
            box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
			padding: 30px;
            width: 485px;
			border-radius: 30px;
			margin-bottom: 24px;
			.top-chat-menu {
				padding-left: 0;
				margin-bottom: 0;
				list-style: none;
				background-color: #EBF1FF;
				padding: 10px;
				border-radius: 10px;
				margin-bottom: 20px;
				li {
					width: 32.33%;
					flex: 0 0 32.33%;
					display: inline-block;
					a {
						font-size: 16px;
						font-weight: 500;
						padding: 10px 10px;
						display: block;
						text-align: center;
						color: var(--heading-color);
						&.active {
							background-color: #5C31D6;
							color: var(--white-color);
							border-radius: 10px;
						}
					}
				}
			}

            .chat-sidebar-header {
                form {
                    label {
                        display: block;
                        margin-bottom: 0;
                        position: absolute;
                        left: 15px;
                        top: 11.3px;
                        font-size: 18px;
                        color: #a8a8a8;
                    }
                    .form-control {
                        height: 45px;
                        border-radius: 30px;
                        background-color: #eef5f9;
                        border: none;
                        color: var(--heading-color);
                        padding: 0 15px 0 40px;
                        transition: var(--transition);
                        box-shadow: unset;
                        font: {
                            size: 14px;
                            weight: 400;
                        };
                        &:focus {
                            box-shadow: unset;
                            background-color: #eef5f9;
        
                            &::placeholder {
                                color: transparent !important;
                            }
                        }
                        &::placeholder {
                            color: #a8a8a8;
                            transition: var(--transition);
                        }
                    }
                }
            }
            .sidebar-content {
                height: calc(100vh - 321px);

                .chat-menu {
                    .list-group-user {
                        li {
                            cursor: pointer;
                            margin-bottom: 20px;
							position: relative;
							&.active {
								background-color: #F8FAFF;
								padding: 15px 10px;
								border-radius: 15px;
								.date {
									top: 15px;
									right: 10px;
								}
								.sms-count {
									bottom: 15px;
									right: 10px;
								}
							}

                            &:last-child {
                                margin-bottom: 0;
                            }
                            .avatar {
                                position: relative;
								&.me-3 {
									margin-right: 15px !important;
								}
								img {
									width: 50px;
									border-radius: 50px;
									border: 3px solid var(--white-color);
									box-shadow: var(--box-shadow);
								}

                                .status-busy {
                                    background-color: #FF5B5C;
                                }
                                .status-online {
                                    background-color: #39DA8A;
                                }
                                .status-away {
                                    background-color: #FDAC41;
                                }
                                .status-offline {
                                    background-color: #475F7B;
                                }
                                [class*=status-] {
                                    border-radius: 50%;
                                    width: 10px;
                                    height: 10px;
                                    position: absolute;
                                    left: 1px;
                                    top: 1px;
                                }
                            }
                            h6 {
                                margin-bottom: 10px;
                                font: {
                                    size: 14px;
                                };
                            }
                            span {
                                color: #71738d;
                                font-size: 12px;
                            }
							.date {
								position: absolute;
								top: 15px;
								right: 10px;
								font-size: 12px;
								color: #BCC2CF;
							}
							.sms-count {
								position: absolute;
								bottom: -15px;
								right: 10px;
								width: 15px;
								height: 15px;
								line-height: 15px;
								text-align: center;
								display: inline-block;
								background-color: #5C31D6;
								border-radius: 5px;
								color: var(--white-color);
								font-size: 12px;
							}
                        }
                    }
                    .list-group-label {
                        color: var(--heading-color);
                        margin: {
                            top: 25px;
                            bottom: 20px;
                        };
                        padding-bottom: 5px;
                        position: relative;
                        border-bottom: 1px solid #eeeeee;
                        font: {
                            size: 16px;
                            weight: 700;
                        };
                        &::before {
                            width: 70px;
                            height: 1px;
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -1px;
                            background-color: var(--main-color);
                        }
                    }
                }
            }
        }
    }
    .content-right {
        float: right;
        width: calc(100% - 510px);
		margin-bottom: 24px;
        .chat-list-wrapper {
            .chat-list {
                background: #FFFFFF 0% 0% no-repeat padding-box;
				box-shadow: 0px 2px 15px #00000005;
				border-radius: 30px;
                
                .simplebar-scrollbar {
                    &::before {
                        background: #aaaaaa;
                    }
                }
                .chat-list-header {
                    padding: 15px 0;
					border-bottom: 1px solid #EBF1FF;
					margin: 0 20px;
                    .header-left {
                        h6 {
                            white-space: nowrap;
                            font-size: 16px;
                        }
						span {
							font-size: 12px;
							color: #67748E;
						}
                        .avatar {
                            position: relative;
							width: 50px;
							img {
								box-shadow: 0px 2px 15px #00000014;
								border: 3px solid #FFFFFF;
							}
                            .status-busy {
                                background-color: #FF5B5C;
                            }
                            .status-online {
                                background-color: #39DA8A;
                            }
                            .status-away {
                                background-color: #FDAC41;
                            }
                            .status-offline {
                                background-color: #475F7B;
                            }
                            [class*=status-] {
                                border-radius: 50%;
                                width: 10px;
                                height: 10px;
                                position: absolute;
                                left: 1px;
                                top: 1px;
                            }
                        }
                    }
                    .header-right {
                        position: relative;
                        top: 1px;

                        ul {
                            li {
                                display: inline-block;
                                margin-left: 15px;

                                &:first-child {
                                    margin-left: 0;
                                }
                                .favorite {
                                    font-size: 20px;
                                    cursor: pointer;
                                    color: #C7CFD6;
                                    position: relative;
                                    top: -1px;
                                    
                                    &.active {
                                        color: #FDAC41;
    
                                        i {
                                            &::before {
                                                content: "\ed99";
                                            }
                                        }
                                    }
                                }
                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                        border: none;
                                        background-color: transparent;
                                        color: #727E8C;
                                        font-size: 22px;
                                        line-height: 15px;
                        
                                        &::after {
                                            display: none;
                                        }
                                    }
                                    .dropdown-menu {
                                        background: #FFFFFF 0% 0% no-repeat padding-box;
										box-shadow: 0px 5px 25px rgba(202, 214, 242, 0.40%);
										border: 1px solid #DFE9FF;
										border-radius: 10px;
                                        margin-top: 35px !important;
                                        display: block;
                                        opacity: 0;
                                        visibility: hidden;
                                        transition: .3s;
                                        transform: unset !important;
                                        left: auto !important;
                                        right: 0 !important;
                        
                                        &.show {
                                            margin-top: 25px !important;
                                            opacity: 1;
                                            visibility: visible;
                                        }
                                        .dropdown-item {
                                            color: var(--heading-color);
                                            padding: 5px 15px 5px 36px;
                                            position: relative;
                                            font: {
                                                size: 14px;
                                            };
                                            i {
                                                color: #686868;
                                                transition: var(--transition);
                                                position: absolute;
                                                left: 15px;
                                                top: 7px;
                                                font: {
                                                    size: 16px;
                                                };
                                            }
                                            &.active, &:active {
                                                color: var(--heading-color);
                                                background-color: transparent;
                                                background-color: #f8f9fa;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .chat-container {
                    height: calc(100vh - 338px);
                    background-color: var(--white-color);
                    padding: 25px 20px;
					.badge  {
						position: relative;
						display: block;
						z-index: 1;
						span {
							background-color: var(--white-color);
							font-size: 14px;
							color: #67748E;
							font-weight: 400;
							padding: 0 10px;
							display: inline-block;
						}
						&::before {
							content: "";
							position: absolute;
							top: 12px;
							left: 0;
							width: 100%;
							height: 1px;
							background-color: #EBF1FF;
							z-index: -1;
						}
					}
                    .chat-content {
                        text-align: center;
						
                        .chat {
                            .chat-avatar {
                                float: right;
								img {
									border-radius: 50px;
									border: 3px solid var(--white-color);
									box-shadow: var(--box-shadow);
								}
                            }
                            .chat-body {
                                overflow: hidden;
    
                                .chat-message {
                                    position: relative;
                                    float: right;
                                    max-width: 600px;
                                    text-align: left;
                                    padding: 12px 16px;
                                    margin: 10px 21.28px 21.28px 0;
                                    clear: both;
                                    word-break: break-word;
                                    color: var(--white-color);
                                    background-color: #3578e5;
                                    border-radius: 30px;
    
                                    p {
                                        color: var(--white-color);
                                        margin-bottom: 0;

                                        a {
                                            color: var(--white-color) !important;
                                        }
                                    }
                                    .time {
                                        position: absolute;
                                        bottom: -25px;
                                        right: 0;
                                        color: #6b7886;
                                        font-size: .8rem;
                                        white-space: nowrap;
                                    }
                                    &:first-child {
                                        margin-top: 0 !important;
                                    }
                                }
                            }
                            &.chat-left {
                                .chat-avatar {
                                    float: left;
                                }
                                .chat-body {
                                    .chat-message {
                                        text-align: left;
                                        float: left;
                                        margin: 10px 0 21.28px 21.28px;
                                        color: #727E8C;
                                        background-color: #F9F9F9;

                                        p {
                                            color: #727E8C;
											font-size: 16px;
                                            a {
                                                color: #727E8C !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .bg-light text-dark {
                            padding: 9px 20px 7px;
                            text-transform: uppercase;
                        }
                    }
                }
                .chat-list-footer {
                    background-color: var(--white-color);
					border-top: 1px solid #EBF1FF;
                    padding: 15px 0;
					margin: 0 20px;
					border-radius: 0 0 10px 10px;
                    form {
                        position: relative;
                        padding-right: 128px;
						.form-group {
							display: block;
							width: 100%;
							position: relative;
						}
                        .form-control {
                            background-color: var(--white-color);
                            height: 45px;
                            border-radius: 30px;
                            transition: var(--transition);
                            padding-left: 15px;
                            font-size: 14.5px;
                            border: 1px solid #DFE3E7;
                            color: var(--heading-color);

                            &:focus {
                                border-color: var(--main-color);
                                box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);
                            }
                        }
                        .send-btn {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            border: none;
                            background-color: #5C31D6;
                            color: var(--white-color);
                            transition: var(--transition);
                            border-radius: 30px;
                            height: 45px;
                            padding: 0 30px;
                            font: {
								font-size: 16px;
                                size: var(--font-size);
                                weight: 500;
                            };
                        }
						.others-option-list {
							padding: 0;
							margin-bottom: 0;
							list-style: none;
							position: absolute;
							top: 10px;
							right: 15px;
							background-color: var(--white-color);
							li {
								display: inline-block;
								margin-right: 10px;
								&:last-child {
									margin-right: 0;
								}
							}
						}
                    }
                }
            }
        }
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .chat-content-area {
        .sidebar-left {
            float: unset;
            .sidebar {
                width: auto;
                padding: 20px;
                
                .top-chat-menu {
                    li {
                        width: 31.33%;
                        flex: 0 0 auto;
                        a {
                            font-weight: 500;
                            padding: 5px;
                            font-size: 12px;
                        }
                    }
                }
                .sidebar-content {
                    .chat-menu {
                        .list-group-user {
                            li {
                                border-bottom: 1px solid #ddd;
                                padding-bottom: 10px;
                                margin-bottom: 10px;
                                border-radius: 0;
                                img {
                                    margin-bottom: 10px;
                                }
                                .d-flex {
                                    display: block !important;
                                }
                            }
                        }
                    }
                }
            }
            .top-chat-menu {
                li {
                    a {
                        font-size: 14px !important;
                    }
                }
            }
        }
        .content-right {
            width: unset;
            float: unset;
            .chat-list-wrapper {
                .chat-list {
                    .chat-list-header {
                        .header-left {
                            h6 {
                                font-size: 12px;
                            }
                            span {
                                font-size: 10px;
                            }
                        }
                        .header-right {
                            ul {
                                li {
                                    margin-left: 1px;
                                    width: 19px;
                                }
                            }
                        }
                    }
                    .chat-container {
                        .chat-content {
                            .chat {
                                .chat-body {
                                    .chat-message {
                                        p {
                                            font-size: 13px;
                                        }
                                    }
                                }
                                &.chat-left {
                                    .chat-body {
                                        .chat-message {
                                            p {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .chat-list-footer {
                        form {
                            padding-right: 50px;
                            .others-option-list {
                                li {
                                    margin-right: -2px; 
                                    img {
                                        width: 20px;
                                    }
                                }
                            }
                            .send-btn {
                                span {
                                    display: none;
                                }
                                padding: 0 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .chat-content-area {
        .sidebar-left {
            float: unset;
            .sidebar {
                width: auto;
                padding: 20px;
                .top-chat-menu {
                    li {
                        width: 31.33%;
                        flex: 0 0 auto;
                        a {
                            font-weight: 500;
                            padding: 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
            .top-chat-menu {
                li {
                    a {
                        font-size: 14px !important;
                    }
                }
            }
        }
        .content-right {
            width: unset;
            float: unset;
            .chat-list-wrapper {
                .chat-list {
                    .chat-list-header {
                        .header-left {
                            h6 {
                                font-size: 12px;
                            }
                            span {
                                font-size: 10px;
                            }
                        }
                        .header-right {
                            ul {
                                li {
                                    margin-left: 1px;
                                    width: 19px;
                                }
                            }
                        }
                    }
                    .chat-container {
                        .chat-content {
                            .chat {
                                .chat-body {
                                    .chat-message {
                                        p {
                                            font-size: 13px;
                                        }
                                    }
                                }
                                &.chat-left {
                                    .chat-body {
                                        .chat-message {
                                            p {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .chat-content-area {
        .sidebar-left {
            .sidebar {
                width: 415px;
            }
        }
        .content-right {
            width: calc(100% - 435px);
            .chat-list-wrapper {
                .chat-list {
                    .chat-container {
                        .chat-content {
                            .chat {
                                &.chat-left {
                                    .chat-body {
                                        .chat-message {
                                            p {
                                                font-size: 13px;
                                            }
                                        }
                                    }
                                }
                                .chat-body .chat-message p {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>