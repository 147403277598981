<template>
    <div class="row">
        <h3>{{ cursoActual.nombre_curso }}</h3>
    </div>
    <div class="visor" style="width: 95%;">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="row right-bar">
                    <div v-if="nombreVideo !== null" class="col-sm-12">
                        <video @ended="onEnd()" id="videoPlayer" style="width: 100%;" controls 
                            poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217" :src="pathVideo" type="video/mp4">
                        </video>
                    </div>
                    <div v-if="contenidoVisor !== null" v-html="contenidoVisor"></div>
                    <div v-if="contenidoVisor === null" @click="continuarLectura()" >Continuar con la siguiente clase</div>
                    <div style="width: 80%;">
                        <ul>
                            <li v-for="(file, indexFile) in files" :key="indexFile" >{{ file.name }} - Error: {{ file.error }}, Success: {{ file.success }}</li>
                        </ul>
                        <file-upload
                            ref="upload"
                            v-model="files"
                            :multiple="true"
                            post-action="/post.method"
                            put-action="/put.method"
                            @input-file="inputFile"
                            @input-filter="inputFilter"
                            style="background: lightgray; width: 100%; min-height: 100px;">
                            Selecciona los archivos
                        </file-upload>
                        <button v-show="!$refs.upload || !$refs.upload.active" @click.prevent="$refs.upload.active = true" type="button" style="padding: 10px; background-color: green; color: #fff;">Subir</button>
                        <button v-show="$refs.upload && $refs.upload.active" @click.prevent="$refs.upload.active = false" type="button">Cancelar</button>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 temas">
                <div class="row">
                    <h6>Temas</h6>
                </div>
                <div class="row contenido" v-for="(modulo, indexModulo) in cursoActual.modulos" :key="indexModulo">
                    <div class="row">
                        <span class="modulo">{{ modulo.nombre_modulo }}</span>
                    </div>
                    <div class="row" v-for="(lectura, indexLectura) in modulo.lecturas" :key="indexLectura"
                        @click="verLectura(indexModulo, indexLectura)">
                        <div class="col-sm-1">
                            <div class="checkbox" >
                                <input class="form-check-input" type="checkbox" :checked="lectura.leida > 0" disabled>
                            </div>
                        </div>
                        <div class="col-sm-11">
                            <span>{{ lectura.nombre_lectura }}-{{ lectura.id_curso_modulo_lectura }}</span>
                        </div>
                        <span style="visibility: hidden;" id="lecturaActual" v-if="lecturaActual == lectura.id_curso_modulo_lectura">{{ indexModulo }}::{{ indexLectura }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios'
import VueUploadComponent from 'vue-upload-component'

export default {
    name: 'VisorCurso',
    components: {
        FileUpload: VueUploadComponent
    },
    data() {
        return {
            id_curso: 0,
            cursoActual: {},
            contenidoVisor: null,
            nombreVideo: null,
            pathVideo: null,
            tipo: '',
            lectura: {},
            lecturaActual: 0,
            indexModuloActual: 0,
            indexLecturaActual: 0,
            files:[],
        }
    },
    mounted() {
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        if (this.$route.query.id !== undefined) {
            this.id_curso = atob(this.$route.query.id)
        }
        axios
            .get(this.$backendUrl + '/me/curso?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursoActual = response.data.data
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
        axios
            .get(this.$backendUrl + '/me/curso/avance/?id=' + this.id_curso, { headers: { token: localStorage.userToken } })
            .then(response => {
                //loaderCursoActual.hide()
                console.log(response.data)
                this.lecturaActual = response.data.data.lectura
            }, error => {
                console.log(error.response.data.description)
                //loaderCursoActual.hide()
            })
    },
    methods: {
        /**
         * Has changed
         * @param  Object|undefined   newFile   Read only
         * @param  Object|undefined   oldFile   Read only
         * @return undefined
         */
        inputFile: function (newFile, oldFile) {
            if (newFile && oldFile && !newFile.active && oldFile.active) {
                // Get response data
                console.log('response', newFile.response)
                if (newFile.xhr) {
                    //  Get the response status code
                    console.log('status', newFile.xhr.status)
                }
            }
        },
        /**
         * Pretreatment
         * @param  Object|undefined   newFile   Read and write
         * @param  Object|undefined   oldFile   Read only
         * @param  Function           prevent   Prevent changing
         * @return undefined
         */
        inputFilter: function (newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                // Filter non-image file
                if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
                    return prevent()
                }
            }

            // Create a blob field
            newFile.blob = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.blob = URL.createObjectURL(newFile.file)
            }
        },

        continuarLectura() {
            let clase = document.getElementById('lecturaActual').innerHTML
            let indices = clase.split('::')
            let idx_modulo = parseInt(indices[0])
            let idx_lectura = parseInt(indices[1])
            if (this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura + 1]) {
                this.verLectura(idx_modulo, idx_lectura+1)
            } else {
                //console.log(idx_modulo + 1, this.cursoActual.modulos[idx_modulo+1])
                this.verLectura(idx_modulo+1, 0)
            }
        },
        verLectura(idx_modulo, idx_lectura) {
            let loaderCursoActual = this.$loading.show({ canCancel: false })
            this.lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
            let lectura = this.cursoActual.modulos[idx_modulo].lecturas[idx_lectura]
            let id_curso = this.cursoActual.id_curso
            let url = this.$backendUrl
            this.contenidoVisor = lectura.contenido_hmtl
            this.nombreVideo = lectura.nombre_video
            this.pathVideo = 'http://localhost:3030/video?id=' + lectura.nombre_video
            this.tipo = lectura.tipo
            if (this.nombreVideo === null) {
                setTimeout(function () {
                    lectura.leida = 1
                    console.log(lectura, id_curso)
                    axios
                        .post(url + '/me/curso/avance', { 'id_curso': id_curso, 'id_curso_modulo_lectura': lectura.id_curso_modulo_lectura }, { headers: { token: localStorage.userToken } })
                        .then(response => {
                            console.log(response)
                        }, error => {
                            console.log(error.response.data.description)
                        })
                }, 10000)
            }
            loaderCursoActual.hide()
        },
        onEnd: function () {
            //alert('SE TERMINÓ EL VIDEO')
            //console.log('test on Ended')
            this.lectura.leida = 1
            axios
                .post(this.$backendUrl + '/me/curso/avance', {
                    'id_curso': this.id_curso,
                    'id_curso_modulo_lectura': this.lectura.id_curso_modulo_lectura
                }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    console.log(response)
                }, error => {
                    console.log(error.response.data.description)
                })
        }
    },
}

</script>

<style lang="scss">
.visor {
    background-color: #fff;
    width: 95%;
    padding: 10px 20px;
    border-radius: 10px;
    .modulo {
        font-weight: bolder;
        font-size: 16px;
    }
    .temas {
        > .row {
            padding: 5px 5px;
        }
        .contenido {
            padding: 5px 10px;
            .row {
                padding: 8px 15px;
            }
        }
        background-color: #eee;
        span {
            font-size: 14px;
            padding: 3px;
        }
    }
}
</style>
