<template>
    <div class="apexcharts-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="others-title">
                    <h3>Dashed Line Chart</h3>
                </div>

                <DashedLineChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Spline Area Chart</h3>
                </div>

                <SplineAreaChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Negative Area Chart</h3>
                </div>

                <NegativeAreaChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Area Chart - Datetime X-Axis</h3>
                </div>

                <AreaChartDatetimeXaxis />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Basic Column Chart</h3>
                </div>

                <BasicColumnChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Column with Data Labels</h3>
                </div>

                <ColumnWithDataLabels />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Stacked Columns</h3>
                </div>

                <StackedColumnsChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Stacked Columns 100%</h3>
                </div>

                <StackedColumns100 />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Column with Negative Values</h3>
                </div>

                <ColumnWithNegativeValues />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Basic Bar Chart</h3>
                </div>

                <BasicBarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Grouped Bar Chart</h3>
                </div>

                <GroupedBarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Stacked Bar Chart</h3>
                </div>

                <StackedBarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Stacked Bars 100% Chart</h3>
                </div>

                <StackedBars100 />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Bar with Negative Values Chart</h3>
                </div>

                <BarWithNegativeValuesChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Custom DataLabels Bar Chart</h3>
                </div>

                <CustomDataLabelsBarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Simple Pie Chart</h3>
                </div>

                <SimplePieChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Simple Donut Chart</h3>
                </div>

                <SimpleDonutChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Monochrome Pie Chart</h3>
                </div>

                <MonochromePieChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Gradient Donut Chart</h3>
                </div>

                <GradientDonutChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Donut with Pattern</h3>
                </div>

                <DonutWithPattern />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Basic Radialbar Chart</h3>
                </div>

                <BasicRadialbarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Multiple Radialbar Chart</h3>
                </div>

                <MultipleRadialbarChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radialbar Custom Angle Circle</h3>
                </div>

                <RadialbarCustomAngleCircle />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radialbar Gradient Circle Chart</h3>
                </div>

                <RadialbarGradientCircleChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radialbar Stroked Circular Gauge</h3>
                </div>

                <RadialbarStrokedCircularGauge />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radialbar Semi Circular Gauge</h3>
                </div>

                <RadialbarSemiCircularGauge />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Basic Radar Charts</h3>
                </div>

                <BasicRadarCharts />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radar – Multiple Series</h3>
                </div>

                <RadarMultipleSeries />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Radar with Polygon-fill</h3>
                </div>

                <RadarWithPolygonFill />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Basic Line Chart</h3>
                </div>

                <BasicLineChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Line with Data Labels Chart</h3>
                </div>

                <LineWithDataLabelsChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Gradient Line Chart</h3>
                </div>

                <GradientLineChart />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Mixed Charts Line Column</h3>
                </div>

                <MixedChartsLineColumn />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Mixed Multiple Y-Axis Charts</h3>
                </div>

                <MixedMultipleYAxisCharts />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Mixed Line & Area Charts</h3>
                </div>

                <MixedLineAreaCharts />
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Mixed Line Column Area Chart</h3>
                </div>

                <MixedLineColumnAreaChart />
            </div>
        </div>
    </div>
</template>

<script>
import DashedLineChart from './DashedLineChart'
import SplineAreaChart from './SplineAreaChart'
import NegativeAreaChart from './NegativeAreaChart'
import AreaChartDatetimeXaxis from './AreaChartDatetimeXaxis'
import BasicColumnChart from './BasicColumnChart'
import ColumnWithDataLabels from './ColumnWithDataLabels'
import StackedColumnsChart from './StackedColumnsChart'
import StackedColumns100 from './StackedColumns100'
import ColumnWithNegativeValues from './ColumnWithNegativeValues'
import BasicBarChart from './BasicBarChart'
import GroupedBarChart from './GroupedBarChart'
import StackedBarChart from './StackedBarChart'
import StackedBars100 from './StackedBars100'
import BarWithNegativeValuesChart from './BarWithNegativeValuesChart'
import CustomDataLabelsBarChart from './CustomDataLabelsBarChart'
import SimplePieChart from './SimplePieChart'
import SimpleDonutChart from './SimpleDonutChart'
import MonochromePieChart from './MonochromePieChart'
import GradientDonutChart from './GradientDonutChart'
import DonutWithPattern from './DonutWithPattern'
import BasicRadialbarChart from './BasicRadialbarChart'
import MultipleRadialbarChart from './MultipleRadialbarChart'
import RadialbarCustomAngleCircle from './RadialbarCustomAngleCircle'
import RadialbarGradientCircleChart from './RadialbarGradientCircleChart'
import RadialbarStrokedCircularGauge from './RadialbarStrokedCircularGauge'
import RadialbarSemiCircularGauge from './RadialbarSemiCircularGauge'
import BasicRadarCharts from './BasicRadarCharts'
import RadarMultipleSeries from './RadarMultipleSeries'
import RadarWithPolygonFill from './RadarWithPolygonFill'
import BasicLineChart from './BasicLineChart'
import LineWithDataLabelsChart from './LineWithDataLabelsChart'
import GradientLineChart from './GradientLineChart'
import MixedChartsLineColumn from './MixedChartsLineColumn'
import MixedMultipleYAxisCharts from './MixedMultipleYAxisCharts'
import MixedLineAreaCharts from './MixedLineAreaCharts'
import MixedLineColumnAreaChart from './MixedLineColumnAreaChart'

export default {
    name: 'ApexCharts',
    components: {
        DashedLineChart,
        SplineAreaChart,
        NegativeAreaChart,
        AreaChartDatetimeXaxis,
        BasicColumnChart,
        ColumnWithDataLabels,
        StackedColumnsChart,
        StackedColumns100,
        ColumnWithNegativeValues,
        BasicBarChart,
        GroupedBarChart,
        StackedBarChart,
        StackedBars100,
        BarWithNegativeValuesChart,
        CustomDataLabelsBarChart,
        SimplePieChart,
        SimpleDonutChart,
        MonochromePieChart,
        GradientDonutChart,
        DonutWithPattern,
        BasicRadialbarChart,
        MultipleRadialbarChart,
        RadialbarCustomAngleCircle,
        RadialbarGradientCircleChart,
        RadialbarStrokedCircularGauge,
        RadialbarSemiCircularGauge,
        BasicRadarCharts,
        RadarMultipleSeries,
        RadarWithPolygonFill,
        BasicLineChart,
        LineWithDataLabelsChart,
        GradientLineChart,
        MixedChartsLineColumn,
        MixedMultipleYAxisCharts,
        MixedLineAreaCharts,
        MixedLineColumnAreaChart,
    }
}
</script>