<template>
    <div class="all-section-area">
        <main class="main-content-wrap">
            <div class="flex-grow-1"></div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'LogoutPage',
    components: {
    },
    mounted() {
        localStorage.removeItem('user')
        localStorage.removeItem('userToken')
        localStorage.removeItem('userRememberMe')
        localStorage.removeItem('userEmail')
        this.$router.push('/login')
    },
}
</script>