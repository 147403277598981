<template>
    <div class="error-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="error-content card-box-style">
                        <h1>404</h1>
                        <h3>Oops! No deberías ver esto</h3>
                        <p>Parece que la pagina quieres ver {{ urlError() }} no existe.</p>
                        <!--<a @click="$router.go(-1)" class="btn btn btn-primary">Regresar</a>-->
                        <router-link class="btn btn btn-primary" to="/perfil">Ir al Inicio</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ErrorPage',
    methods: {
        urlError() {
            return window.location.pathname
        }
    },
}
</script>

<style lang="scss">
.error-area {
	height: 100vh;
}
.error-content {
	text-align: center;
	max-width: 500px;
	margin: auto;
	h1 {
		font-size: 150px;
		line-height: 1;
	}
	h3 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	p {
		margin-bottom: 30px;
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .error-content {
        h1 {
            font-size: 100px;
            margin-top: 0;
        }
    }
}
</style>