<template>
    <div class="contact-list-area">
        <div class="card-box-style" v-if="edicion == true">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-lg-4">
                        <input v-model="form.id_paciente_expediente" type="hidden" class="form-control" >
                        <div class="form-group">
                            <label>Nombre</label>
                            <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre"
                                required>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Paterno</label>
                            <input v-model="form.paterno" type="text" class="form-control" placeholder="Paterno"
                                required>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Materno</label>
                            <input v-model="form.materno" type="text" class="form-control" placeholder="Materno"
                                required>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Correo</label>
                            <input v-model="form.email" type="text" class="form-control" placeholder="Correo"
                                required>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Género</label>
                            <select v-model="form.genero" class="form-control form-select" tabindex="-1" aria-hidden="true">
                                <option value="Femenino">Femenino</option> 
                                <option value="Masculino">Masculino</option> 
                                <option value="No Binario">No Binario</option> 
                                <option value="Prefiero no responder">Prefiero no responder</option> 
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Fecha de nacimiento</label>
                            <VueDatePicker v-model="form.nacimiento" class="form-control" 
                            model-type="yyyy-MM-dd" auto-apply
                            placeholder="Fecha de nacimiento"
                            no-today
                            required
                            :enable-time-picker="false"></VueDatePicker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Sobrenombre</label>
                            <input v-model="form.sobrenombre" type="text" class="form-control" placeholder="Sobrenombre"
                                required>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Estado civil</label>
                            <select v-model="form.estado_civil" class="form-control form-select" tabindex="-1"
                                aria-hidden="true">
                                <option value="Solter@">Solter@</option>
                                <option value="Casad@">Casad@</option>
                                <option value="Unión Libre">Unión Libre</option>
                                <option value="Viud@">Viud@</option>
                                <option value="Divorciad@">Divorciad@</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Escolaridad</label>
                            <select v-model="form.escolaridad" class="form-control form-select" tabindex="-1"
                                aria-hidden="true">
                                <option value="Básica">Básica</option>
                                <option value="Bachillerato">Bachillerato</option>
                                <option value="Superior">Superior</option>
                                <option value="Postgrado">Postgrado</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Teléfono</label>
                            <input v-model="form.telefono" type="text" class="form-control" placeholder="Teléfono">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Ocupación</label>
                            <input v-model="form.ocupacion" type="text" class="form-control" placeholder="Ocupación">
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group">
                            <label>Terapeuta Asignad@</label>
                            <select v-model="form.id_acceso_terapeuta" class="form-control form-select" tabindex="-1"
                                aria-hidden="true">
                                <option v-for="(terapeuta, index) in terapeutas" :key="index" :value="terapeuta.id_acceso">{{ terapeuta.nombre }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label>Domicilio</label>
                            <input v-model="form.domicilio" type="text" class="form-control" placeholder="Domicilio"
                                >
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label>Comentarios</label>
                            <textarea v-model="form.comentario" type="text" class="form-control" placeholder="Comentarios"
                                ></textarea>
                        </div>
                    </div>
                </div><br>
                <div class="row">
                    <div class="save-update">
                        <button type="submit" class="btn btn-success mb-1 me-1"><span v-if="form.id_paciente_expediente == 0">Agregar</span><span v-else>Actualizar</span></button>
                        <button type="button" class="btn btn-danger mb-1 me-1" @click="cancelar" >Cancelar</button>
                        <button type="button" class="btn btn-secondary mb-1 me-1" @click="desactivarPagosOffline" v-if="pacienteSoportaPagoOffiline">Desactivar pagos offline</button>
                        <button type="button" class="btn btn-primary mb-1 me-1" @click="activarPagosOffline" v-if="pacienteSoportaPagoOffiline == false">Activar pagos offline</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="container-fluid">
            <div class="table-responsive scroll-bar">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-5">
                                <select v-model="terapeutaAsignado" class="form-control form-select" 
                                    aria-hidden="true" placeholder="Terapeuta Asignado">
                                    <option value="0" selected>-Todos los terapeutas-</option>
                                    <option v-for="(terapeuta, index) in terapeutas" :key="index" :value="terapeuta.id_acceso">{{ terapeuta.nombre }}</option>
                                </select>
                            </div>
                            <div class="col-md-5">
                                <input v-model="buscar" type="text" class="form-control" placeholder="Nombre del paciente">
                            </div>
                            <div class="col-sm-2">
                                <a  @click="cargarGrid(buscar)" style="color:blue; font-size:24px;">
                                    <i class="ri-search-line"></i>&nbsp;
                                </a>
                                <a  @click="resetGrid" style="color:blue; font-size:24px;">
                                    <i class="ri-refresh-fill"></i>&nbsp;
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <a class="nav-link" @click="agregar" style="color:green;">
                            <i class="ri-add-box-line"></i>&nbsp;Agregar Paciente
                        </a>
                    </div>
                </div><hr/>
                
                <table-lite
                    :is-slot-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    :sortable="table.sortable"
                    :messages="table.messages"
                    :has-checkbox="true"
                    @do-search="doSearch"
                    @is-finished="table.isLoading = false"
                >
                    <template v-slot:nacimiento="paciente">
                        <slot>{{ calculateAge(paciente.value.nacimiento) }}</slot>
                    </template>
                    <template v-slot:id_acceso_terapeuta="paciente">
                        <slot>{{ terapeutasGrid[paciente.value.id_acceso_terapeuta].nombre }}</slot>
                    </template>
                    <template v-slot:telefono="paciente">
                        <slot><a v-if="paciente.value.telefono !== ''" :href="phoneLink(paciente.value.telefono)" target="_blank" style="color: blue; cursor:grab;"><i class="ri-phone-line"></i>Llamar</a></slot>&nbsp;
                        <slot><a v-if="paciente.value.telefono !== ''" :href="whatsAppLink(paciente.value.telefono)" target="_blank" style="color: green; cursor:grab;"><i class="ri-whatsapp-line"></i>Escribir</a></slot>
                    </template>
                    <template v-slot:edit="paciente">
                        <slot><a @click="editar(paciente.value)" style="color: blue; cursor:grab;"><i class="ri-edit-fill"></i>&nbsp;</a></slot>
                    </template>
                </table-lite>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import axios from 'axios'
import TableLite from "vue3-table-lite"
import { reactive } from 'vue'

export default {
    name: 'AdminPacientes',
    components: {
        TableLite,
        VueDatePicker
    },
    data() {
        return {
            buscar: '',
            terapeutaAsignado: 0,
            edicion: false,
            form: {
                id_acceso: 0,
                id_usuario: 0,
                id_paciente_expediente: 0,
                email: "",
                paterno: "",
                materno: "",
                nombre: "",
                telefono: "",
                nacimiento: "",
                genero: "",
                sobrenombre: "",
                estado_civil: "",
                escolaridad: "",
                ocupacion: "",
                domicilio: "",
                comentario: "",
                id_acceso_terapeuta: 0
            },
            terapeutas: [],
            terapeutasGrid: [],
            pacienteSoportaPagoOffiline: false
        }
    },
    watch: {
        'form.activo'(newVal, oldVal) {
            console.log(oldVal)
            if (newVal == 1) {
                this.form.activo = true
            }
            if (newVal == 0) {
                this.form.activo = false
            }
        }
    },
    mounted() {
        let loader = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/admin/terapeutas', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.terapeutas = response.data
                var terapeutasGrid = []
                response.data.forEach(terapeuta => {
                    terapeutasGrid[terapeuta.id_acceso] = {
                        nombre: terapeuta.nombre + ' ' + terapeuta.paterno,
                        email: terapeuta.email,
                        id_acceso: terapeuta.id_acceso
                    } 
                })
                this.terapeutasGrid = terapeutasGrid
                loader.hide()
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
        this.doSearch(this.$backendUrl, '', 0)
    },
    methods: {
        calculateAge(nacimiento) {
            const currentDate = new Date();
            const diffTime = currentDate - new Date(nacimiento);
            const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return Math.floor(totalDays / 365.25);
        },
        phoneLink(link) {
            var urlLink = "tel:" + link
            return urlLink
        },
        whatsAppLink(link) {
            var urlLink = "https://wa.me/52" + link
            return urlLink
        },
        editar(data) {
            console.log(data)
            data.nacimiento = moment(data.nacimiento).format('YYYY-MM-DD')
            this.form = data
            this.edicion = true
            window.scrollTo(0, 0)
            this.pacienteSoportaPagoOffiline = false
            let loader = this.$loading.show({ canCancel: false })
            axios
                .get(this.$backendUrl + '/admin/paciente-tiene-plan-offline?id=' + data.id_acceso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    console.log(response)
                    this.pacienteSoportaPagoOffiline = true
                    loader.hide()
                }, error => {
                    console.log(error)
                    this.pacienteSoportaPagoOffiline = false
                    loader.hide()
                })
        },
        cancelar() {
            this.form = {}
            this.edicion = false
        },
        agregar() {
            this.form = {}
            this.form.id_paciente_expediente = 0
            this.form.genero = 'Prefiero no responder'
            this.edicion = true
            window.scrollTo(0, 0)
        },
        cargarGrid() {
            this.doSearch(this.$backendUrl, this.buscar, this.terapeutaAsignado)
        },
        resetGrid() {
            this.buscar = ''
            this.terapeutaAsignado = 0
            this.doSearch(this.$backendUrl, '',0)
        },
        guardar() {
            let loader = this.$loading.show({ canCancel: false })
            if (this.form.activo) {
                this.form.activo = 1
            } else {
                this.form.activo = 0
            }
            if (this.form.id_plan_pago == 0) {
                axios
                    .post(this.$backendUrl + '/admin/planes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.resetGrid()
                            this.edicion = false
                            this.form = {}
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            } else {
                axios
                    .put(this.$backendUrl + '/admin/planes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.resetGrid()
                            this.edicion = false
                            this.form = {}
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            }
        },
        submit() {
            let loader = this.$loading.show({ canCancel: false })
            if (this.form.id_paciente_expediente > 0) {
                axios
                    .put(this.$backendUrl + '/admin/pacientes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.edicion = false
                            this.doSearch(this.$backendUrl, '',0)
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            } else {
                axios
                    .post(this.$backendUrl + '/admin/pacientes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.edicion = false
                            this.doSearch(this.$backendUrl, '',0)
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            }
        },
        activarPagosOffline() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .put(this.$backendUrl + '/admin/paciente-tiene-plan-offline', {id_acceso: this.form.id_acceso, soporta: true}, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        loader.hide()
                        this.$swal({ icon: 'success', text: 'Ahora el paciente puede colocar sesiones sin restricción' })
                        this.resetGrid()
                        this.edicion = false
                        this.form = {}
                    }
                },
                error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        },
        desactivarPagosOffline() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .put(this.$backendUrl + '/admin/paciente-tiene-plan-offline', { id_acceso: this.form.id_acceso, soporta: false }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        loader.hide()
                        this.$swal({ icon: 'warning', text: 'Ahora el paciente debe pagar por un paquete para colocar sesiones' })
                        this.resetGrid()
                        this.edicion = false
                        this.form = {}
                    }
                },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
        },
    },
    setup() {
        // Init Your table settings
        const table = reactive({
            columns: [
                {
                    label: "ID",
                    field: "id_usuario",
                    width: "3%",
                    sortable: false,
                    isKey: true,
                },
                {
                    label: "Paterno",
                    field: "paterno",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Materno",
                    field: "materno",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Nombre",
                    field: "nombre",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Genero",
                    field: "genero",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Edad",
                    field: "nacimiento",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Terapeuta",
                    field: "id_acceso_terapeuta",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Contactar",
                    field: "telefono",
                    width: "10%",

                },
                {
                    label: "",
                    field: "edit",
                    width: "10%",
                    
                },
            ],
            rows: [],
            totalRecordCount: 0,
            sortable: {
                order: "id",
                sort: "asc",
            },
        })

        const doSearch = (backendUrl, searchText, terapeuta, offset, limit, order, sort) => {
            table.isLoading = true
            let url = backendUrl + '/admin/pacientes'
            url = url + '?nombre=' + searchText + '&terapeuta=' + terapeuta
            url = url + '&offset=' + offset + '&limit=' + limit
            axios
                .get(url, { headers: { token: localStorage.userToken } })
                .then(response => {
                    table.rows = response.data.data
                    table.totalRecordCount = response.data.totalRows
                    table.sortable.order = order
                    table.sortable.sort = sort
                    table.isLoading = false
                }, error => {
                    console.log(error.response.data.description)
                })
        }

        // doSearch('', '',0 , 0, 10, 'id', 'asc')

        return {
            table,
            doSearch,
        }
    },
}

</script>

<style lang="scss">
.dp__input_reg {
    height: 40px;
    color: var(--heading-color);
    border: 1px solid #F1F5FF;
    background-color: #F1F5FF;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    transition: var(--transition);
    margin-top: -20px;
    margin-left: 20px;
}
.contact-list-area {
    margin-bottom: 24px;

    .table-responsive {
        background-color: var(--white-color);
        box-shadow: var(--box-shadow);
        padding: 30px;
        border-radius: var(--border-radius);
        height: 595px;

        &.scroll-bar {
            overflow-x: scroll !important;

            &::-webkit-scrollbar {
                height: 4px;
            }
        }
    }

    .table {
        tbody {
            tr {
                border-bottom: 1px solid #EBF1FF;
                border-radius: 10px;
                transition: var(--transition);

                &:hover {
                    background-color: #F8FAFF;
                    border-color: var(--white-color);
                }

                td {
                    padding: 20px 20px;
                    border-radius: 10px;

                    .form-check {
                        margin-bottom: 0;

                        .form-check-input {
                            &:focus {
                                box-shadow: none;
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 12px;
                            color: #67748E;
                        }

                        img {
                            width: 50px;
                        }

                        .border {
                            border: 3px solid var(--white-color) !important;
                            box-shadow: var(--box-shadow);
                        }
                    }

                    a {
                        font-size: 16px;
                        color: #67748E;
                    }

                    .location {
                        color: #67748E;
                        font-size: 16px;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        float: right;

                        li {
                            display: inline-block;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-scrollbar:before {
        background-color: var(--main-color);
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 30px;
        top: -20px;
        cursor: pointer;
    }

    .form-check .form-check-input {
        border-color: #1765fd;
        background-color: rgba(23, 101, 253, 0.1);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=checkbox] {
        background-color: #1765fd;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;

                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}
</style>