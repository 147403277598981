<template>
    <div class="loaders-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-five" id="loader-style">
                                <div class="content">
                                    <div class="box"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-thirteen" id="loader-style">
                                <div class="preloader-wrap">
                                    <span class="light"></span>
                                    <span></span>
                                    <span></span>
                                    <span class="light"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-fifteen" id="loader-style">
                                <div class="pl-bow-1 pl-bow-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-sixteen" id="loader-style">
                                <div class="pl-butterfly-1 pl-butterfly-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-seventeen" id="loader-style">
                                <div class="pl-flip-1 pl-flip-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-eighteen" id="loader-style">
                                <div class="pl-kinetic-1 pl-kinetic-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-nineteen" id="loader-style">
                                <div class="pl-spark-1 pl-spark-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-twenty" id="loader-style">
                                <div class="pl-star-1 pl-star-2"></div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="card-box-style mb-24">
                            <div class="preloader-style-fourteen" id="loader-style">
                                <div class="preloader-wrap">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoadersContent'
}
</script>

<style lang="scss">
/* Preloader Area Style One
============================================*/
.preloader-style-one {
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: #5b5bff;
	top: 0;
	left: 0;
	z-index: 99999;
	.lds-ripple {
		position: relative;
		width: 55px;
		height: 55px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		div {
			position: absolute;
			border: 2px dashed #ffffff;
			opacity: 1;
			animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
			&:nth-child(2) {
				animation-delay: -0.5s;
			}
		}
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 60%;
		z-index: -1;
		background: #5b5bff;
		transition:  all ease 0.5s;
	}
	&::after {
		left: auto;
		right: 0;
	}
	&.preloader-deactivate {
		visibility: hidden;
		&::after {
			width: 0;
		}
		&::before {
			width: 0;
		}
		.loader {
			opacity: 0;
			visibility: hidden;
		}
	}
}
@keyframes lds-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1
    }
    100% {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0
    }
}

/* Preloader Area Style Two
============================================*/
.preloader-style-two {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    .main-circle {
        width: 150px;
        height: 150px;
        border:4px solid blue;
        border-top: 4px solid transparent  ;
        border-right:4px solid transparent;
        position:relative;
        border-radius:50%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: rotate-two 2s infinite;
        &::before {
            width: 100%;
            height: 100%;
            position:absolute;
            content:'';
            border:4px solid transparent;
            border-right:4px solid blue;
            transform: rotate(40deg);
            border-radius:50%;
        }
        .green-circle {
            animation: rotate-two 2s infinite .4s;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 120px;
            border:4px solid blue;
            border-top:4px solid transparent;
            border-right:4px solid transparent;
            transform: rotate(-20deg);
            border-radius:50%;
            position:relative;
            &::before {
                content:'';
                width: 100%;
                height: 100%;
                border-radius:50%;
                position:absolute;
                border:4px solid transparent;
                border-right:4px solid blue;
                transform: rotate(60deg);
            }
            .brown-circle {
                animation: rotate-two 2s infinite .6s;
                width: 80px;
                height: 80px;
                border:4px solid blue;
                border-top:4px solid transparent;
                border-right:4px solid transparent;
                transform: rotate(-20deg);
                border-radius:50%;
            }
        }
    }
}
@keyframes rotate-two {
    from {}
    to {
      transform: rotate(360deg);
    }
}

/* Preloader Area Style Three
============================================*/
.preloader-style-three {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #5b5bff;
    .preloader-wrap {
        width: 80px;
        height: 80px;
        position: relative;
        transform: rotate(45deg);
        animation: walk-three 1s linear infinite;
        span {
            width: 40px;
            height: 40px;
            background: #FFB037;
            position: absolute;
            animation: spin-three 1s linear infinite;
            &:nth-child(1) {
                top: 0;
                left: 0;
            }
            &:nth-child(2) {
                top: 0;
                right: 0;
            }
            &:nth-child(3) {
                bottom: 0;
                left: 0;
            }
            &:nth-child(4) {
                bottom: 0;
                right: 0;
            }
        }
        .light {
            background: #FFEAA5;
        }
    }
}
@keyframes walk-three {
    50% {
      width: 130px;
      height: 130px;
    }
}
@keyframes spin-three {
    0% {
      transform: rotate(0);
    }
    50%, 90%, 100% {
      transform: rotate(90deg);
    }
}

/* Preloader Area Style Four
============================================*/
.preloader-style-four {
    background-color: #000000;
    height: auto;
    .preloader-wrap {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 100px;
        height: 100px;
        transform: translate(-50px, -50px);
    }
	.loader-inner-1 {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		position: absolute;
		top: 0;
		left: 0;
		transform: rotate(70deg);
		&:before {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			position: absolute;
			right: 0;
			animation-name: loading-1;
			animation-iteration-count: infinite;
			animation-direction: normal;
			animation-duration: 2s;
			background: #06aed5;
		}
	}
	.loader-inner-2 {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		position: absolute;
		top: 0;
		right: 0;
		transform: rotate(160deg);
		&:before {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			position: absolute;
			right: 0;
			animation-name: loading-1;
			animation-iteration-count: infinite;
			animation-direction: normal;
			animation-duration: 2s;
			background: #ec008c;
		}
	}
	.loader-inner-3 {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		position: absolute;
		bottom: 0;
		right: 0;
		transform: rotate(-110deg);
		&:before {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			position: absolute;
			right: 0;
			animation-name: loading-1;
			animation-iteration-count: infinite;
			animation-direction: normal;
			animation-duration: 2s;
			background: #ffbf00;
		}
	}
	.loader-inner-4 {
		display: block;
		width: 20px;
		height: 20px;
		border-radius: 20px;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: rotate(-20deg);
		&:before {
			content: "";
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			position: absolute;
			right: 0;
			animation-name: loading-1;
			animation-iteration-count: infinite;
			animation-direction: normal;
			animation-duration: 2s;
			background: #079c00;
		}
	}
}
@keyframes loading-1 {
    0% {
        width: 20px;
        right: 0;
    }
    30% {
        width: 120px;
        right: -100px;
    }
    60% {
        width: 20px;
        right: -100px;
    }
}

/* Preloader Area Style Five
============================================*/
.preloader-style-five {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    .content {
        width: 80px;
        height: 80px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            width: 90%;
            height: 90%;
            background: white;
            
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: black;
            animation: rotate-five 12s infinite alternate;
        }
        .box {
            height: 50%;
            width: 50%;
            background: #000;
            position: relative;
            z-index: 10;
            animation: 12s rotate-five infinite alternate;
        }
    }
}
@keyframes rotate-five {
    0%{
        transform: rotate(180deg);
    }
    50% {
        transform: rotate(360deg);
    }
}

/* Preloader Area Style Six
============================================*/
.preloader-style-six {
    background-color: #000;
    .preloader-container {
        position: absolute;
        width: 80px;
        height: 80px;
        animation: spin-six 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
        left: 50%;
        top: 50%;
        transform: translate(-50px, -50px);
    }
    .ball {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 11%;
        height: 50%;
        background-color: transparent;
        transform-origin: bottom center;
        overflow: visible;
        &:nth-of-type(2) {
            transform: translate(-50%, 0) rotate(36deg);
        }
        &:nth-of-type(3) {
            transform: translate(-50%, 0) rotate(72deg);
        }
        &:nth-of-type(4) {
            transform: translate(-50%, 0) rotate(108deg);
        }
        &:nth-of-type(5) {
            transform: translate(-50%, 0) rotate(144deg);
        }
        &:nth-of-type(6) {
            transform: translate(-50%, 0) rotate(180deg);
        }
        &:nth-of-type(7) {
            transform: translate(-50%, 0) rotate(216deg);
        }
        &:nth-of-type(8) {
            transform: translate(-50%, 0) rotate(252deg);
        }
        &:nth-of-type(9) {
            transform: translate(-50%, 0) rotate(288deg);
        }
        &:nth-of-type(10) {
            transform: translate(-50%, 0) rotate(324deg);
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 56%;
            border-radius: 70px;
            background-color: #ef1144;
        }
        &::after {
            position: absolute;
            content: "";
            bottom: -100;
            left: 0;
            width: 100%;
            height: 56%;
            border-radius: 70px;
            background-color:  #ffc7bd;
            animation: opacity-six 2.5s infinite linear;
            opacity: 0;
        }
    }
}
@keyframes spin-six {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
@keyframes opacity-six {
    20% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

/* Preloader Area Style Seven
============================================*/
.preloader-style-seven {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    ::before {
        position: absolute;
        content: "";
        box-sizing: border-box;
    }
    .preloader-wrap {
        .preloader-container {
            width: 80px;
            height: 80px;
        }
        .ball {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            width: 15%;
            height: 50%;
            background-color:transparent;
            transform-origin: bottom center;
            overflow: visible;
            &::before {
                top: 0;
                left: 0;
                width: 67%;
                height: 60%;
                border-radius: 50%;
                background-color: #ef1144;
                animation: opacity-seven 1.2s infinite linear;
                opacity: 0.1;
            }
            &:nth-of-type(2) {
                transform: translate(-50%, 0) rotate(30deg);
                &::before {
                animation-delay: 0.1s;
            }
            }
            &:nth-of-type(3) {
                transform: translate(-50%, 0) rotate(60deg);
                &::before {
                    animation-delay: 0.2s;
                }
            }
            &:nth-of-type(4) {
                transform: translate(-50%, 0) rotate(90deg);
                &::before {
                    animation-delay: 0.3s;
                }
            }
            &:nth-of-type(5) {
                transform: translate(-50%, 0) rotate(120deg);
                &::before {
                    animation-delay: 0.4s;
                }
            }
            &:nth-of-type(6) {
                transform: translate(-50%, 0) rotate(150deg);
                &::before {
                    animation-delay: 0.5s;
                }
            }
            &:nth-of-type(7) {
                transform: translate(-50%, 0) rotate(180deg);
                &::before {
                    animation-delay: 0.6s;
                }
            }
            &:nth-of-type(8) {
                transform: translate(-50%, 0) rotate(210deg);
                &::before {
                    animation-delay: 0.7s;
                }
            }
            &:nth-of-type(9) {
                transform: translate(-50%, 0) rotate(240deg);
                &::before {
                    animation-delay: 0.8s;
                }
            }
            &:nth-of-type(10) {
                transform: translate(-50%, 0) rotate(270deg);
                &::before {
                    animation-delay: 0.9s;
                }
            }
            &:nth-of-type(11) {
                transform: translate(-50%, 0) rotate(300deg);
                &::before {
                    animation-delay: 1s;
                }
            }
            &:nth-of-type(12) {
                transform: translate(-50%, 0) rotate(330deg);
                &::before {
                    animation-delay: 1.1s;
                }
            }
        }
    }
}
@keyframes opacity-seven {
    0% {
      opacity: 1;
    }
    85% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
}

/* Preloader Area Style Eight
============================================*/
.preloader-style-eight {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    ::before {
        position: absolute;
        content: "";
        box-sizing: border-box;
    }
    .preloader-wrap {
        .preloader-container {
            width: 80px;
            height: 80px;
        }
        .ball {
            &::before {
                top: 0;
                left: 0;
                width: 80%;
                padding-bottom: 80%;
                border-radius: 50%;
                background-color: #ef1144;
            }
            position: absolute;
            top: 0;
            left: 50%;
            width: 15%;
            height: 50%;
            background-color: transparent;
            transform-origin: bottom center;
            overflow: visible;
            animation: rotate-eight 5s infinite linear;
            &:nth-of-type(2) {
                animation-delay: 0.15s;
            }
            &:nth-of-type(3) {
                animation-delay: 0.3s;
            }
            &:nth-of-type(4) {
                animation-delay: 0.45s;
            }
            &:nth-of-type(5) {
                animation-delay: 0.6s;
            }
            &:nth-of-type(6) {
                animation-delay: 0.75s;
            }
            &:nth-of-type(7) {
                animation-delay: 0.9s;
            }
            &:nth-of-type(8) {
                animation-delay: 1.05s;
            }
            &:nth-of-type(9) {
                animation-delay: 1.2s;
            }
            &:nth-of-type(10) {
                animation-delay: 1.35s;
            }
            &:nth-of-type(11) {
                animation-delay: 1.5s;
            }
        }
    }
}
@keyframes rotate-eight {
    0% {
        transform: translate(-50%, 0%) rotate(0deg);
    }
    40% {
        transform: translate(-50%, 0%) rotate(360deg);
    }
    60% {
        transform: translate(-50%, 0%) rotate(520deg);
    }
    90% {
        transform: translate(-50%, 0%) rotate(590deg);
    }
    100% {
        transform: translate(-50%, 0%) rotate(720deg);
    }
}

/* Preloader Area Style Nine
============================================*/
.preloader-style-nine {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    ::before {
        position: absolute;
        content: "";
        box-sizing: border-box;
    }
    .preloader-wrap {
        .preloader-container {
            width: 80px;
            height: 80px;
        }
        .petal {
            position: absolute;
            width: 30%;
            height: 50%;
            bottom: 50%;
            left: 50%;
            transform-origin: bottom center;
            border-radius: 50%;
            z-index: 2;
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                bottom: 0%;
                left: 0%;
                transform-origin: bottom center;
                border-radius: 50%;
                background-color:  #ef1144;
                z-index: 2;
                animation: opacity-nine 0.8s infinite linear;
                opacity: 1;
                border-left: 1px solid #ffffff;
            }
            &:nth-of-type(6) {
                &::before {
                    background-color:  transparent;
                    animation-delay: 0.5s;
                }
                transform: translate(-50%, 0%) rotate(225deg);
            }
            &:nth-of-type(7) {
                &::before {
                    background-color:  transparent;
                    animation-delay: 0.6s;
                }
                transform: translate(-50%, 0%) rotate(270deg);
            }
            &:nth-of-type(8) {
                &::before {
                    background-color:  transparent;
                    animation-delay: 0.7s;
                }
                transform: translate(-50%, 0%) rotate(315deg);
            }
            &:nth-of-type(1) {
                transform: translate(-50%, 0%);
                &::before {
                    animation-delay: 0s;
                }
            }
            &:nth-of-type(2) {
                transform: translate(-50%, 0%) rotate(45deg);
                &::before {
                    animation-delay: 0.1s;
                }
            }
            &:nth-of-type(3) {
                transform: translate(-50%, 0%) rotate(90deg);
                &::before {
                    animation-delay: 0.2s;
                }
            }
            &:nth-of-type(4) {
                transform: translate(-50%, 0%) rotate(135deg);
                &::before {
                    animation-delay: 0.3s;
                }
            }
            &:nth-of-type(5) {
                transform: translate(-50%, 0%) rotate(180deg);
                &::before {
                    animation-delay: 0.4s;
                }
            }
        }
        .petal-1 {
            position: absolute;
            width: 30%;
            height: 50%;
            bottom: 50%;
            left: 50%;
            transform-origin: bottom center;
            border-radius: 50%;
            z-index: -1;
            &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 100%;
                bottom: 0%;
                left: 0%;
                transform-origin: bottom center;
                border-radius: 50%;
                background-color:  #ef1144;
                z-index: 2;
                animation: opacity-nine 0.8s infinite linear;
                opacity: 1;
            }
            &:nth-of-type(9) {
                transform: translate(-50%, 0%) rotate(225deg);
                &::before {
                    animation-delay: 0.5s;
                }
            }
            &:nth-of-type(10) {
                transform: translate(-50%, 0%) rotate(270deg);
                &::before {
                    animation-delay: 0.6s;
                }
            }
            &:nth-of-type(11) {
                transform: translate(-50%, 0%) rotate(315deg);
                &::before {
                    animation-delay: 0.7s;
                }
            }
        }
        .ball {
            position: absolute;
            width: 28%;
            height: 28%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 1px solid #ffffff;
            background-color:  #ef1144;
            z-index: 3;
        }
    }
}
@keyframes opacity-nine {
    0%   { 
        opacity: 1; 
    }
    95%  { 
        opacity: 0.2; 
    }
    100% { 
        opacity: 0.2; 
    }
}

/* Preloader Area Style Ten
============================================*/
.preloader-style-ten {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    ::before {
        position: absolute;
        content: "";
        box-sizing: border-box;
    }
    .preloader-container {
        width: 80px;
        height: 80px;
        animation: rotate-ten 3s infinite linear reverse;
        .ball {
            position: absolute;
            width: 65%;
            height: 65%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            border: 3px solid #ffffff;
            background-color: #ef1144;
            z-index: 5;
        }
        .petal {
            position: absolute;
            width: 28%;
            height: 50%;
            bottom: 50%;
            left: 50%;
            transform-origin: bottom center;
            &::before {
                position: absolute;
                content: "";
                width: 72%;
                height: 40%;
                left: 50%;
                transform-origin: 0% 0%;
                transform: scale(1, 1.8) rotate(45deg);
                background: #ef1144;
            }
            &::after {
                position: absolute;
                content: "";
                width: 72%;
                height: 40%;
                left: 50%;
                transform-origin: 0% 0%;
                transform: scale(1, 1.8) rotate(45deg);
                background: #ef1144;
                top: 100%;
            }
            &:nth-of-type(1) {
                transform: translate(-50%, 0%) rotate(0deg);
            }
            &:nth-of-type(2) {
                transform: translate(-50%, 0%) rotate(30deg);
            }
            &:nth-of-type(3) {
                transform: translate(-50%, 0%) rotate(60deg);
            }
            &:nth-of-type(4) {
                transform: translate(-50%, 0%) rotate(90deg);
            }
            &:nth-of-type(5) {
                transform: translate(-50%, 0%) rotate(120deg);
            }
            &:nth-of-type(6) {
                transform: translate(-50%, 0%) rotate(150deg);
            }
        }
    }
}
@keyframes rotate-ten {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

/* Preloader Area Style Eleven
============================================*/
.preloader-style-eleven {
    .preloader-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .loader-circle {
        background-color: transparent;
        border: 5px solid rgb(30,30,30);
        opacity: .9;
        border-right: 5px solid #3694FF;
        border-left: 5px solid #3694FF;
        border-radius: 50px;
        box-shadow: 0 0 35px #3694FF;
        width: 80px;
        height: 80px;
        animation: spinPulse-eleven 1s infinite ease-in-out;
        animation: spinPulse-eleven 1s infinite linear;
    }
    .loader-circle1 {
        background-color: rgba(0,0,0,0);
        border: 5px solid rgb(30,30,30);
        opacity: .9;
        border-left: 5px solid #3694FF;
        border-right: 5px solid #3694FF;
        border-radius: 50px;
        box-shadow: 0 0 2px #3694FF, inset 0 0 2px #3694FF;
        width: 60px;
        height: 60px;
        position: relative;
        top: -80px;
        left: 10px;
        animation: spinoffPulse-eleven 1s infinite linear;
    }
}
@keyframes spinoffPulse-eleven {
    0% { 
        transform:rotate(0deg); 
    }
    100% { 
        transform:rotate(360deg);
    }
}
@keyframes spinPulse-eleven {
    0% { 
        transform:rotate(160deg); opacity:0; box-shadow:0 0 1px #fff; 
    }
    50% { 
        transform:rotate(145deg); opacity:1;
    }
    100% { 
        transform:rotate(-320deg); opacity:0; 
    }
}
@keyframes spinoffPulse-eleven {
    0% { 
        transform:rotate(-160deg); opacity:0; box-shadow: 0 0 0px #fff, inset 0 0 0px #fff; 
    }
    50% { 
        transform:rotate(-145deg); opacity:1;
    }
    100% { 
        transform:rotate(320deg); opacity:0; 
    }
}

/* Preloader Area Style Twelve
============================================*/
.preloader-style-twelve {
    .preloader-wrap {
        position: absolute;
        box-sizing: border-box;
        width: 200px;
        height: 200px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 200px;
		height: 200px;
		border-radius: 50%;
		animation: anime 4s infinite;
		&:nth-child(1) {
			animation-delay: 4s;
		}
		&:nth-child(2) {
			animation-delay: 3.5s;
		}
		&:nth-child(3) {
			animation-delay: 3s;
		}
		&:nth-child(4) {
			animation-delay: 2.5s;
		}
		&:nth-child(5) {
			animation-delay: 2s;
		}
		&:nth-child(6) {
			animation-delay: 1.5s;
		}
		&:nth-child(7) {
			animation-delay: 1s;
		}
		&:nth-child(8) {
			animation-delay: 0.5s;
		}
	}
}
@keyframes anime {
    0% {
        width: 0px;
        height: 0px;
        box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
    }
    20% {
        box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
    }
    40% {
        box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
    }
    100% {
        width: 200px;
        height: 200px;
        box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
    }
}

/* Preloader Area Style Thirteen
============================================*/
.preloader-style-thirteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .preloader-wrap {
        width: 80px;
        height: 80px;
        position: relative;
        transform: rotate(45deg);
        span {
            width: 40px;
            height: 40px;
            background: #FFB037;
            position: absolute;
            animation: spin-thirteen 1s linear infinite;
            &:nth-child(1) {
                top: 0;
                left: 0;
            }
            &:nth-child(2) {
                top: 0;
                right: 0;
            }
            &:nth-child(3) {
                bottom: 0;
                left: 0;
            }
            &:nth-child(4) {
                bottom: 0;
                right: 0;
            }
        }
        .light {
            background: #FFEAA5;
        }
    }
}
@keyframes spin-thirteen {
    0% {
      transform: rotate(0);
    }
    50%, 90%, 100% {
      transform: rotate(90deg);
    }
}

/* Preloader Area Style Thirteen
============================================*/
.preloader-style-fourteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    .preloader-wrap {
        position: relative;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background: linear-gradient(#00FF00, #00FF00, #00FF00);
        animation: rotate-fourteen 1s linear infinite;
        span {
            position: absolute;
            width: 68px;
            height: 68px;
            border-radius: 50%;
            background: linear-gradient(#00b2ff, #00ffbb, #6bff6b);
            &:nth-child(1) {
                filter: blur(5px);
            }
            &:nth-child(2) {
                filter: blur(10px);
            }
        }
        &::after {
            content: "";
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            background: #162434;
            border-radius: 50%;
        }
    }
}
@keyframes rotate-fourteen {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

/* Preloader Area Style Thirteen
============================================*/
.preloader-style-fifteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-bow-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-bow-2 {
            animation-name: rotate-fifteen;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
}
@keyframes rotate-fifteen {
    from { 
        transform: scale(1,1) rotate(0turn) 
    }
    25%, 35%, 50% { 
        transform: scale(1,1) rotate(0.75turn) 
    }
    30% { 
        transform: scale(1.25,1) rotate(0.75turn) 
    }
    75%, 85%, to { 
        transform: scale(1,1) rotate(1.5turn) 
    }
    80% { 
        transform: scale(1,1.25) rotate(1.5turn) 
    }
}

/* Preloader Area Style Sixteen
============================================*/
.preloader-style-sixteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-butterfly-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-butterfly-2 {
            animation-name: butterflyA;
            &:before {
                animation-name: butterflyB;
            }
            &:after {
                animation-name: butterflyC;
            }
        }
    }
}
@keyframes butterflyA {
    from, to { 
        transform: translateY(0) 
    }
    50% { 
        transform: translateY(25%) 
    }
}
@keyframes butterflyB {
    from, 50%, to { 
        transform: rotate(60deg) 
    }
    25%, 75% { 
        transform: rotate(120deg) 
    }
}
@keyframes butterflyC {
    from, 50%, to { 
        transform: rotate(300deg) 
    }
    25%, 75% { 
        transform: rotate(240deg) 
    }
}

/* Preloader Area Style Sixteen
============================================*/
.preloader-style-seventeen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-flip-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-flip-2 {
            animation-name: flipA;
            animation-timing-function: steps(1);
            &:before {
                transform-origin: 50% 50%;
                animation-name: flipB;
            }
            &:after {
                transform-origin: 50% 50%;
                animation-name: flipC;
            }
        }
    }
    
}
@keyframes flipA {
    from, 75%, to { 
        transform: rotate(0) 
    }
    25%, 50% { 
        transform: rotate(90deg) 
    }
}
@keyframes flipB {
    from { 
        transform: translateY(0) rotateX(0deg) 
    }
    6.25% { 
        transform: translateY(0) rotateX(0deg) 
    }
    12.5% { 
        transform: translateY(-50%) rotateX(90deg) 
    }
    18.75% { 
        transform: translateY(0) rotateX(180deg) 
    }
    31.25% { 
        transform: translateY(0) rotateX(180deg) 
    }
    37.5% { 
        transform: translateY(-50%) rotateX(270deg) 
    }
    43.75% { 
        transform: translateY(0) rotateX(360deg) 
    }
    50% { 
        transform: translateY(0) rotateX(360deg) 
    }
    56.25% { 
        transform: translateY(0) rotateX(360deg) 
    }
    62.5% { 
        transform: translateY(-50%) rotateX(450deg) 
    }
    68.75% { 
        transform: translateY(0) rotateX(540deg) 
    }
    81.25% { 
        transform: translateY(0) rotateX(540deg) 
    }
    87.5% { 
        transform: translateY(-50%) rotateX(630deg) 
    }
    93.75% { 
        transform: translateY(0) rotateX(720deg) 
    }
    to { 
        transform: translateY(0) rotateX(720deg) 
    }
}
@keyframes flipC {
    from { 
        transform: translateY(100%) rotateZ(180deg) rotateX(0deg) 
    }
    6.25% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(0deg) 
    }
    12.5% { 
        transform: translateY(150%) rotateZ(180deg) rotateX(90deg) 
    }
    18.75% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(180deg) 
    }
    31.25% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(180deg) 
    }
    37.5% { 
        transform: translateY(150%) rotateZ(180deg) rotateX(270deg) 
    }
    43.75% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(360deg) 
    }
    50% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(360deg) 
    }
    56.25% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(360deg) 
    }
    62.5% { 
        transform: translateY(150%) rotateZ(180deg) rotateX(450deg) 
    }
    68.75% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(540deg) 
    }
    81.25% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(540deg) 
    }
    87.5% { 
        transform: translateY(150%) rotateZ(180deg) rotateX(630deg) 
    }
    93.75% { 
        transform: translateY(100%) rotateZ(180deg) rotateX(720deg) 
    }
    to { 
        transform: translateY(100%) rotateZ(180deg) rotateX(720deg) 
    }
}

/* Preloader Area Style Eighteen
============================================*/
.preloader-style-eighteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-kinetic-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-kinetic-2 {
            &:before {
                animation-name: kineticA;
            }
            &:after {
                animation-name: kineticB;
            }
        }
    }
   
}
@keyframes kineticA {
    from, 25% { 
        transform: rotate(0deg) 
    }
    50%, 75% { 
        transform: rotate(180deg) 
    }
    to { 
        transform: rotate(360deg) 
    }
}
@keyframes kineticB {
    from { 
        transform: rotate(90deg) 
    }
    25%, 50% { 
        transform: rotate(270deg) 
    }
    75%, to { 
        transform: rotate(450deg) 
    }
}


/* Preloader Area Style Nineteen
============================================*/
.preloader-style-nineteen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-spark-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-spark-2 {
            &:before {
                animation-name: scaleA;
            }
            &:after {
                animation-name: scaleB;
            }
        }
    }
    
}
@keyframes scaleA {
    from, 50%, to { 
        transform: scale(1) 
    }
    25%, 75% { 
        transform: scale(-1) 
    }
}
@keyframes scaleB {
    from, 50%, to { 
        transform: rotate(90deg) scale(0) 
    }
    12.5%, 62.5% { 
        transform: rotate(90deg) scale(1) 
    }
    37.5%, 87.5% { 
        transform: rotate(90deg) scale(-1) 
    }
}

/* Preloader Area Style Twenty
============================================*/
.preloader-style-twenty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-color: #ffffff;
    .pl-star-1 {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        margin: 0 auto 18px auto;
        position: relative;
        width: 50px;
        height: 50px;
        &:before,  &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 50%;
            animation-duration: 2s;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
            background: currentColor;
            display: block;
            transform-origin: 50% 100%;
            clip-path: polygon(0 0,100% 0,50% 100%);
        }
        &.pl-star-2 {
            animation-name: starA;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    
}
@keyframes starA {
    from, to { 
        opacity: 1; 
        transform: rotate(0deg) scale(1) 
    }
    25%, 75.1% { 
        opacity: 0; 
        transform: rotate(0deg) scale(0) 
    }
    25.1%, 75% { 
        opacity: 0; transform: rotate(90deg) scale(0) 
    }
    50% { 
        opacity: 1; 
        transform: rotate(90deg) scale(1) 
    }
}

/* Preloader Loading Content
============================================*/
.preloader-loading-content {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1140px;
    transform: translate(-50%, -50%);
    text-align: center;
    h2 {
        font-size: 50px;
        margin-bottom: 20px;
    }
    p {
        line-height: 1.5;
    }
}

/* Preloader Close Btn
============================================*/
.preloader-close-btn {
    background-color: transparent;
    position: relative;
    background-color: #ef1144;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border: none;
    cursor: pointer;
    float: right;
    z-index: 99999;
    transition: all ease 0.5s;
    &:hover {
        background-color: #ff0000;
    }
    &::before {
        content: "";
        position: absolute;
        top: 13px;
        left: 8px;
        width: 15px;
        height: 2px;
        background-color: #ffffff;
        transform: rotate(-45deg);
    }
    &::after {
        content: "";
        position: absolute;
        top: 13px;
        left: 8px;
        width: 15px;
        height: 2px;
        background-color: #ffffff;
        transform: rotate(45deg);
    }
    &:focus {
        box-shadow: none;
        border: none;
        outline: none;
    }
}
</style>