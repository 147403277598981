<template>
    <div class="profile-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row align-items-center">
                    <div class="col-md-4">
                        <div class="profile-info d-flex align-items-center">
                            <img class="w-25 rounded-4" :src="getIMGPath()" alt="profile-img">
                            <div class="profile-name ms-4">
                                <h4>{{ profile.nombre }}</h4>
                                <h6>{{ calculateAge() }} años</h6>
                                <span>Miembro desde: {{ calculateSince() }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <router-link to="/perfil/editar" class="btn btn-secondary">
                            Editar Perfil
                        </router-link>&nbsp;
                        <router-link to="/paciente/sesiones" class="btn btn-primary">
                            Agendar una sesión
                        </router-link>
                        <!--<button class="btn btn-secondary">
                        Dejar un mensaje
                    </button>-->
                    </div>
                </div>
            </div>
            <div class="profile-details card-box-style" v-if="profile.nombre != '' || profile.nombre != undefinded">
                <div class="row">
                    <div class="col-lg-12">
                        <h3>
                            Información Personal
                            <router-link to="/perfil/editar" style="font-size: 10px; color: #0d6efd !important;">Editar mi información</router-link>
                        </h3>
                    </div>

                    <div class="col-lg-6">
                        <div class="personal-info">
                            <ul class="list-inline">
                                <li>
                                    <span>Nombre :</span>
                                    {{ profile.nombre }} {{ profile.paterno }} {{ profile.materno }}
                                </li>
                                <li>
                                    <span>Genero :</span>
                                    {{ profile.genero }}
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="personal-info">
                            <ul class="list-inline">
                                <li>
                                    <span>Email :</span>
                                    <a :href="'mailto:' + email">{{ email }}</a>
                                </li>
                                <li>
                                    <span>Teléfono :</span>
                                    <a :href="'tel:+' + profile.telefono">: {{ profile.telefono }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="biography">
                    <h3>Algo sobre mi</h3>
                    <p>{{ profile.bio }}</p>
                </div>
            </div>
            <div class="profile-details card-box-style" v-else>
                <div class="row">
                    <div class="col-lg-12">
                        <h3>Para comenzar captura tu información personal</h3>
                        <router-link to="/profile/edit" class="btn btn-success">
                            Da clic aquí
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="card-box-style">
                <div class="row align-items-center">
                    <div class="row">
                        <div class="col-md-2">
                            <h6>Servicios contratados</h6>
                        </div>
                        <div class="col-md-8">
                            <div class="col-md-3" style="border: 1px #aaa solid; border-radius: 5px; padding: 5px; background-color: rgb(249 255 207);" v-for="(plan, index) in planes" :key="index">
                                <div class="profile-info d-flex align-items-center">
                                    <div class="profile-name md-4">
                                        <h4>{{ plan.nombre }}</h4>
                                        <span>{{ plan.descripcion }}</span>
                                        <span><strong v-if="plan.redimido == 1">Redimido</strong><strong v-else>Vigente</strong></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <router-link to="/perfil/pagos" class="btn btn-success">
                                <i class="bx bx-plus"></i> Nuevo servicio
                            </router-link>
                        </div>
                    </div>
                </div>

                <!--
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <h3>Cuentas vinculadas</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <a class="btn btn-success" v-if="calendy">
                                <i class="bx bx-calendar"></i>&nbsp;Calendy Vinculado
                            </a>
                            <a class="btn btn-primary" href="https://auth.calendly.com/oauth/authorize?client_id=9U65TG-inn7gckN_FG95X10u-BsNeWNaujALMUSC9FQ&response_type=code&redirect_uri=http://localhost:8081/oauthcalendy" v-else>
                                <i class="bx bx-calendar"></i>&nbsp;Vincular Calendy
                            </a>
                        </div>
                    </div>
                </div>
                -->

            </div>
            <!--
            <div class="profile-details card-box-style">
                <ul class="list-inline profile-menu">
                    <li>
                        <router-link to="/profile" class="active">Cuentas de servicios</router-link>
                    </li>
                </ul>
                <div class="row">
                    <div class="col-lg-12">
                        <h3>Cuentas vinculadas</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <a class="btn btn-success" v-if="calendy">
                                <i class="bx bx-calendar"></i>&nbsp;Calendy Vinculado
                            </a>
                            <a class="btn btn-primary" href="https://auth.calendly.com/oauth/authorize?client_id=9U65TG-inn7gckN_FG95X10u-BsNeWNaujALMUSC9FQ&response_type=code&redirect_uri=http://localhost:8081/oauthcalendy" v-else>
                                <i class="bx bx-calendar"></i>&nbsp;Vincular Calendy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'
import moment from 'moment'

export default {
    name: 'ProfileContent',
    data() {
        return {
            planes: [],
            age: 0,
            email: '',
            sessions: 0,
            calendy: false,
            meet: false,
            avatar: 'avatar-00',
            profile: {
                nombre: 'Desconocido',
                paterno: '',
                materno: '',
                nacimiento: '',
                telefono: '',
                genero: '',
                bio: '',
                created_at: '',
            },
        }
    },
    mounted() {
        let userData = JSON.parse(localStorage.user)
        this.email = userData.email
        if (typeof userData.general !== 'undefined') {
            if (typeof userData.general.avatar !== 'undefined') {
                this.avatar = userData.general.avatar
            }
        }
        let loaderInfo = this.$loading.show({ canCancel: false })
        let loaderPlanes = this.$loading.show({ canCancel: false })
        //let loaderConfig = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/info/general', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.profile = response.data
                loaderInfo.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderInfo.hide()
            })
        axios
            .get(this.$backendUrl + '/me/info/planes', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.planes = response.data
                loaderPlanes.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderPlanes.hide()
            })
        /*
        axios
            .get(this.$backendUrl + '/me/config?ruta=/oauth/' , { headers: { token: localStorage.userToken } })
            .then(configs => {
                for (let i = 0; i < 10; i++) {
                    if (configs.data.data[i] == undefined) {
                        i = 10
                    } else {
                        if (configs.data.data[i].ruta == '/oauth/calendy') {
                            this.calendy = configs.data.data[i].valor
                            localStorage.oauthCalendyToken = configs.data.data[i].valor
                        }
                        if (configs.data.data[i].ruta == '/oauth/meet') {
                            this.meet = configs.data.data[i].valor
                            localStorage.oauthMeetToken = configs.data.data[i].valor
                        }     
                    }
                }
                loaderConfig.hide()
            }, error => {
                console.log(error.configs.data.description)
                loaderConfig.hide()
            })
        */
    },
    methods: {
        calculateAge() {
            if (this.profile.nacimiento) {
                const currentDate = new Date()
                const diffTime = currentDate - new Date(this.profile.nacimiento)
                const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))
                return Math.floor(totalDays / 365.25)
            } else {
                return 0
            }
        },
        calculateSince() {
            return moment(this.profile.created_at).format('MMM YYYY')
        },
        getIMGPath() {
            if (!this.avatar) {
                this.avatar = 'avatar-00'
            }
            console.log(this.avatar)
            return require("@/assets/images/avatar/" + this.avatar + ".png")
        }
    },
}
</script>

<style lang="scss">
.profile-info {
	.w-25 {
		width: 120px !important;
	}
	h4 {
		font-size: 18px;
		margin-bottom: 10px;
	}
	span {
		font-size: 14px;
		display: block;
		margin-bottom: 20px;
	}
	.follow-email {
		.btn {
			&:last-child {
				margin-right: 0;
			}
			&.btn-primary {
				background-color: var(--main-color);
				margin-right: 20px;
			}
			&.btn-secondary {
				background-color: #5C31D6;
			}
		}
		
	}
}
.profile-activity {
	background-color: var(--white-color);
	box-shadow: var(--box-shadow);
	padding: 20px;
	text-align: center;
	border-radius: var(--border-radius);
	margin-bottom: 24px;
	i {
		font-size: 20px;
		width: 50px;
		height: 50px;
		line-height: 50px;
		display: inline-block;
		background-color: #5C31D6;
		text-align: center;
		color: var(--white-color);
		border-radius: 50px;
		margin-bottom: 20px;
	}
	h3 {
		font-weight: 600;
		font-size: 15px;
		margin-bottom: 10px;
	}
	h2 {
		font-size: 20px;
		font-weight: 700;
	}
}
.edit-massage {
	.btn {
		&:last-child {
			margin-right: 0;
		}
		&.btn-primary {
			background-color: var(--main-color);
			margin-right: 20px;
		}
		&.btn-secondary {
			background-color: #5C31D6;
		}
	}
	
}
.profile-details {
	padding: 100px !important;
	.profile-menu {
		margin-bottom: 0;
		border-bottom: 1px solid #eeeeee;
		padding-bottom: 20px;
		margin-bottom: 30px;
		li {
			display: inline-block;
			margin-right: 30px;
			&:last-child {
				margin-right: 0;
			}
			a {
				&:hover {
					color: var(--main-color);
				}
				&.active {
					color: var(--main-color);
				}
			}
		}
	}
	h3 {
		font-size: 18px;
		margin-bottom: 20px;
	}
	.personal-info {
		margin-bottom: 30px;
		ul {
			li {
				margin-bottom: 15px;
				span {
					font-weight: 600;
					font-size: 14px;
					color: var(--heading-color);
				}
			}
		}
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .profile-details {
        .profile-menu {
            margin-bottom: 20px;
        }
    }
    .edit-massage {
        .btn.btn-primary {
            margin-right: 0;
        }
    }
    .profile-info {
        display: block !important;
        text-align: center;
        img {
            margin-bottom: 10px;
        }
        .ms-4 {
            margin-left: 0 !important;
        }
        .follow-email {
            margin-bottom: 20px;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .profile-details {
        padding: 30px;
        .profile-menu {
            margin-bottom: 20px;
        }
    }
    .edit-massage {
        text-align: center !important;
        .btn.btn-primary {
            margin-right: 0;
        }
    }
    .profile-info {
        display: block !important;
        text-align: center;
        img {
            margin-bottom: 10px;
        }
        .ms-4 {
            margin-left: 0 !important;
        }
        .follow-email {
            margin-bottom: 20px;
        }
    }
}
</style>