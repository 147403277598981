<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Card Design" pageTitle="Card Design" />
            <CardDesign />
            <div class="flex-grow-1"></div>
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import CardDesign from '../CardDesign/CardDesign'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'CardDesignPage',
    components: {
        MainHeader,
        PageTitle,
        CardDesign,
        FooterArea,
    },
    mounted () {
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>