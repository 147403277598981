<template>
    <div class="profile-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="edit-profile-content card-box-style">
                        <h3>Elige tu avatar</h3>
                        <ul class="avatar-list style-two d-flex align-items-center">
                            <li :class="[form.avatar == 'avatar-00' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-00')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-00.png" alt="Images">
                            </li>
                            <li :class="[form.avatar == 'avatar-01' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-01')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                            </li>
                            <li :class="[form.avatar == 'avatar-02' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-02')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-02.png" alt="Images">
                            </li>
                            <li :class="[form.avatar == 'avatar-03' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-03')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-03.png" alt="Images">
                            </li>
                            <li :class="[form.avatar == 'avatar-04' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-04')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-04.png" alt="Images">
                            </li>
                            <li :class="[form.avatar == 'avatar-05' ? 'avatar-selected' : '']" v-on:click="seleccionarAvatar('avatar-05')">
                                <img class="rounded-pill" src="../../assets/images/avatar/avatar-05.png" alt="Images">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="edit-profile-content card-box-style">
                        <h3>Información general</h3>
                        <form @submit.prevent="submit">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Nombre</label>
                                        <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre" required>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Paterno</label>
                                        <input v-model="form.paterno" type="text" class="form-control" placeholder="Paterno" required>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Materno</label>
                                        <input v-model="form.materno" type="text" class="form-control" placeholder="Materno" required>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>Teléfono</label>
                                        <input v-model="form.telefono" type="text" class="form-control" placeholder="Teléfono" required>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Género</label>
                                            <select v-model="form.genero" class="form-control form-select" tabindex="-1" aria-hidden="true">
                                                <option value="Femenino">Femenino</option> 
                                                <option value="Masculino">Masculino</option> 
                                                <option value="No Binario">No Binario</option> 
                                                <option value="Prefiero no responder">Prefiero no responder</option> 
                                            </select>
                                        </div>
                                    </div>
                                <div class="col-md-4">
                                    <label>Fecha de nacimiento</label>
                                    <VueDatePicker v-model="form.nacimiento" class="form-control" 
                                    model-type="yyyy-MM-dd" auto-apply
                                    placeholder="Fecha de nacimiento"
                                    no-today
                                    required
                                    :enable-time-picker="false"></VueDatePicker>
                                </div>
                                
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Algo sobre ti</label>
                                        <textarea v-model="form.bio" class="form-control" cols="30" rows="10" placeholder="Una pequeña biografía"></textarea>
                                    </div>
                                </div>
                                
                                <div class="save-update">
                                    <button type="submit" class="default-btn">Actualizar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="edit-profile-content card-box-style">
                        <h3>Cambiar Password</h3>
                        <form @submit.prevent="savePassword">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Nuevo</label>
                                        <input required v-model="password.nuevo" type="password" class="form-control">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label>Confirmar</label>
                                        <input required v-model="password.confirmar" type="password" class="form-control">
                                    </div>
                                </div>
                            
                                <div class="save-update">
                                    <button class="btn btn-primary me-2">Actualizar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import axios from 'axios'

export default {
    name: 'EditProfile',
    components: { VueDatePicker },
    data() {
        return {
            form: {
                nombre: '',
                paterno: '',
                materno: '',
                nacimiento: '',
                telefono: '',
                genero: '',
                bio: '',
                avatar: 'avatar-00'
            },
            password: {
                nuevo: '',
                confirmar: '',
            }
        }
    },
    mounted() {
        let loader = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/info/general', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.form = response.data
                loader.hide()
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
        
    },
    computed: {
        scorePassword() {
            let score = 0;
            if (this.password.nuevo === '') return score;
            let letters = {};
            for (let i = 0; i < this.password.nuevo.length; i++) {
                letters[this.password.nuevo[i]] = (letters[this.password.nuevo[i]] || 0) + 1;
                score += 5.0 / letters[this.password.nuevo[i]];
            }
            let variations = {
                digits: /\d/.test(this.password.nuevo),
                lower: /[a-z]/.test(this.password.nuevo),
                upper: /[A-Z]/.test(this.password.nuevo),
                special: /\W/.test(this.password.nuevo)
            };
            let variationsCount = 0;
            for (let check in variations) {
                variationsCount += (variations[check] === true) ? 1 : 0;
            }
            score += (variationsCount - 1) * 10;
            return parseInt(score);
        }
    },
    methods: {
        seleccionarAvatar(avatar) {
            this.form.avatar = avatar
        },
        submit() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/me/info/general', this.form, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        let userData = JSON.parse(localStorage.user)
                        userData.general = this.form
                        localStorage.user = JSON.stringify(userData)
                        loader.hide()
                        this.$swal({ icon: 'success', text: 'Datos guardados' })
                    }
                },
                error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        },
        savePassword() {
            if (this.scorePassword < 50) {
                this.$swal({ icon: 'error', text: 'Tu password es muy simple, intenta usar mayúsculas, números y algún signo de admiración' })
                return false
            }
            if (this.password.confirmar != this.password.nuevo) {
                this.$swal({ icon: 'error', text: 'El password nuevo y su confirmación no coinciden' })
                return false
            }
            let loader = this.$loading.show({ canCancel: false })
            axios
                .put(this.$backendUrl + '/me/password', { password: this.password.nuevo }, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        loader.hide()
                        this.$swal({ icon: 'success', text: 'Datos guardados' })
                    }
                }, error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        }
    },
}
</script>

<style lang="scss">
.edit-profile-content {
	h3 {
		font-size: 18px;
		margin-bottom: 30px;
	}
	label {
		margin-bottom: 10px;
		font-size: 14px;
	}
	.form-group {
		margin-bottom: 24px;
		.form-select {
			cursor: pointer;
			
		}
		.form-select[aria-selected] {
			background-color: red;
			color: white;
		}
	}
}

.avatar-selected {
    border-bottom: 3px #000 solid;
}
</style>