<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Servicios disponibles" pageTitle="Servicios disponibles" />
            <ProfilePaymentsContent v-if="id_pago == ''"/>
            <div v-if="id_pago != ''">
                <router-link to="/perfil" class="success">
                    <h5>Gracias por contratar {{ plan.nombre }}</h5>
                    <span>Haz clic aquí para regresar a la información de tu cuenta</span>
                </router-link>
            </div>
            <div class="flex-grow-1"></div>
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import ProfilePaymentsContent from '../Profile/ProfilePaymentsContent'
import FooterArea from '../Layouts/FooterArea'
import axios from 'axios'

export default {
    name: 'ProfilePaymentsPage',
    components: {
        MainHeader,
        PageTitle,
        ProfilePaymentsContent,
        FooterArea,
    },
    data() {
        return {
            form: {
                stripe_payment_id: '',
                id_plan_pago: 0,
                stripe_price_id: '',
                stripe_product_id: '',
                sesiones_redimidas: 0,
                llamadas_redimidas: 0
            },
            id_pago: '',
            id_plan: '',
            plan: {}
        }
    },
    mounted() {
        this.id_pago = ''
        this.id_plan = ''
        if (this.$route.query.id !== undefined) {
            this.id_pago = this.$route.query.id.trim()
            this.id_plan = this.$route.query.plan.trim()
        }
        if (this.id_pago != '' && this.id_pago !== undefined) {
            let loaderInfo = this.$loading.show({ canCancel: false })
            this.form.id_plan_pago = this.id_plan
            this.form.stripe_payment_id = this.id_pago
            axios
                .get(this.$backendUrl + '/configs/plan?id=' + this.id_plan, { headers: { token: localStorage.userToken } })
                .then(response => {
                    this.plan = response.data
                    this.form.stripe_payment_id = this.id_pago
                    this.form.id_plan_pago = this.id_plan
                    this.form.stripe_price_id = this.plan.stripe_price_id
                    this.form.stripe_product_id = this.plan.stripe_product_id
                    this.form.sesiones_incluidas = this.plan.sesiones
                    this.form.llamadas_incluidas = this.plan.llamadas
                    this.form.sesiones_redimidas = 0
                    this.form.llamadas_redimidas = 0
                    axios
                        .post(this.$backendUrl + '/me/info/planes', this.form, { headers: { token: localStorage.userToken } })
                        .then(response => {
                            console.log(response)
                        }, error => {
                            console.log(error.response.data.description)
                        })
                    loaderInfo.hide()
                }, error => {
                    console.log(error.response.data.description)
                    loaderInfo.hide()
                })
        } else {
            this.id_pago = ''
        }
        document.body.classList.add('body-bg-f8faff')
    },
}
</script>