<template>
    <div class="contact-list-area">
        <div class="container-fluid">
            <div class="table-responsive scroll-bar">
                <table class="table align-middle mb-0">
                    <tbody>
                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-2.png" alt="user-2">
                                    </label>
                                    <div class="info">
                                        <h4>Zennilia Anderson</h4>
                                        <a href="mailto:anderson@gmail.com">anderson@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-3.png" alt="user-3">
                                    </label>
                                    <div class="info">
                                        <h4>Zara Canela</h4>
                                        <a href="mailto:anderson@gmail.com">zaracanela@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-4.png" alt="user-4">
                                    </label>
                                    <div class="info">
                                        <h4>Victor James</h4>
                                        <a href="mailto:anderson@gmail.com">victorjames@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-5.png" alt="user-1">
                                    </label>
                                    <div class="info">
                                        <h4>Jane Ronan</h4>
                                        <a href="mailto:anderson@gmail.com">janeronan@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-1.png" alt="user-1">
                                    </label>
                                    <div class="info">
                                        <h4>Angela Carter</h4>
                                        <a href="mailto:anderson@gmail.com">angelacarter@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-2.png" alt="user-2">
                                    </label>
                                    <div class="info">
                                        <h4>John Karahan</h4>
                                        <a href="mailto:anderson@gmail.com">johnkarahan@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78911</a>
                            </td>
                            <td>
                                <span class="location">31/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <div class="form-check d-flex align-items-center">
                                    <input class="form-check-input" type="checkbox">
                                    <label class="form-check-label ms-2">
                                        <img class="rounded-circle ms-3 me-3 border" src="../../assets/images/user/user-1.png" alt="user-1">
                                    </label>
                                    <div class="info">
                                        <h4>Anderson Coopers</h4>
                                        <a href="mailto:anderson@gmail.com">anderson@gmail.com</a>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <a href="tel:+(124)45678910">+ (124) 456 78910</a>
                            </td>
                            <td>
                                <span class="location">32/B, Central town, England</span>
                            </td>
                            <td>
                                <ul class="d-flex justify-content-betweens">
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/call-2.svg" alt="call-2">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/messages-2.svg" alt="messages-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/video-3.svg" alt="video-3">
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <img src="../../assets/images/icon/trash-2.svg" alt="trash-2">
                                        </a>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactList'
}
</script>

<style lang="scss">
.contact-list-area {
	margin-bottom: 24px;
	.table-responsive {
		background-color: var(--white-color);
		box-shadow: var(--box-shadow);
		padding: 30px;
		border-radius: var(--border-radius);
		height: 595px;
	}
	.table {
		tbody {
			tr {
				border-bottom: 1px solid #EBF1FF;
				border-radius: 10px;
				transition: var(--transition);
				&:hover {
					background-color: #F8FAFF;
					border-color: var(--white-color);
				}
				td {
					padding: 20px 20px;
					border-radius: 10px;
					.form-check {
						margin-bottom: 0;
						.form-check-input {
							&:focus {
								box-shadow: none;
							}
						}
						h4{
							font-size: 16px;
							font-weight: 600;
							margin-bottom: 0;
						}
						a {
							font-size: 12px;
							color: #67748E;
						}
						img {
							width: 50px;
						}
						.border {
							border: 3px solid var(--white-color) !important;
							box-shadow: var(--box-shadow);
						}
					}
					
					a {
						font-size: 16px;
						color: #67748E;
					}
					.location {
						color: #67748E;
						font-size: 16px;
					}
					ul {
						padding-left: 0;
						margin-bottom: 0;
						list-style-type: none;
						float: right;
						li {
							display: inline-block;
							margin-right: 30px;
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
	.simplebar-scrollbar:before {
		background-color: var(--main-color);
	}
	.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
		height: 30px;
		top: -20px;
		cursor: pointer;
	}
	.form-check .form-check-input {
		border-color: #1765fd;
		background-color: rgba(23, 101, 253, 0.1);
		width: 20px;
		height: 20px;
	}
	.form-check-input:checked[type=checkbox] {
		background-color: #1765fd;
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;
            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }
        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;
            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }
        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}
</style>