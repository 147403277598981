<template>
    <div class="calendar-area">
        <div class="container-fluid">
			<div class="row" v-if="this.editando">
				<div class="col-md-12">
					<div class="calendar-wrap style-two" v-if="expedientes.length <= 0">
						<h6 style="color: #dc3545;">Antes de que puedas agendar sesiones, necesitamos saber un poco más de ti</h6>
						<router-link to="/paciente/perfil?editando=expediente" style="color: #1765FD; font-size: 14px; margin-top: 5px; display: inline-block;">Por favor, da clic aquí</router-link>
					</div>
					<div class="calendar-wrap style-two" v-else>
						<h6>Agenda tu sesión <span v-if="terapeuta.id_acceso>0" style="color: #1e6ea5;">con <i>{{ terapeuta.nombre }} {{ terapeuta.paterno }}</i></span></h6>
						<form @submit.prevent="submit">
							<div class="row">
								<div class="col-md-2">
									<div class="form-group">
										<label><strong>Quién tomará la sesión?</strong></label><br/>
										<select v-model="indexExpediente" class="form-control form-select" tabindex="-1" aria-hidden="true" @change="horasDisponibles()" :disabled="form.id_paciente_sesion > 0">
											<option v-for="(expediente, index) in expedientes" :key="index" :value="index">
												{{ expediente.sobrenombre }}
											</option>
										</select>
									</div>
								</div>
								<div class="col-md-2">
									<div class="form-group">
										<label><strong>Tipo</strong></label><br/>
										<select v-model="form.tipo_sesion" class="form-control form-select" tabindex="-1" aria-hidden="true" :disabled="form.id_paciente_sesion > 0">
											<option value="Sesion" v-if="sesiones_disponibles > 0">Sesión de terapia</option>
											<option value="Llamada" v-if="llamadas_disponibles > 0">Llamada de apoyo</option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label>Horas disponibles para el día {{ this.form.fecha }}</label><br/>
										<div class="horas">
											<span v-for="(hora, index) in horas" :key="index">
												<label style="border: 1px #000 solid; padding: 5px 15px; font-family: monospace;">
													<input v-model="form.hora" type="radio" :value="hora" :id="hora"/>{{ hora }}
												</label>&nbsp;
											</span>
										</div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label><strong>Describe el objetivo que quieres cubrir en la sesión</strong></label><br/>
										<input type="text" class="form-control" v-model="form.objetivo" placeholder="Objetivo sesión" required :disabled="form.id_paciente_sesion > 0"/>
									</div>
								</div>
							</div>
							<div class="save-update">
								<button type="submit" class="default-btn">Agendar</button>
								<button type="submit" class="btn btn-danger mb-1 me-1" @click="cancelar()">Cancelar</button>
							</div>
						</form>
					</div>
				</div>
			</div>
            <div class="row" v-else>
                <div class="col-lg-8">
                    <div class="calendar-wrap style-two">
						<div class="calendar-wrap style-two" v-if="expedientes.length <= 0">
							<h6 style="color: #dc3545;">Antes de que puedas agendar sesiones, necesitamos saber un poco más de ti</h6>
							<router-link to="/paciente/perfil?editando=expediente" style="color: #1765FD; font-size: 14px; margin-top: 5px; display: inline-block;">Por favor, da clic aquí</router-link>
						</div>
						<div class="calendar-wrap style-two" v-else-if="llamadas_disponibles == 0 && sesiones_disponibles == 0">
							<h6 style="color: #dc3545;">No tienes saldo disponible para agendar eventos</h6>
							<router-link to="/perfil/pagos" style="color: #1765FD; font-size: 14px; margin-top: 5px; display: inline-block;">Haz clic aquí, para contratar nuestros servicios</router-link>
						</div>
						<div v-else>
							<h6>Elige un día para agendar tu sesión</h6>
							<Qalendar
								:selected-date="new Date()"
								:events="events"
								:config="config"
								@edit-event="editarEvento"
								@updated-period="datePeriod"
								@day-was-clicked="agregarEvento"
							/>
						</div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="performance-date">
                        <div class="main-title d-flex justify-content-between align-items-center">
                            <h3>Sesiones de hoy</h3>
                        </div>
						<div v-if="sesionesDia.length == 0">
							<h6 style="color: #dc3545;">No hay eventos asignados para hoy</h6>
						</div>
                        <ul class="performance-date-list">
                            <li v-for="(sesion, index) in sesionesDia" :key="index">
                                <h3>{{sesion.objetivo_sesion}} <span>{{sesion.inicio}} - {{ sesion.fin }}</span></h3>
                                <a :href="sesion.meet_url" style="font-size: 12px;font-weight: 500;color: #1765FD;"><i class="ri-video-chat-fill">{{ sesion.meet_url }}</i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Qalendar } from "qalendar";
import axios from 'axios';
//import moment from 'moment'
import moment from 'moment-timezone'

export default {
    name: 'SessionsContent',
	components: {
        Qalendar,
    },
    data() {
		return {
			start: '',
			end: '',
			events: [],
			sesiones: [],
			sesionesDia: [],
			editando: false,
			indexExpediente: -1,
			sesiones_disponibles: 0,
			llamadas_disponibles: 0,
			horas: [],
			expedientes: [],
			terapeuta: {
				id_acceso: 0,
				nombre: '',
				paterno: '',
				materno: ''
			},
			form: {
				tipo_sesion: 'Sesion',
				fecha: '',
				hora: '',
				objetivo: 'Seguimiento',
				id_paciente_expediente: 0,
				id_acceso_terapeuta: 0,
				id_paciente_sesion: 0,
			},
			config: {
				locale: "es-MX",
				defaultMode: "month",
				style: {
					colorSchemes: {
						meetings: {
							color: "#fff",
							backgroundColor: "#131313",
						},
						sports: {
							color: "#fff",
							backgroundColor: "#ff4081",
						},
					},
				},
			},
		};
	},
	mounted() {
		let actualDate = new Date()
		let desde = new Date()
		let hasta = new Date()
		desde.setDate(1)
		hasta.setDate(1)
		hasta.setMonth(actualDate.getMonth() + 1)
		this.start = desde
		this.end = hasta
		this.loadData()
	},
	methods: {
		loadData() {
			this.editando = false
			this.form.fecha = ''
			this.form.hora = ''
			this.form.objetivo = ''
			this.form.tipo_sesion = 'Sesion'
			let loader = this.$loading.show()
			var sesionesFormat = []
			axios
				.get(this.$backendUrl + '/me/terapia/citas?desde=' + moment(this.start).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYY-MM-DD') + '&hasta=' + moment(this.end).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYY-MM-DD'), { headers: { token: localStorage.userToken } })
				.then(response => {
					let events = []
					this.sesionesDia = []
					let eventsDay = []
					response.data.data.forEach(function (session, index) {
						//console.log(index, session)
						if (moment(session.inicio).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
							let _data = {
								inicio: session.inicio.substr(11, 5), // moment.tz(session.inicio, 'America/Mexico_City').format('HH:mm'),
								fin: session.fin.substr(11, 5), //moment.tz(session.fin, 'America/Mexico_City').format('HH:mm'),
								objetivo_sesion: session.objetivo_sesion,
								tipo_sesion: session.tipo_sesion,
								meet_url: session.meet_url
							}
							console.log(_data,index)
							eventsDay.push(_data)
						}
						session.inicio = session.inicio.substr(0, 16)
						session.fin = session.fin.substr(0, 16)
						sesionesFormat.push(session)
						var editable = false
						if (session.notas_terapeuta == null) {
							//if (moment(session.inicio).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).add(-24, "hours").format('YYYYMMDDHHmm') >= moment().utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYYMMDDHHmm')) {
							//	editable = true
							//}
						}
						var color = "yellow"
						if (session.tipo_sesion == 'Llamada') {
							color = "red"
						}
						let event = {
							title: session.objetivo_sesion,
							location: session.meet_url,
							time: { start: moment(session.inicio).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYY-MM-DD HH:mm'), end: moment(session.fin).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYY-MM-DD HH:mm') },
							color: color,
							isEditable: editable,
							id: session.id_paciente_sesion
						}
						events.push(event)
					})
					this.sesiones = sesionesFormat
					this.events = events
					this.sesionesDia = eventsDay
					loader.hide()
				}, error => {
					console.log(error.response.data.description)
					loader.hide()
				})
			let loaderExpedientes = this.$loading.show()
			axios
				.get(this.$backendUrl + '/me/info/expediente', { headers: { token: localStorage.userToken } })
				.then(response => {
					this.expedientes = response.data
					loaderExpedientes.hide()
				}, error => {
					console.log(error.response.data.description)
					loaderExpedientes.hide()
				})
			let loaderSaldos = this.$loading.show()
			axios
				.get(this.$backendUrl + '/me/info/saldos', { headers: { token: localStorage.userToken } })
				.then(response => {
					response.data.forEach(element => {
						this.llamadas_disponibles += (element.llamadas_incluidas - element.llamadas_redimidas)
						this.sesiones_disponibles += (element.sesiones_incluidas - element.sesiones_redimidas)
					})
					loaderSaldos.hide()
				}, error => {
					console.log(error.response.data.description)
					loaderSaldos.hide()
				})
		},
		datePeriod: function (e) {
			this.start = e.start
			this.end = e.end
			this.loadData()
		},
		editarEvento: function (e) {
			//console.log(moment)
			console.log(e)
			var indexExpediente = -1
			this.sesiones.forEach(element => {
				if (element.id_paciente_sesion == e) {
					console.log(element)
					this.form = {
						fecha: moment(element.fecha).format('YYYY-MM-DD'),
						hora: moment(element.fecha).format('HH:mm'),
						objetivo: element.objetivo_sesion,
						tipo_sesion: element.tipo_sesion,
						id_paciente_expediente: element.id_paciente_expediente,
						id_acceso_terapeuta: element.id_acceso_terapeuta,
						id_paciente_sesion: element.id_paciente_sesion
					}
					indexExpediente = element.id_paciente_expediente
				}
			})
			this.expedientes.forEach((expediente, index) => {
				if (expediente.id_paciente_expediente == indexExpediente) {
					this.indexExpediente = index
				}
			})
			this.horasDisponibles()
			this.editando = true
		},
		agregarEvento: function (e) {
			if (e >= moment().format('YYYY-MM-DD')) {
				if (this.llamadas_disponibles > 0 || this.sesiones_disponibles > 0) {
					this.editando = true
					this.form = {
						tipo_sesion: 'Sesion',
						fecha: e,
						hora: '',
						objetivo: 'Seguimiento',
						id_paciente_expediente: 0,
						id_acceso_terapeuta: 0,
						id_paciente_sesion: 0,
					}
					this.indexExpediente = -1
					this.horas = []
				} else {
					this.$swal({ icon: 'warning', text: 'No tienes saldo disponible para agendar un evento, por favor, contrata uno de nuestros paquetes para continuar' })
				}
			}
		},
		horasDisponibles(){
			console.log(this.indexExpediente)
			let id_acceso_terapeuta = this.expedientes[this.indexExpediente].id_acceso_terapeuta
			this.form.id_acceso_terapeuta = id_acceso_terapeuta
			this.form.id_paciente_expediente = this.expedientes[this.indexExpediente].id_paciente_expediente
			let loaderTerapeuta = this.$loading.show()
			axios
				.get(this.$backendUrl + '/configs/terapeuta?id=' + id_acceso_terapeuta, { headers: { token: localStorage.userToken } })
				.then(response => {
					this.terapeuta = response.data
					loaderTerapeuta.hide()
				}, error => {
					console.log(error.response.data.description)
					loaderTerapeuta.hide()
				})
			let loader = this.$loading.show()
			axios
				.get(this.$backendUrl + '/me/terapia/citas/disponibles?dia=' + this.form.fecha + '&id_acceso_terapeuta=' + id_acceso_terapeuta, { headers: { token: localStorage.userToken } })
				.then(response => {
					if (response.status == 200) {
						this.horas = response.data
						this.editando = true
						loader.hide()
					}
				},
				error => {
					loader.hide()
					this.$swal({ icon: 'error', text: error.response.data.description })
				})
		},
		submit() {
			if (this.form.hora != '') {
				let loader = this.$loading.show()
				if (this.form.id_paciente_sesion == 0) {
					let data = {
						inicio: moment(this.form.fecha + ' ' + this.form.hora).format('YYYY-MM-DDTHH:mm:00'),
						fin: moment(this.form.fecha + ' ' + this.form.hora).add(1, "hours").format('YYYY-MM-DDTHH:mm:00'),
						programada: 1,
						objetivo_sesion: this.form.objetivo,
						tipo_sesion: this.form.tipo_sesion,
						id_paciente_expediente: this.form.id_paciente_expediente,
						id_acceso_terapeuta: this.form.id_acceso_terapeuta,
						crear_meet: 1
					}
					if (this.form.tipo_sesion == 'Llamada') {
						data.fin = moment(this.form.fecha + ' ' + this.form.hora).add(30, "minutes").format('YYYY-MM-DDTHH:mm:00')
					}
					console.log(data)
					axios
						.post(this.$backendUrl + '/me/terapia/citas', data, { headers: { token: localStorage.userToken } })
						.then(response => {
							if (response.status == 200) {
								loader.hide()
								this.loadData()
								this.$swal({ icon: 'success', text: 'Datos guardados' })
							}
						},
							error => {
								loader.hide()
								this.$swal({ icon: 'error', text: error.response.data.description })
							})
				} else {
					axios
						.put(this.$backendUrl + '/me/terapia/citas', {
							inicio: moment(this.form.fecha + ' ' + this.form.hora).format('YYYY-MM-DDTHH:mm:00'),
							fin: moment(this.form.fecha + ' ' + this.form.hora).add(1, "hours").format('YYYY-MM-DDTHH:mm:00'),
							crear_meet: 0
						}, { headers: { token: localStorage.userToken } })
						.then(response => {
							if (response.status == 200) {
								loader.hide()
								this.loadData()
								this.$swal({ icon: 'success', text: 'Datos guardados' })
							}
						},
							error => {
								loader.hide()
								this.$swal({ icon: 'error', text: error.response.data.description })
							})
				}
			} else {
				this.$swal({ icon: 'error', text: 'No hay horarios disponibles para agendar' })
			}
		},
		cancelar() {
			this.editando = false
			this.terapeuta.id_acceso = 0
		},
	}
}
</script>

<style lang="scss">
.calendar-header__mode-picker {
	visibility: hidden !important;
}
.calendar-area {
	.calendar-month__weekday[data-v-cd3b00be] {
		height: 135px !important;
	}
}
.performance-date {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 2px 15px #00000005;
	border-radius: 30px;
	padding: 30px;
	margin-bottom: 24px;
}
.performance-date-list {
	padding-left: 30px;
	margin-bottom: 0;
	list-style-type: none;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 65px;
		left: 0;
		width: 1px;
		height: 79%;
		border-left: 1px dashed #CAD6F2;
	}
	li {
		display: inline-block;
		background: #FFFFFF 0% 0% no-repeat padding-box;
		box-shadow: 0px 5px 25px #CAD6F266;
		border: 1px solid #DFE9FF;
		border-radius: 20px;
		padding: 30px;
		position: relative;
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
		&::before {
			content: "";
			position: absolute;
			top: 50%;
			left: -38px;
			width: 14px;
			height: 14px;
			border-radius: 50px;
			background-color: #1765FD;
			transform: translateY(-50%);
		}
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			left: -43px;
			width: 24px;
			height: 24px;
			border-radius: 50px;
			border: 1px solid #EBF1FF;
			transform: translateY(-50%);
		}
		h3 {
			font-size: 14px;
			font-weight: 600;
			margin-bottom: 10px;
			span {
				font-size: 12px;
				font-weight: 500;
				color: #1765FD;
			}
		}
		p {
			font-size: 14px;
		}
		&:nth-child(2) {
			&::before {
				background-color: #4FCB8D;
			}
		}
		&:nth-child(3) {
			&::before {
				background-color: #5C31D6;
			}
		}
		&:nth-child(4) {
			&::before {
				background-color: #FE5957;
			}
		}
	}
}
.event-flyout__relative-wrapper {
	background-color: #555;
    color: #fff;
}
.event-flyout__info-wrapper .is-title {
	color: #bbb;
    font-size: 18px;
    font-weight: bold;
}
.calendar-month__event {
	background-image: linear-gradient(to bottom, #1788fd, #1788fd);
	width: auto !important;
	&.is-draggable[data-v-7bf10a44] {
		justify-content: center;
	}
	.calendar-month__event-color[data-v-7bf10a44] {
		display: none;
	}
	.calendar-month__event-title {
		color: var(--white-color);
		font-size: 14px;
	}
}
.calendar-month__event[data-v-7bf10a44] {
	display: inline-block !important;
	padding: 0 3px !important;
	--event-inline-padding: 0;
}
.calendar-month__event-time {
	color: #fff;
    font-weight: bolder;
}
.qalendar-is-small {
	.calendar-month {
		.calendar-month__week[data-v-25c5b5f2] {
			display: inherit !important;
		}
		.calendar-month__weekday[data-v-cd3b00be] {
			min-height: auto !important;
		}
	}
}
.calendar-header[data-v-6976f31b] {
    display: block;
}
.calendar-month__weekday[data-v-cd3b00be] {
	height: 73px !important;
}
.calendar-root-wrapper {
    height: auto !important;
    min-height: auto !important;
}
.calendar-wrap {
    background-color: var(--white-color);
    box-shadow: var(--box-shadow);
    padding: 20px;
    border-radius: var(--border-radius);
    &.style-two {
        border-radius: 30px;
        margin-bottom: 24px;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
	.calendar-area {
		.calendar-month__weekday[data-v-cd3b00be] {
			height: 73px !important;
		}
	}
    .performance-date {
        padding: 20px;
    }
    .performance-date-list {
        padding-left: 20px;
        &::before {
            height: 100%;
            top: 0;
        }
        li {
            padding: 15px;
            &::after {
                left: -32px;
            }
            &::before {
                left: -27px;
            }
        }
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .performance-date-list {
        &::before {
            height: 100%;
            top: 0;
        }
    }
}

/* Min width 1200px to Max width 1320px */
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .performance-date-list {
        &::before {
            height: 100%;
            top: 0;
        }
    }
}
</style>