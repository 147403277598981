<template>
    <div class="tabs-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="others-title">
                    <h3>Tabs</h3>
                </div>

                <ul class="nav nav-tabs mb-4" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Home</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Profile</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Contact</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" disabled>Disabled</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi delectus hic corporis possimus non voluptatibus dolore voluptate, numquam quasi nisi cum enim doloremque amet mollitia animi nostrum praesentium est reiciendis.</div>
                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi delectus hic corporis possimus non voluptatibus dolore voluptate, numquam quasi nisi cum enim doloremque amet mollitia animi nostrum praesentium est reiciendis.</div>
                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi delectus hic corporis possimus non voluptatibus dolore voluptate, numquam quasi nisi cum enim doloremque amet mollitia animi nostrum praesentium est reiciendis.</div>
                    <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi delectus hic corporis possimus non voluptatibus dolore voluptate, numquam quasi nisi cum enim doloremque amet mollitia animi nostrum praesentium est reiciendis.</div>
                </div>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Tabs Left Menu</h3>
                </div>

                <div class="d-flex align-items-start">
                    <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</button>
                        <button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
                        <button class="nav-link" id="v-pills-disabled-tab" data-bs-toggle="pill" data-bs-target="#v-pills-disabled" type="button" role="tab" aria-controls="v-pills-disabled" aria-selected="false" disabled>Disabled</button>
                        <button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</button>
                        <button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button>
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabindex="0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum suscipit quasi saepe incidunt debitis beatae nemo sint ratione voluptate consequatur qui magni eum, magnam voluptates consectetur aliquid, eius inventore cumque? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores totam nam quia impedit, quaerat deserunt officia, vel ullam explicabo ipsa dolor? Deleniti laudantium est facere voluptates? Eaque adipisci iste vel. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nobis, quibusdam voluptatum. Repudiandae, neque dolores vel quos consequuntur minus, voluptatem suscipit adipisci quae inventore asperiores autem saepe perferendis facere fugiat officiis? Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse suscipit, incidunt impedit ea natus explicabo nostrum amet assumenda dignissimos accusamus voluptatem harum, praesentium fugiat sunt rerum, eum exercitationem inventore nam.</div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum suscipit quasi saepe incidunt debitis beatae nemo sint ratione voluptate consequatur qui magni eum, magnam voluptates consectetur aliquid, eius inventore cumque?</div>
                        <div class="tab-pane fade" id="v-pills-disabled" role="tabpanel" aria-labelledby="v-pills-disabled-tab" tabindex="0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum suscipit quasi saepe incidunt debitis beatae nemo sint ratione voluptate consequatur qui magni eum, magnam voluptates consectetur aliquid, eius inventore cumque?</div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabindex="0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum suscipit quasi saepe incidunt debitis beatae nemo sint ratione voluptate consequatur qui magni eum, magnam voluptates consectetur aliquid, eius inventore cumque?</div>
                        <div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabindex="0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum suscipit quasi saepe incidunt debitis beatae nemo sint ratione voluptate consequatur qui magni eum, magnam voluptates consectetur aliquid, eius inventore cumque?</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TabsContent'
}
</script>