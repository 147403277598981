<template>
    <div class="all-section-area">
        <MainHeader />
        <main class="main-content-wrap">
            <PageTitle mainTitle="Administrar Cursos" pageTitle="Administrar Cursos" />
            <AdminCursos />
            <FooterArea />
        </main>
    </div>
</template>

<script>
import MainHeader from '../Layouts/MainHeader'
import PageTitle from '../Common/PageTitle'
import AdminCursos from '../Admin/Cursos'
import FooterArea from '../Layouts/FooterArea'

export default {
    name: 'AdminCursosPage',
    components: {
        MainHeader,
        PageTitle,
        AdminCursos,
        FooterArea,
    },
}
</script>