<template>
    <div class="profile-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="edit-profile-content card-box-style" v-if="editandoEntrevista">
                        <h3>Entrevista inicial de - {{ form.sobrenombre }}</h3>
                        <h6>Ya casi terminamos, sólo unas preguntas más.</h6>
                        <h6>Responde tranquilamente, de la forma más sincera que puedas y sin pensar si existen respuestas adecuadas.</h6>
                        <form @submit.prevent="submit" @submit="guardaEntrevista">
                            <div class="row">
                                <div class="col-lg-4">
                                    <input v-model="entrevista.id_paciente_expediente" type="hidden" class="form-control" >
                                    <div class="form-group">
                                        <label>Historia familiar *</label>
                                        <textarea v-model="entrevista.historia_familiar" type="text" class="form-control"
                                            required placeholder="Tienes herman@s, más grandes, más chicos, tus padres aún viven y a que se dedican..."></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Motivo consulta *</label>
                                        <textarea v-model="entrevista.motivo_consulta" type="text" class="form-control" 
                                            required placeholder="Que te gustaría trabajar en las sesiones..."></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Integrantes de la familia</label>
                                        <textarea v-model="entrevista.integrantes_familia" type="text" class="form-control"
                                            placeholder="Nombra a los integrantes de tu familia nuclear y sus edades..."></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Síntomas *</label>
                                        <textarea v-model="entrevista.sintomas" type="text" class="form-control"
                                            required placeholder="Que sentías física o emocionalmente, que motivo buscarnos..."></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Observaciones</label>
                                        <textarea v-model="entrevista.observaciones" type="text" class="form-control"
                                            placeholder="Algo más que quieras decirnos..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="save-update">
                                <button class="default-btn"><span>Guardar</span></button>
                                <button type="submit" class="btn btn-danger mb-1 me-1" @click="cancelaEntrevista()">Cancelar</button>
                            </div>
                        </form>
                    </div>
                    
                    <div class="edit-profile-content card-box-style" v-if="editandoExpediente">
                        <h3>Información general</h3>
                        <form @submit.prevent="submit" @submit="guardaExpediente">
                            <div class="row">
                                <div class="col-lg-4">
                                    <input v-model="form.id_paciente_expediente" type="hidden" class="form-control" >
                                    <div class="form-group">
                                        <label>Nombre *</label>
                                        <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre" required>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Paterno *</label>
                                        <input v-model="form.paterno" type="text" class="form-control" placeholder="Paterno" required>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Materno *</label>
                                        <input v-model="form.materno" type="text" class="form-control" placeholder="Materno" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Género *</label>
                                        <select v-model="form.genero" class="form-control form-select" tabindex="-1" aria-hidden="true">
                                            <option value="Femenino">Femenino</option> 
                                            <option value="Masculino">Masculino</option> 
                                            <option value="No Binario">No Binario</option> 
                                            <option value="Prefiero no responder">Prefiero no responder</option> 
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Fecha de nacimiento *</label>
                                        <VueDatePicker v-model="form.nacimiento" class="form-control" 
                                        model-type="yyyy-MM-dd" auto-apply
                                        placeholder="Fecha de nacimiento"
                                        no-today
                                        required
                                        :enable-time-picker="false"></VueDatePicker>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Teléfono *</label>
                                        <input v-model="form.telefono" type="text" class="form-control" placeholder="Teléfono" required>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Sobrenombre *</label>
                                        <input v-model="form.sobrenombre" type="text" class="form-control" placeholder="Sobrenombre" required>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Estado civil *</label>
                                        <select v-model="form.estado_civil" class="form-control form-select" tabindex="-1" aria-hidden="true" required>
                                            <option value="Solter@">Solter@</option>
                                            <option value="Casad@">Casad@</option>
                                            <option value="Unión Libre">Unión Libre</option>
                                            <option value="Viud@">Viud@</option>
                                            <option value="Divorciad@">Divorciad@</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Escolaridad *</label>
                                        <select v-model="form.escolaridad" class="form-control form-select" tabindex="-1" aria-hidden="true" required>
                                            <option value="Básica">Básica</option>
                                            <option value="Bachillerato">Bachillerato</option>
                                            <option value="Superior">Superior</option>
                                            <option value="Postgrado">Postgrado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="form.id_paciente_expediente > 0">
                                <div class="col-lg-4">
                                    <input v-model="form.id_paciente_expediente" type="hidden" class="form-control" >
                                    <div class="form-group">
                                        <label>Terapeuta recomendado</label>
                                        <select v-model="form.id_acceso_terapeuta" class="form-control form-select" tabindex="-1" aria-hidden="true" required>
                                            <option v-for="(terapeuta, index) in terapeutas" :key="index" v-bind:value="terapeuta.id_acceso">{{terapeuta.nombre}} {{terapeuta.paterno}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Ocupación</label>
                                        <input v-model="form.ocupacion" type="text" class="form-control" placeholder="Ocupación" >
                                    </div>
                                </div>
                                <div class="col-lg-8">
                                    <div class="form-group">
                                        <label>Domicilio</label>
                                        <input v-model="form.domicilio" type="text" class="form-control" placeholder="Domicilio" >
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Comentarios</label>
                                        <textarea v-model="form.comentario" type="text" class="form-control" placeholder="Comentarios"
                                            ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="save-update">
                                <button type="submit" class="default-btn"><span v-if="form.id_paciente_expediente > 0">Actualizar</span><span v-else>Guardar</span></button>
                                <button type="submit" class="btn btn-danger mb-1 me-1" @click="cancelaExpediente()">Cancelar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" v-if="editandoEntrevista">
        </div>
        <div class="container-fluid" v-else-if="editandoExpediente">
        </div>
        <div class="container-fluid" v-else>
            <div class="row">
                <div class="col-sm-12">
                    <div class="edit-profile-content card-box-style">
                        <div class="row" v-if="expedientes.length == 0">
                            <h6 style="color: #dc3545;">Antes de que puedas agendar sesiones, necesitamos saber un poco más de ti</h6>
                        </div>
                        <div class="row" v-else>
                            <h3>Expedientes disponibles</h3>
                            <div class="col-sm-4" style="border: 1px solid; border-radius: 5px; padding-top: 7px; padding-left: 15px;" v-for="(expediente, index) in expedientes" :key="index">
                                <strong>{{ expediente.sobrenombre }} - {{ calculateAge(expediente.nacimiento) }} años</strong><button type="button" @click="editar(index)" class="btn btn-link mb-1 me-1">Editar</button>
                                <button type="button" @click="entrevistar(expediente)" class="btn btn-link mb-1 me-1" v-if="expediente.historia_familiar == null">Llenar entrevista</button>
                                <span style="font-size: 10px;" v-if="expediente.historia_familiar != null">Entrevista inical capturada</span>
                            </div>
                        </div>
                        <br>
                        <button v-if="expedientes.length > 0" type="button" @click="agregar()" class="btn btn-success mb-1 me-1" >Agregar el expediente de un menor</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios'
import moment from 'moment'

export default {
    name: 'PatientProfileContent',
    components: { VueDatePicker },
    data() {
        return {
            entrevista: {
                id_paciente_expediente: 0,
                historia_familiar: '',
                motivo_consulta: '',
                integrantes_familia: '',
                objetivos_terapia: '',
                observaciones: '',
                sintomas: '',
            },
            form: {
                id_paciente_expediente: 0,
                paterno: '',
                materno: '',
                nombre: '',
                genero: '',
                nacimiento: '',
                telefono: '',
                sobrenombre: '',
                estado_civil: '',
                escolaridad: '',
                ocupacion: '',
                domicilio: '',
                comentario: '',
            },
            terapeutas: [],
            expedientes: [],
            terapeutas_default: {
                ninos: 0,
                adultos: 0
            },
            editandoExpediente: false,
            editandoEntrevista: false,
        }
    },
    mounted() {
        if (this.$route.query.editando == 'expediente') {
            this.editandoExpediente = true
            let userData = JSON.parse(localStorage.user)
            if (typeof userData.general !== 'undefined') {
                this.form.nombre = userData.general.nombre
                this.form.paterno = userData.general.paterno
                this.form.materno = userData.general.materno
                this.form.sobrenombre = userData.general.nombre
                this.form.genero = userData.general.genero
                this.form.nacimiento = moment(userData.general.nacimiento).format('YYYY-MM-DD')
                this.form.telefono = userData.general.telefono
            }
        }
        if (this.$route.query.editando == 'entrevista') {
            this.editandoEntrevista = true
        }
        let loader = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/info/expediente', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.expedientes = response.data
                loader.hide()
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
        axios
            .get(this.$backendUrl + '/configs/terapeutas', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.terapeutas = response.data
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
        axios
            .get(this.$backendUrl + '/configs?ruta=' + '/terapias/defaults/terapeuta', { headers: { token: localStorage.userToken } })
            .then(response => {
                response.data.forEach(terapeuta => {
                    if (terapeuta.ruta == '/terapias/defaults/terapeuta/ninos') {
                        this.terapeutas_default.ninos = terapeuta.valor
                    } else {
                        this.terapeutas_default.adultos = terapeuta.valor
                    }
                })
            }, error => {
                console.log(error.response.data.description)
                loader.hide()
            })
    },
    methods: {
        guardaExpediente() {
            let loader = this.$loading.show({ canCancel: false })
            if (this.form.id_paciente_expediente > 0) {
                axios
                    .put(this.$backendUrl + '/me/info/expediente', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            axios
                                .get(this.$backendUrl + '/me/info/expediente', { headers: { token: localStorage.userToken } })
                                .then(response => {
                                    this.expedientes = response.data
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Datos guardados' })
                                    this.editandoExpediente = false
                                })
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            } else {
                if (this.calculateAge(this.form.nacimiento) < 18) {
                    this.form.id_acceso_terapeuta = this.terapeutas_default.ninos
                } else {
                    this.form.id_acceso_terapeuta = this.terapeutas_default.adultos
                }
                delete this.form.id_paciente_expediente
                axios
                    .post(this.$backendUrl + '/me/info/expediente', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            this.editandoEntrevista = true
                            this.entrevista = {
                                id_paciente_expediente: response.data.id_paciente_expediente,
                                historia_familiar: '',
                                motivo_consulta: '',
                                integrantes_familia: '',
                                objetivos_terapia: '',
                                observaciones: '',
                                sintomas: '',
                            }
                            if (response.data.completar_usuario) {
                                axios
                                    .post(this.$backendUrl + '/me/info/general', this.form, { headers: { token: localStorage.userToken } })
                                    .then(response => {
                                        if (response.status == 200) {
                                            let userData = JSON.parse(localStorage.user)
                                            userData.general = this.form
                                            localStorage.user = JSON.stringify(userData)
                                        }
                                    })
                            }
                            axios
                                .get(this.$backendUrl + '/me/info/expediente', { headers: { token: localStorage.userToken } })
                                .then(response => {
                                    this.expedientes = response.data
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Datos guardados' })
                                    this.editandoExpediente = false
                                })
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            }
        },
        guardaEntrevista() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/me/info/expediente/entrevista', this.entrevista, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        this.$swal({ icon: 'success', text: 'Datos guardados' })
                        this.editandoExpediente = false
                        this.editandoEntrevista = false
                        axios
                            .get(this.$backendUrl + '/me/info/expediente', { headers: { token: localStorage.userToken } })
                            .then(response => {
                                this.expedientes = response.data
                                loader.hide()
                            }, error => {
                                console.log(error.response.data.description)
                                loader.hide()
                            })
                    }
                },
                error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        },
        calculateAge(nacimiento) {
            const currentDate = new Date();
            const diffTime = currentDate - new Date(nacimiento);
            const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return Math.floor(totalDays / 365.25);
        },
        editar(index) {
            //console.log(id_paciente_expediente)
            this.form = this.expedientes[index]
            this.form.nacimiento = moment(this.expedientes[index].nacimiento).format('YYYY-MM-DD')
            this.editandoExpediente = true
        },
        agregar() {
            this.form = JSON.parse(localStorage.user).general
            delete this.form.bio
            delete this.form.id_acceso
            this.form.nacimiento = moment(this.form.nacimiento).format('YYYY-MM-DD')
            this.form.id_paciente_expediente = 0
            this.editandoExpediente = true
        },
        entrevistar(expediente) {
            this.editandoEntrevista = true
            this.form = expediente
            this.entrevista = {
                id_paciente_expediente: expediente.id_paciente_expediente,
                historia_familiar: '',
                motivo_consulta: '',
                integrantes_familia: '',
                objetivos_terapia: '',
                observaciones: '',
                sintomas: '',
            }
        },
        cancelaExpediente() {
            this.editandoExpediente = false
        },
        cancelaEntrevista() {
            this.editandoEntrevista = false
        }
    },
}
</script>

<style lang="scss">
.dp__input_reg {
    height: 40px;
    color: var(--heading-color);
    border: 1px solid #F1F5FF;
    background-color: #F1F5FF;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    transition: var(--transition);
    margin-top: -20px;
    margin-left: 20px;
}

.edit-profile-content {
    h3 {
        font-size: 18px;
        margin-bottom: 30px;
    }

    label {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .form-group {
        margin-bottom: 24px;

        .form-select {
            cursor: pointer;

        }

        .form-select[aria-selected] {
            background-color: red;
            color: white;
        }
    }
}</style>