<template>
    <div class="contact-list-area">
        <div class="card-box-style" v-if="edicion == true">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <input v-model="form.id_configuracion" type="hidden" class="form-control" >
                        <div class="form-group">
                            <label>Ruta</label>
                            <input v-model="form.ruta" type="text" class="form-control" placeholder="Ruta"
                                required>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <div class="form-group">
                            <label>Valor</label>
                            <input v-model="form.valor" type="text" class="form-control" placeholder="Valor"
                                required>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="save-update">
                        <button type="submit" class="btn btn-success mb-1 me-1"><span v-if="form.id_configuracion == 0">Agregar</span><span v-else>Actualizar</span></button>
                        <button type="button" class="btn btn-danger mb-1 me-1" @click="cancelar" >Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="container-fluid">
            <div class="table-responsive scroll-bar">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-5">
                                <input v-model="buscar" type="text" class="form-control" placeholder="Nombre del usuario">
                            </div>
                            <div class="col-sm-2">
                                <a  @click="cargarGrid()" style="color:blue; font-size:24px;">
                                    <i class="ri-search-line"></i>&nbsp;
                                </a>
                                <a  @click="resetGrid" style="color:blue; font-size:24px;">
                                    <i class="ri-refresh-fill"></i>&nbsp;
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <a class="nav-link" @click="agregar" style="color:green;">
                            <i class="ri-add-box-line"></i>&nbsp;Agregar Configuración
                        </a>
                    </div>
                </div><hr/>
                
                <table-lite
                    :is-slot-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    :sortable="table.sortable"
                    :messages="table.messages"
                    :has-checkbox="true"
                    @do-search="doSearch"
                    @is-finished="table.isLoading = false"
                >
                    <template v-slot:nacimiento="usuario">
                        <slot>{{ calculateAge(usuario.value.nacimiento) }}</slot>
                    </template>
                    <template v-slot:status="usuario">
                        <slot><span v-if="usuario.value.status == 1" style="color: green; cursor:grab;">Activo</span></slot>
                        <slot><span v-if="usuario.value.status != 1" style="color: red; cursor:grab;">Inactivo</span></slot>
                    </template>
                    <template v-slot:rol_code="usuario">
                        <slot><span v-if="usuario.value.rol_code == 'user'" style="color: blue; cursor:grab;">Usuario</span></slot>
                        <slot><span v-if="usuario.value.rol_code == 'root'" style="color: green; cursor:grab;">Administrador</span></slot>
                        <slot><span v-if="usuario.value.rol_code == 'tera'" style="color: blue; cursor:grab;">Terapeuta</span></slot>
                    </template>
                    <template v-slot:telefono="usuario">
                        <slot><a v-if="usuario.value.telefono !== ''" :href="phoneLink(usuario.value.telefono)" target="_blank" style="color: blue; cursor:grab;"><i class="ri-phone-line"></i>Llamar</a></slot>&nbsp;
                        <slot><a v-if="usuario.value.telefono !== ''" :href="whatsAppLink(usuario.value.telefono)" target="_blank" style="color: green; cursor:grab;"><i class="ri-whatsapp-line"></i>Escribir</a></slot>
                    </template>
                    <template v-slot:edit="usuario">
                        <slot><a @click="editar(usuario.value)" style="color: blue; cursor:grab;"><i class="ri-edit-fill"></i>&nbsp;</a></slot>
                    </template>
                </table-lite>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import TableLite from "vue3-table-lite"
import { reactive } from 'vue'

export default {
    name: 'AdminConfig',
    components: {
        TableLite
    },
    data() {
        return {
            buscar: '',
            edicion: false,
            form: {
                id_configuracion: 0,
                ruta: "",
                valor: "",
            },
        }
    },
    mounted() {
        this.doSearch(this.$backendUrl, this.buscar)
    },
    methods: {
        calculateAge(nacimiento) {
            const currentDate = new Date();
            const diffTime = currentDate - new Date(nacimiento);
            const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
            return Math.floor(totalDays / 365.25);
        },
        phoneLink(link) {
            var urlLink = "tel:" + link
            return urlLink
        },
        whatsAppLink(link) {
            var urlLink = "https://wa.me/52" + link
            return urlLink
        },
        editar(data) {
            console.log(data)
            this.form = data
            this.edicion = true
            window.scrollTo(0, 0)
        },
        cancelar() {
            this.form = {}
            this.edicion = false
        },
        agregar() {
            this.form = {}
            this.edicion = true
            window.scrollTo(0, 0)
        },
        cargarGrid() {
            this.doSearch(this.$backendUrl, this.buscar)
        },
        resetGrid() {
            this.buscar = ''
            this.terapeutaAsignado = 0
            this.doSearch(this.$backendUrl, '')
        },
        submit() {
            let loader = this.$loading.show({ canCancel: false })
            axios
                .post(this.$backendUrl + '/admin/config', this.form, { headers: { token: localStorage.userToken } })
                .then(response => {
                    if (response.status == 200) {
                        loader.hide()
                        this.$swal({ icon: 'success', text: 'Datos guardados' })
                        this.edicion = false
                        this.doSearch(this.$backendUrl, '')
                    }
                },
                error => {
                    loader.hide()
                    this.$swal({ icon: 'error', text: error.response.data.description })
                })
        },
    },
    setup() {
        // Init Your table settings
        const table = reactive({
            columns: [
                {
                    label: "ID",
                    field: "id_configuracion",
                    width: "3%",
                    sortable: false,
                    isKey: true,
                },
                {
                    label: "Ruta",
                    field: "ruta",
                    width: "30%",
                    sortable: false,
                },
                {
                    label: "",
                    field: "edit",
                    width: "10%",
                    
                },
            ],
            rows: [],
            totalRecordCount: 0,
            sortable: {
                order: "id",
                sort: "asc",
            },
        })

        const doSearch = (backendUrl, searchText, offset, limit, order, sort) => {
            table.isLoading = true
            let url = backendUrl + '/admin/configs'
            url = url + '?ruta=' + searchText
            url = url + '&offset=' + offset + '&limit=' + limit
            axios
                .get(url, { headers: { token: localStorage.userToken } })
                .then(response => {
                    table.rows = response.data.data
                    table.totalRecordCount = response.data.totalRows
                    table.sortable.order = order
                    table.sortable.sort = sort
                    table.isLoading = false
                }, error => {
                    console.log(error.response.data.description)
                })
        }

        return {
            table,
            doSearch,
        }
    },
}

</script>

<style lang="scss">
.dp__input_reg {
    height: 40px;
    color: var(--heading-color);
    border: 1px solid #F1F5FF;
    background-color: #F1F5FF;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    transition: var(--transition);
    margin-top: -20px;
    margin-left: 20px;
}
.contact-list-area {
    margin-bottom: 24px;

    .table-responsive {
        background-color: var(--white-color);
        box-shadow: var(--box-shadow);
        padding: 30px;
        border-radius: var(--border-radius);
        height: 595px;

        &.scroll-bar {
            overflow-x: scroll !important;

            &::-webkit-scrollbar {
                height: 4px;
            }
        }
    }

    .table {
        tbody {
            tr {
                border-bottom: 1px solid #EBF1FF;
                border-radius: 10px;
                transition: var(--transition);

                &:hover {
                    background-color: #F8FAFF;
                    border-color: var(--white-color);
                }

                td {
                    padding: 20px 20px;
                    border-radius: 10px;

                    .form-check {
                        margin-bottom: 0;

                        .form-check-input {
                            &:focus {
                                box-shadow: none;
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 12px;
                            color: #67748E;
                        }

                        img {
                            width: 50px;
                        }

                        .border {
                            border: 3px solid var(--white-color) !important;
                            box-shadow: var(--box-shadow);
                        }
                    }

                    a {
                        font-size: 16px;
                        color: #67748E;
                    }

                    .location {
                        color: #67748E;
                        font-size: 16px;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        float: right;

                        li {
                            display: inline-block;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-scrollbar:before {
        background-color: var(--main-color);
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 30px;
        top: -20px;
        cursor: pointer;
    }

    .form-check .form-check-input {
        border-color: #1765fd;
        background-color: rgba(23, 101, 253, 0.1);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=checkbox] {
        background-color: #1765fd;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;

                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}
</style>