<template>
    <div class="contact-list-area">
        <div class="card-box-style" v-if="edicion == true">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Tipo</label>
                            <select v-model="form.tipo" class="form-control form-select" tabindex="1" aria-hidden="true" required>
                                <option value="pacientes">Pacientes</option> 
                            </select>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Precio</label>
                            <input v-model="form.precio" type="text" class="form-control" placeholder="Precio" tabindex="4" required>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Moneda</label>
                            <select v-model="form.moneda" class="form-control form-select" tabindex="5" aria-hidden="true" required>
                                <option value="MXN">MXN</option> 
                                <option value="USD">USD</option> 
                            </select>
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-4" v-if="form.tipo == 'pacientes'">
                        <div class="form-group">
                            <label>Sesiones incluidas</label>
                            <input v-model="form.sesiones" type="text" class="form-control" placeholder="Sesiones incluidas" tabindex="6" required/>
                        </div>
                    </div>

                    <div class="col-md-4" v-if="form.tipo=='pacientes'">
                        <div class="form-group">
                            <label>Llamadas incluidas</label>
                            <input v-model="form.llamadas" type="text" class="form-control" placeholder="Llamadas incluidas" tabindex="6" required/>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group">
                            <label>Recurrencia</label>
                            <select v-model="form.recurrencia" class="form-control form-select" tabindex="5" aria-hidden="true" required>
                                <option value="unica">Única</option> 
                                <option value="mensual">Mensual</option> 
                                <option value="anual">Anual</option> 
                            </select>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Nombre</label>
                            <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre" tabindex="2" required>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Descripción</label>
                            <textarea v-model="form.descripcion" type="text" class="form-control" placeholder="Descripción" tabindex="3" required></textarea>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="form-check">
                            <input v-model="form.activo" class="form-check-input" type="checkbox" id="flexCheckDefault">
                            <label class="form-check-label" for="flexCheckDefault">
                                <span>Plan Activo</span>
                            </label>
                        </div>
                    </div>

                    <div class="save-update">
                        <button type="submit" class="btn btn-success mb-1 me-1"><span v-if="form.id_plan_pago == 0">Agregar</span><span v-else>Actualizar</span></button>
                        <button type="button" class="btn btn-danger mb-1 me-1" @click="cancelar" >Cancelar</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="container-fluid">
            <div class="table-responsive scroll-bar">
                <div class="row">
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-10">
                                <input v-model="buscar" type="text" class="form-control" placeholder="Bucar">
                            </div>
                            <div class="col-sm-2">
                                <a  @click="cargarGrid(buscar)" style="color:blue; font-size:24px;">
                                    <i class="ri-search-line"></i>&nbsp;
                                </a>
                                <a  @click="resetGrid" style="color:blue; font-size:24px;">
                                    <i class="ri-refresh-fill"></i>&nbsp;
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <a class="nav-link" @click="agregar" style="color:green;">
                            <i class="ri-add-box-line"></i>&nbsp;Agregar Plan
                        </a>
                    </div>
                </div><hr/>
                
                <table-lite
                    :is-slot-mode="true"
                    :is-loading="table.isLoading"
                    :columns="table.columns"
                    :rows="table.rows"
                    :total="table.totalRecordCount"
                    :sortable="table.sortable"
                    :messages="table.messages"
                    :has-checkbox="true"
                    @do-search="doSearch"
                    @is-finished="table.isLoading = false"
                >
                    <template v-slot:activo="plan">
                        <slot><span v-if="plan.value.activo == 0" style="color:red">Inactivo</span><span v-else>Activo</span></slot>
                    </template>
                    <template v-slot:stripe_payment_link="plan">
                        <slot><a v-if="plan.value.stripe_payment_link !== ''" @click="copyTextNoInput(plan.value.stripe_payment_link)" style="color: green; cursor:grab; font-size:18px"><i class="ri-link-m"></i><span style="font-size:12px;">Copiar</span></a></slot>&nbsp;
                        <slot><a v-if="plan.value.stripe_payment_link !== ''" :href="whatsAppLink(plan.value.stripe_payment_link)" target="_blank" style="color: green; cursor:grab; font-size:18px"><i class="ri-whatsapp-line"></i><span style="font-size:12px;">Enviar</span></a></slot>
                    </template>
                    <template v-slot:edit="plan">
                        <slot><a @click="editar(plan.value)" style="color: blue; cursor:grab;"><i class="ri-edit-fill"></i>&nbsp;</a></slot>
                        <slot><a @click="borrar(plan.value)" style="color: red; cursor:grab"><i class="ri-delete-bin-fill"></i>&nbsp;</a></slot>
                    </template>
                </table-lite>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import TableLite from "vue3-table-lite"
import { reactive } from 'vue'

export default {
    name: 'AdminProductos',
    components: {
        TableLite
    },
    data() {
        return {
            buscar: '',
            edicion: false,
            form: {
                id_plan_pago: 0,
                nombre: '',
                precio: 0,
                moneda: '',
                recurrencia: '',
                activo: 0,
                stripe_product_id: '',
                stripe_price_id: ''
            }
        }
    },
    mounted() {
        this.doSearch(this.$backendUrl, '', 0)
    },
    watch: {
        'form.activo'(newVal, oldVal) {
            console.log(oldVal)
            if (newVal == 1) {
                this.form.activo = true
            }
            if (newVal == 0) {
                this.form.activo = false
            }
        }
    },
    methods: {
        whatsAppLink(link) {
            var urlLink = "https://api.whatsapp.com/send/?text=Hola%2C+esta+es+la+liga+de+pago+" + encodeURIComponent(link) + "&type=phone_number&app_absent=0"
            console.log(urlLink)
            return urlLink
        },
        copyTextNoInput(data) {
            const storage = document.createElement('textarea')
            storage.value = data
            document.body.appendChild(storage)
            storage.select()
            storage.setSelectionRange(0, 99999)
            document.execCommand('copy')
            document.body.removeChild(storage)
        },
        editar(data) {
            this.form = data
            this.edicion = true
            window.scrollTo(0, 0)
        },
        cancelar() {
            this.form = {}
            this.edicion = false
        },
        agregar() {
            this.form = {}
            this.form.id_plan_pago = 0
            this.form.tipo = 'pacientes'
            this.form.moneda = 'MXN'
            this.form.recurrencia = 'unica'
            this.form.activo = true
            this.edicion = true
            window.scrollTo(0, 0)
        },
        cargarGrid() {
            this.doSearch(this.$backendUrl, this.buscar)
        },
        resetGrid() {
            this.buscar = ''
            this.doSearch(this.$backendUrl, '')
        },
        submit() {
            let loader = this.$loading.show({ canCancel: false })
            if (this.form.activo) {
                this.form.activo = 1
            } else {
                this.form.activo = 0
            }
            if (this.form.id_plan_pago == 0) {
                axios
                    .post(this.$backendUrl + '/admin/planes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.resetGrid()
                            this.edicion = false
                            this.form = {}
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            } else {
                axios
                    .put(this.$backendUrl + '/admin/planes', this.form, { headers: { token: localStorage.userToken } })
                    .then(response => {
                        if (response.status == 200) {
                            loader.hide()
                            this.$swal({ icon: 'success', text: 'Datos guardados' })
                            this.resetGrid()
                            this.edicion = false
                            this.form = {}
                        }
                    },
                    error => {
                        loader.hide()
                        this.$swal({ icon: 'error', text: error.response.data.description })
                    })
            }
        },
        borrar(data) {
            data.activo = 0
            this.$swal({
                title: "Desactivar?",
                text: "Desactivar este plan de pago?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#ff0000",
                confirmButtonText: "Si"
            }).then((result) => {
                if (result.value) {
                    let loader = this.$loading.show({ canCancel: false })
                    this.form = data
                    axios
                        .put(this.$backendUrl + '/admin/planes', this.form, { headers: { token: localStorage.userToken } })
                        .then(response => {
                            if (response.status == 200) {
                                loader.hide()
                                this.$swal({ icon: 'success', text: 'Datos guardados' })
                                this.resetGrid()
                                this.edicion = false
                                this.form = {}
                            }
                        },
                        error => {
                            loader.hide()
                            this.$swal({ icon: 'error', text: error.response.data.description })
                        })
                }
            });
        }
    },
    setup() {
        // Init Your table settings
        const table = reactive({
            columns: [
                {
                    label: "ID",
                    field: "id_plan_pago",
                    width: "3%",
                    sortable: false,
                    isKey: true,
                },
                {
                    label: "Nombre",
                    field: "nombre",
                    width: "10%",
                    sortable: false,
                },
                {
                    label: "Precio",
                    field: "precio",
                    width: "5%",
                    sortable: false,
                },
                {
                    label: "Moneda",
                    field: "moneda",
                    width: "5%",
                    sortable: false,
                },
                {
                    label: "Activo",
                    field: "activo",
                    width: "10%",

                },
                {
                    label: "Link",
                    field: "stripe_payment_link",
                    width: "10%",

                },
                {
                    label: "",
                    field: "edit",
                    width: "10%",
                    
                },
            ],
            rows: [],
            totalRecordCount: 0,
            sortable: {
                order: "id",
                sort: "asc",
            },
        })

        const doSearch = (backendUrl, searchText, offset, limit, order, sort) => {
            table.isLoading = true
            let url = backendUrl + '/admin/planes?tipo=all'
            if (searchText != '') {
                url = url + '&cadena=' + searchText
            }
            axios
                .get(url, { headers: { token: localStorage.userToken } })
                .then(response => {
                    table.rows = response.data
                    table.totalRecordCount = response.data.length
                    table.sortable.order = order
                    table.sortable.sort = sort
                    table.isLoading = false
                }, error => {
                    console.log(error.response.data.description)
                })
        }

        //doSearch(this.$backendUrl, '', 0, 10, 'id', 'asc')

        return {
            table,
            doSearch,
        }
    },
}

</script>

<style lang="scss">
.contact-list-area {
    margin-bottom: 24px;

    .table-responsive {
        background-color: var(--white-color);
        box-shadow: var(--box-shadow);
        padding: 30px;
        border-radius: var(--border-radius);
        height: 595px;

        &.scroll-bar {
            overflow-x: scroll !important;

            &::-webkit-scrollbar {
                height: 4px;
            }
        }
    }

    .table {
        tbody {
            tr {
                border-bottom: 1px solid #EBF1FF;
                border-radius: 10px;
                transition: var(--transition);

                &:hover {
                    background-color: #F8FAFF;
                    border-color: var(--white-color);
                }

                td {
                    padding: 20px 20px;
                    border-radius: 10px;

                    .form-check {
                        margin-bottom: 0;

                        .form-check-input {
                            &:focus {
                                box-shadow: none;
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 12px;
                            color: #67748E;
                        }

                        img {
                            width: 50px;
                        }

                        .border {
                            border: 3px solid var(--white-color) !important;
                            box-shadow: var(--box-shadow);
                        }
                    }

                    a {
                        font-size: 16px;
                        color: #67748E;
                    }

                    .location {
                        color: #67748E;
                        font-size: 16px;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        float: right;

                        li {
                            display: inline-block;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-scrollbar:before {
        background-color: var(--main-color);
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 30px;
        top: -20px;
        cursor: pointer;
    }

    .form-check .form-check-input {
        border-color: #1765fd;
        background-color: rgba(23, 101, 253, 0.1);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=checkbox] {
        background-color: #1765fd;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;

                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}</style>