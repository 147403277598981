<template>
    <div id="chart">
        <apexchart type="donut" width="550" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
    name: 'SimpleDonutChart',
    data: function() {
        return {
            series: [44, 55, 41, 17, 15],
            chartOptions: {
                chart: {
                    type: 'donut',
                },
                labels: ['UK', 'USA', 'Canada', 'Australia', 'Italy'],
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        };
    },
}
</script>