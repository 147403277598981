<template>
    <div class="contact-list-area">
        <div class="card-box-style">
            <div class="row">
                <div class="col-md-7">
                    <div class="table-responsive scroll-bar" v-if="edicion == true">
                        <div class="row">
                            <div class="col-sm-12">
                                <button type="button" class="btn btn-success mb-1 me-1" @click="guardarElemento()" >Guardar</button>
                                <button type="button" class="btn btn-danger mb-1 me-1" @click="cancelarEdicion()" >Cancelar</button>
                            </div>
                            <input v-model="form.id" type="hidden">
                            <input v-model="form.id_padre" type="hidden">
                            <input v-model="tipo" type="hidden">
                            <div class="form-group">
                                <label>Nombre</label>
                                <input v-model="form.nombre" type="text" class="form-control" placeholder="Nombre" required>
                            </div>
                        </div>
                        <div class="row" v-if="tipo == 'curso'">
                            <div class="form-group">
                                <label>Fecha de publicación</label>
                                <VueDatePicker v-model="form.vigencia_desde" class="form-control" 
                                model-type="yyyy-MM-dd" auto-apply
                                placeholder="Fecha de publicación"
                                no-today
                                required
                                :enable-time-picker="false"></VueDatePicker>
                            </div>
                        </div>
                        <div class="row" v-if="tipo == 'curso'">
                            <div class="form-group">
                                <label>Texto de venta</label>
                                <textarea v-model="form.texto_corto" type="text" class="form-control" placeholder="Descripción corta"></textarea>
                            </div>
                        </div>
                        <div class="row" v-if="tipo == 'lectura'">
                            <div class="form-group">
                                <label>Video</label>
                                <input v-model="form.nombre_video" type="text" class="form-control" placeholder="Nombre" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group">
                                <label>Contenido</label>
                                <QuillEditor v-model:content="form.contenido" contentType="html" theme="snow" toolbar="full"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="row">
                        <h5>Información del curso <a @click="editarCurso(curso)" style="color: blue; font-weight: normal; cursor:grab;"><i class="ri-edit-box-fill"></i>&nbsp;</a></h5>
                        <h6>{{ curso.nombre_curso }}</h6>
                        <span cass="descripcion_curso_corta">{{ curso.descripcion_curso_corta }}</span>
                    </div><br>
                    <div class="row" v-if="curso.id_curso > 0">
                        <h5>Módulos <a @click="agregarModuloCurso()" style="color: rgb(34, 174, 34); cursor:grab;"><i class="ri-add-circle-fill"></i>&nbsp;</a></h5>
                        <div class="accordion" id="accordionExample">
                            <div class="accordion-item" v-for="(modulo, indexModulo) in curso.modulos" :key="indexModulo">
                                <h2 class="accordion-header" id="headingOne">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="idAcorddionTarget(indexModulo)" aria-expanded="true" :aria-controls="idAcorddion(indexModulo)">
                                        {{modulo.nombre_modulo}}
                                    </button>
                                </h2>
                                <div :id="idAcorddion(indexModulo)" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div class="accordion-body">
                                        <div class="row>">
                                            <h6>
                                                <a @click="agregarLecturaModuloCurso(modulo.id_curso_modulo, indexModulo)" style="color: rgb(34, 174, 34); cursor:grab;"><i class="ri-add-circle-fill"></i>&nbsp;</a>
                                                <a @click="editarModuloCurso(modulo, indexModulo)" style="color: blue; font-weight: normal; cursor:grab;"><i class="ri-edit-box-fill"></i>&nbsp;</a>
                                            </h6>
                                        </div>
                                        <!--<span v-html="modulo.descripcion_modulo"></span>-->
                                        <div class="row">
                                            <ul>
                                                <li class="lectura" v-for="(lectura, indexLectura) in modulo.lecturas" :key="indexLectura">
                                                    {{ lectura.nombre_lectura }}
                                                    <a @click="editarLecturaModuloCurso(lectura, modulo.id_curso_modulo, indexModulo, indexLectura)" style="color: blue; font-weight: normal; cursor:grab;"><i class="ri-edit-box-fill"></i>&nbsp;</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment'
import axios from 'axios'

export default {
    name: 'EditorCurso',
    components: {
        QuillEditor,
        VueDatePicker
    },
    data() {
        return {
            idCurso: 0,
            editor: null,
            edicion: false,
            tipo: 'lectura',
            indexElemento: -1,
            indexElementoPadre: -1,
            form: {
                id: 0,
                nombre: '',
                texto_corto: '',
                contenido: '',
                nombre_video: '',
                vigencia_desde: '',
                id_padre: 0,
            },
            curso: {
                id_curso: -1,
                nombre_curso: '',
                descripcion_curso_corta: '',
                descripcion_curso: '<p></p>',
                vigencia_desde: '',
                modulos: []
            },
        }
    },
    watch: {
    },
    mounted() {
        if (this.$route.query.id !== undefined) {
            this.idCurso = this.$route.query.id
            let loader = this.$loading.show({ canCancel: false })
            axios
                .get(this.$backendUrl + '/admin/curso?id=' + this.idCurso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        this.curso = response.data
                        this.curso.vigencia_desde = moment(response.data.vigencia_desde).format('YYYY-MM-DD')
                        loader.hide()
                    }
                },
                error => {
                    loader.hide()
                    console.log(error)
                })
            axios
                .get(this.$backendUrl + '/admin/curso?id=' + this.idCurso, { headers: { token: localStorage.userToken } })
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        this.curso = response.data
                        this.curso.vigencia_desde = moment(response.data.vigencia_desde).format('YYYY-MM-DD')
                    }
                },
                error => {
                    console.log(error)
                })
        } else {
            this.idCurso = 0
        }
    },
    methods: {
        idAcorddion(id) {
            return 'modulo' + id;
        },
        idAcorddionTarget(id) {
            return '#modulo' + id;
        },
        editarCurso(curso) {
            this.form.id = curso.id_curso
            this.form.nombre = curso.nombre_curso
            this.form.contenido = curso.descripcion_curso
            this.form.texto_corto = curso.descripcion_curso_corta
            this.form.vigencia_desde = moment(curso.vigencia_desde).format('YYYY-MM-DD')
            this.tipo = 'curso'
            this.form.id_padre = 0
            this.edicion = true
        },
        agregarModuloCurso() {
            this.form.id = 0
            this.form.nombre = ''
            this.form.contenido = '<p></p>'
            this.form.vigencia_desde = this.curso.vigencia_desde
            this.tipo = 'modulo'
            this.form.id_padre = this.curso.id_curso
            this.edicion = true
        },
        editarModuloCurso(modulo, index) {
            this.form.id = modulo.id_curso_modulo
            this.form.nombre = modulo.nombre_modulo
            this.form.contenido = modulo.descripcion_modulo
            this.form.vigencia_desde = moment(modulo.vigencia_desde).utcOffset(process.env.VUE_APP_WEB_UTF_OFFSET).format('YYYY-MM-DD')
            this.tipo = 'modulo'
            this.form.id_padre = this.curso.id_curso
            this.edicion = true
            this.indexElemento = index
        },
        agregarLecturaModuloCurso(id_padre, indexPadre) {
            this.form.id = 0
            this.form.nombre = ''
            this.form.contenido = '<p></p>'
            this.tipo = 'lectura'
            this.form.id_padre = id_padre
            this.edicion = true
            this.indexElemento = 0
            this.indexElementoPadre = indexPadre
        },
        editarLecturaModuloCurso(lectura, id_padre, indexPadre, indexElemento) {
            this.form.id = lectura.id_curso_modulo_lectura
            this.form.nombre = lectura.nombre_lectura
            this.form.contenido = lectura.contenido_hmtl
            this.form.nombre_video = lectura.nombre_video
            this.form.tipo = lectura.tipo
            this.tipo = 'lectura'
            this.form.id_padre = id_padre
            this.edicion = true
            this.indexElemento = indexElemento
            this.indexElementoPadre = indexPadre
        },
        cancelarEdicion() {
            this.form.id = 0
            this.form.nombre = ''
            this.form.contenido = '<p></p>'
            this.form.vigencia_desde = ''
            this.tipo = ''
            this.form.id_padre = 0
            this.edicion = false
        },
        guardarElemento() {
            let loader = this.$loading.show({ canCancel: false })
            switch (this.tipo) {
            //*** CURSO
                case 'curso':
                    if (this.form.id > 0) {
                        let elementoCurso = {
                            id_curso: this.form.id,
                            nombre_curso: this.form.nombre,
                            descripcion_curso: this.form.contenido,
                            descripcion_curso_corta: this.form.texto_corto,
                            vigencia_desde: this.form.vigencia_desde,
                        }
                        axios
                            .put(this.$backendUrl + '/admin/cursos', elementoCurso, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    this.form.id = response.data.id_curso
                                    this.curso.id_curso = response.data.id_curso
                                    this.curso.nombre_curso = response.data.nombre_curso
                                    this.curso.descripcion_curso = response.data.descripcion_curso
                                    this.curso.descripcion_curso_corta = response.data.descripcion_curso_corta
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Datos guardados' })
                                    this.edicion = false
                                }
                            },
                            error => {
                                loader.hide()
                                this.$swal({ icon: 'error', text: error.response.data.description })
                            })
                    } else {
                        let elementoCurso = {
                            nombre_curso: this.form.nombre,
                            descripcion_curso: this.form.contenido,
                            descripcion_curso_corta: this.form.texto_corto,
                            vigencia_desde: this.form.vigencia_desde,
                        }
                        axios
                            .post(this.$backendUrl + '/admin/cursos', elementoCurso, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    this.form.id = response.data.id_curso
                                    this.curso.id_curso = response.data.id_curso
                                    this.curso.nombre_curso = response.data.nombre_curso
                                    this.curso.descripcion_curso = response.data.descripcion_curso
                                    this.curso.descripcion_curso_corta = response.data.descripcion_curso_corta
                                    this.curso.modulos = []
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Datos guardados' })
                                    this.edicion = false
                                }
                            },
                            error => {
                                loader.hide()
                                this.$swal({ icon: 'error', text: error.response.data.description })
                            })
                    }
                    break
                //*** MODULO
                case 'modulo':
                    if (this.form.id > 0) {
                        let elementoCursoModulo = {
                            id_curso: this.form.id_padre,
                            id_curso_modulo: this.form.id,
                            nombre_modulo: this.form.nombre,
                            descripcion_modulo: this.form.contenido,
                            vigencia_desde: this.form.vigencia_desde,
                        }
                        axios
                            .put(this.$backendUrl + '/admin/cursos/modulos', elementoCursoModulo, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    this.curso.modulos[this.indexElemento].nombre_modulo = elementoCursoModulo.nombre_modulo
                                    this.curso.modulos[this.indexElemento].descripcion_modulo = elementoCursoModulo.descripcion_modulo
                                    this.curso.modulos[this.indexElemento].vigencia_desde = elementoCursoModulo.vigencia_desde
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Módulo guardado' })
                                    this.edicion = false
                                }
                            },
                                error => {
                                    loader.hide()
                                    this.$swal({ icon: 'error', text: error.response.data.description })
                                })
                    } else {
                        let elementoCursoModulo = {
                            id_curso: this.form.id_padre,
                            nombre_modulo: this.form.nombre,
                            descripcion_modulo: this.form.contenido,
                            vigencia_desde: this.form.vigencia_desde,
                        }
                        axios
                            .post(this.$backendUrl + '/admin/cursos/modulos', elementoCursoModulo, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    this.form.id = response.data.id_curso_modulo
                                    elementoCursoModulo.id_curso_modulo = response.data.id_curso_modulo
                                    delete elementoCursoModulo.id_curso
                                    elementoCursoModulo.lecturas = []
                                    this.curso.modulos.push(elementoCursoModulo)
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Módulo agregado' })
                                    this.edicion = false
                                }
                            },
                                error => {
                                    loader.hide()
                                    this.$swal({ icon: 'error', text: error.response.data.description })
                                })
                    }
                    break
                //*** LECTURA
                case 'lectura':
                    if (this.form.id > 0) {
                        let elementoCursoModuloLectura = {
                            id_curso_modulo: this.form.id_padre,
                            id_curso_modulo_lectura: this.form.id,
                            nombre_lectura: this.form.nombre,
                            contenido_hmtl: this.form.contenido,
                            nombre_video: this.form.nombre_video,
                            tipo: this.form.tipo,
                        }
                        axios
                            .put(this.$backendUrl + '/admin/cursos/modulos/lecturas', elementoCursoModuloLectura, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    delete elementoCursoModuloLectura.id_curso_modulo
                                    this.curso.modulos[this.indexElementoPadre].lecturas[this.indexElemento] = elementoCursoModuloLectura
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Lectura guardada' })
                                    this.edicion = false
                                }
                            },
                                error => {
                                    loader.hide()
                                    this.$swal({ icon: 'error', text: error.response.data.description })
                                })
                    } else {
                        let elementoCursoModuloLectura = {
                            id_curso_modulo: this.form.id_padre,
                            nombre_lectura: this.form.nombre,
                            contenido_hmtl: this.form.contenido,
                            nombre_video: this.form.nombre_video,
                            tipo: this.form.tipo,
                        }
                        axios
                            .post(this.$backendUrl + '/admin/cursos/modulos/lecturas', elementoCursoModuloLectura, { headers: { token: localStorage.userToken } })
                            .then(response => {
                                //console.log(response.data)
                                if (response.status == 200) {
                                    this.form.id = response.data.id_curso_modulo_lectura
                                    elementoCursoModuloLectura.id_curso_modulo_lectura = response.data.id_curso_modulo_lectura
                                    delete elementoCursoModuloLectura.id_curso_modulo
                                    this.curso.modulos[this.indexElementoPadre].lecturas.push(elementoCursoModuloLectura)
                                    loader.hide()
                                    this.$swal({ icon: 'success', text: 'Lectura agregada' })
                                    this.edicion = false
                                }
                            },
                                error => {
                                    loader.hide()
                                    this.$swal({ icon: 'error', text: error.response.data.description })
                                })
                    }
                    break
            }
        },
    },
}

</script>

<style lang="scss">
.ql-editor {
    height: 250px !important; 
}

.dp__input_reg {
    height: 40px;
    color: var(--heading-color);
    border: 1px solid #F1F5FF;
    background-color: #F1F5FF;
    border-radius: 10px;
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    transition: var(--transition);
    margin-top: -20px;
    margin-left: 20px;
}
.contact-list-area {
    margin-bottom: 24px;

    .table-responsive {
        background-color: var(--white-color);
        box-shadow: var(--box-shadow);
        padding: 30px;
        border-radius: var(--border-radius);
        height: 595px;

        &.scroll-bar {
            overflow-x: scroll !important;

            &::-webkit-scrollbar {
                height: 4px;
            }
        }
    }

    .table {
        tbody {
            tr {
                border-bottom: 1px solid #EBF1FF;
                border-radius: 10px;
                transition: var(--transition);

                &:hover {
                    background-color: #F8FAFF;
                    border-color: var(--white-color);
                }

                td {
                    padding: 20px 20px;
                    border-radius: 10px;

                    .form-check {
                        margin-bottom: 0;

                        .form-check-input {
                            &:focus {
                                box-shadow: none;
                            }
                        }

                        h4 {
                            font-size: 16px;
                            font-weight: 600;
                            margin-bottom: 0;
                        }

                        a {
                            font-size: 12px;
                            color: #67748E;
                        }

                        img {
                            width: 50px;
                        }

                        .border {
                            border: 3px solid var(--white-color) !important;
                            box-shadow: var(--box-shadow);
                        }
                    }

                    a {
                        font-size: 16px;
                        color: #67748E;
                    }

                    .location {
                        color: #67748E;
                        font-size: 16px;
                    }

                    ul {
                        padding-left: 0;
                        margin-bottom: 0;
                        list-style-type: none;
                        float: right;

                        li {
                            display: inline-block;
                            margin-right: 30px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-scrollbar:before {
        background-color: var(--main-color);
    }

    .simplebar-track.simplebar-horizontal .simplebar-scrollbar {
        height: 30px;
        top: -20px;
        cursor: pointer;
    }

    .form-check .form-check-input {
        border-color: #1765fd;
        background-color: rgba(23, 101, 253, 0.1);
        width: 20px;
        height: 20px;
    }

    .form-check-input:checked[type=checkbox] {
        background-color: #1765fd;
    }
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .contact-list-area {
        .table-responsive {
            overflow: auto;

            table {
                tbody {
                    tr {
                        td {
                            white-space: nowrap;
                            padding: 15px !important;

                            .form-check img {
                                width: 30px;
                            }
                        }
                    }
                }
            }
        }

        .table tbody {
            tr td ul li {
                width: 20px;
            }
        }
    }
}
</style>