<template>
    <div class="avatar-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="others-title">
                    <h3>Default Avatar</h3>
                </div>

                <ul class="avatar-list d-flex">
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-02.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-03.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-04.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-05.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                </ul>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Avatar Size</h3>
                </div>

                <ul class="avatar-list style-two d-flex align-items-center">
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-02.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-03.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-04.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-05.png" alt="Images">
                    </li>
                    <li>
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                </ul>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Avatar Size Revivers</h3>
                </div>

                <ul class="avatar-list style-three d-flex align-items-center">
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-02.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-03.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-04.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-05.png" alt="Images">
                    </li>
                    <li>
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                </ul>
            </div>

            <div class="card-box-style">
                <div class="others-title">
                    <h3>Border Radius Avatar</h3>
                </div>

                <ul class="avatar-list d-flex">
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-02.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-03.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-04.png" alt="Images">
                    </li>
                    <li class="me-3">
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-05.png" alt="Images">
                    </li>
                    <li>
                        <img class="rounded-pill" src="../../assets/images/avatar/avatar-01.png" alt="Images">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AvatarRadius'
}
</script>

<style lang="scss">
.avatar-list {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	&.style-two {
		li {
			&:nth-child(1) {
				img {
					max-width: 225px;
				}
			}
			&:nth-child(2) {
				img {
					max-width: 200px;
				}
			}
			&:nth-child(3) {
				img {
					max-width: 175px;
				}
			}
			&:nth-child(4) {
				img {
					max-width: 150px;
				}
			}
			&:nth-child(5) {
				img {
					max-width: 125px;
				}
			}
			&:nth-child(6) {
				img {
					max-width: 100px;
				}
			}
		}
	}

	&.style-three {
		li {
			&:nth-child(6) {
				img {
					max-width: 225px;
				}
			}
			&:nth-child(5) {
				img {
					max-width: 200px;
				}
			}
			&:nth-child(4) {
				img {
					max-width: 175px;
				}
			}
			&:nth-child(3) {
				img {
					max-width: 150px;
				}
			}
			&:nth-child(2) {
				img {
					max-width: 125px;
				}
			}
			&:nth-child(1) {
				img {
					max-width: 100px;
				}
			}
		}
	}
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .avatar-list {
        display: flex !important;
        img {
            max-width: 100% !important;
        }
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .avatar-list {
        display: flex !important;
        img {
            max-width: 100% !important;
        }
    }
}

.avatar-selected {
    border-bottom: 3px #000 solid;
}
</style>