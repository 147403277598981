<template>
    <div class="widgets-area">
        <div class="container-fluid">
            <div class="card-box-style">
                <div class="row justify-content-cenetr">

                    <div class="col-lg-6 col-sm-6">
                        <div class="card card-box text-center mb-24">
                            <div class="card-header">
                                Continúa con tu aprendizaje
                            </div>
                            <div class="card-body" >
                                <h5 class="card-title">{{cursoActual.nombre_curso}}</h5>
                                <p class="card-text">{{cursoActual.descripcion_curso_corta}}</p>
                                <a :href="'visor?id=' + cursoActual.id_url" class="btn btn-primary">Continuar</a>
                            </div>
                            <div class="card-footer text-muted">
                                {{cursoActual.avance}}% completado
                            </div>
                        </div>
                    </div>
                
                </div>
                
                <div class="row justify-content-cenetr">

                    <div class="col-lg-4 col-sm-6" v-for="(curso, index) in cursos" :key="index">
                        <div class="card card-box mb-24">
                            <div class="card-body">
                                <h5 class="card-title">{{curso.nombre_curso}}</h5>
                                <p class="card-text">{{curso.descripcion_curso_corta}}</p>
                                <p class="card-statics"><span class="badge">{{ curso.modulos }}</span> modulos / <span class="badge">{{ curso.lecturas }}</span> lecturas</p>
                                <a href="#" class="btn btn-primary">Continuar</a>
                            </div>
                        </div>
                    </div>
                
                </div>

                <div class="row justify-content-cenetr">

                    <div class="col-lg-3 col-sm-6" v-for="(curso, index) in cursosRecomendado" :key="index">
                        <div class="card card-box border-primary mb-24">
                            <div class="card-header">{{ curso.nombre_curso }}</div>
                            <div class="card-body">
                                <h5 class="card-title">{{ curso.descripcion_curso_corta }}</h5>
                                <p class="card-statics"><span class="badge">{{ curso.modulos }}</span> modulos / <span class="badge">{{ curso.lecturas }}</span> lecturas</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
//import moment from 'moment-timezone'

export default {
    name: 'CursosContent',
    data() {
        return {
            cursos: {},
            cursoActual: {},
        }
    },
    mounted() {
        let loaderCursos = this.$loading.show({ canCancel: false })
        let loaderCursoActual = this.$loading.show({ canCancel: false })
        axios
            .get(this.$backendUrl + '/me/cursos', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursos = response.data.data
                loaderCursos.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursos.hide()
            })
        axios
            .get(this.$backendUrl + '/me/cursos/actual', { headers: { token: localStorage.userToken } })
            .then(response => {
                this.cursoActual = response.data
                this.cursoActual.id_url = btoa(this.cursoActual.id_curso)
                loaderCursoActual.hide()
            }, error => {
                console.log(error.response.data.description)
                loaderCursoActual.hide()
            })
    }
}
</script>

<style lang="scss">
.card-box {
    h5 {
        font-weight: 500;
        font-size: 20px;
    }
}

.card-statics {
    .badge {
        background-color: #1765fd;
    }
    font-size: 12px;
}
</style>